import React from 'react';
import Buttonbar from '../Buttonbar';
import DialogBox from '../DialogBox';
import { get } from '../../../actions/REST';
import { dynamicOEButtonCall } from '../../../actions/API';
import './OnDeck.scss';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const buttonTypes = require('../../../constants/buttonTypes.js');

class DeckObject extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectOnDeckNode = this.handleSelectOnDeckNode.bind(this);
    this.handleRemoveNodeFromOnDeck = this.handleRemoveNodeFromOnDeck.bind(this);
  }

  handleSelectOnDeckNode(node) {
    this.props.selectOnDeckNode(node);
  }

  handleRemoveNodeFromOnDeck(e, node) {
    e.stopPropagation();

    this.props.removeNodeFromOnDeck(node);
  }

  render() {
    const { node, selected } = this.props;
    const extraClass = selected ? ' selected' : '';

    return (
      <div className={classNames('deckObject', extraClass)} onClick={() => this.handleSelectOnDeckNode(node)}>
        <span>{node.ondeck_label || node.label}</span>
        <span
          className="removeFromOnDeck glyphicon glyphicon-remove-circle"
          onClick={e => this.handleRemoveNodeFromOnDeck(e, node)}
        />
      </div>
    );
  }
}

class OnDeck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dialog_box: {
        data: null,
        show: false,
        dialog_type: null,
      },
    };

    this.closeBox = this.closeBox.bind(this);
    this.handleButton = this.handleButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      (this.props.currentlyViewing !== prevProps.currentlyViewing || prevProps.onDeckShrunk !== this.props.onDeckShrunk)
    ) {
      //Make sure the selected element is in view at all times
      const el = document.getElementsByClassName('deckObject selected')[0];
      if (el && !isMobile) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
  }

  openDialogBox(data, dialog_type, uniqueID) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: data,
        show: true,
        dialog_type,
      },
    });
  }

  //Close the DialogBox and delete the data from the state
  closeBox() {
    this.setState({
      dialog_box: {
        data: null,
        show: false,
        dialog_type: null,
      },
    });
  }

  //Open the box and pass the data to the state
  renderDialogBox(data, id, selectedObjects) {
    let selected_ids = {};
    if (selectedObjects) {
      for (let object of selectedObjects) {
        selected_ids[`ids[${object.id}]`] = true;
      }
    } else {
      //if there's no selectedObjects we set this to null to prevent to be sent to API
      selected_ids = null;
    }
    let params = Object.assign({}, selected_ids, id);
    if (data.variables) {
      params = Object.assign({}, params, data.variables);
    }

    this.setState({
      loading: true,
    });

    if (data.endpoint) {
      get(params, `/${data.endpoint}`, 'Fetching data for DialogBox').then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, data.dialog_type);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.props.openDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.props.openDialog(response));
        }
      });
    } else {
      //Inline DialogBox
      this.openDialogBox(data, data.dialog_type);
    }
  }

  handleButton(button) {
    if (button && button.action === 'clear_tmp_data') {
      this.props.clearOnDeck();
      return;
    }
    if (button.action === buttonTypes.DIALOG_BOX) {
      this.renderDialogBox(button, null, this.props.nodes);
      return;
    }

    //COMMAND Button
    //Gather all te ids of the objects in onDeck
    const { nodes } = this.props;
    let selected_ids = {};

    if (nodes) {
      for (let object of nodes) {
        selected_ids[`ids[${object.id}]`] = true;
      }
    } else {
      //if there's no selectedObjects we set this to null to prevent to be sent to API
      selected_ids = null;
    }
    let params = selected_ids;
    if (button.variables) {
      params = Object.assign({}, params, button.variables);
    }

    if (button.confirm) {
      params.confirmed = button.confirm;
    }

    dynamicOEButtonCall(button.endpoint, button.method, params).then(response => {
      if (response.status >= 200 && response.status < 300) {
        this.props.openDialog(response, this.handleButton.bind(this), button);
      }
    });
  }

  render() {
    const { config } = this.props;
    const buttonbar = config && config.ondeck && config.ondeck.buttons ? config.ondeck.buttons : [];

    return (
      <div id="onDeckSection" className="onDeckPanel" onClick={() => this.props.onClick && this.props.onClick()}>
        {this.state.dialog_box.show && this.state.dialog_box.data && (
          <DialogBox
            key={'dialog_box_'}
            data={this.state.dialog_box.data}
            dialog_type={this.state.dialog_box.dialog_type}
            onClose={this.closeBox}
            onSuccess={this.props.refreshObjects}
            openDialog={this.props.openDialog}
          />
        )}
        <div className="onDeckButtonbar">
          <Buttonbar buttonbar={buttonbar} handleButton={this.handleButton} />
        </div>
        {this.props.nodes.map(node => (
          <DeckObject
            key={node.id + node.label + node.ondeck_label}
            node={node}
            selected={this.props.currentlyViewing && node.id === this.props.currentlyViewing.id}
            selectOnDeckNode={this.props.selectOnDeckNode}
            removeNodeFromOnDeck={this.props.removeNodeFromOnDeck}
          />
        ))}
      </div>
    );
  }
}

OnDeck.defaultProps = {
  nodes: [],
};

export default OnDeck;
