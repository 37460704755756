import React from 'react';

export default class WorkingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //TODO make this look pretty
    var contents = this.props.stats ? (
      <div className="stats">
        <div>
          <span className="statsLabel">Count: </span>
          <span className="data">{this.props.stats.count_all}</span>
        </div>
        <div>
          <span className="statsLabel">Sum: </span>
          <span className="data">{this.props.stats.sum}</span>
        </div>
      </div>
    ) : (
      <div className="naMessage">Statistics for this user are unavailable</div>
    );
    return <div className="userStats">{contents}</div>;
  }
}
