import React from 'react';
import { Alert } from 'react-bootstrap';
import { DatePickerInput } from 'rc-datepicker';
import moment from 'moment';

export default class DateSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertMsg: false,
    };
    this.validateDate = this.validateDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /*
   ** Validate the date to make sure it's a valid date, if it's not
   ** moment will return a string 'Invalid date' and will set the
   ** state to show the alert message asking to enter a valid date.
   ** When a valid date is entered, this will set the state to false
   ** to hide the alert message.
   **
   ** @param {Object/String} - date
   */
  validateDate(date) {
    const result = moment(date).format('l').split('/').join('-');
    if (result === 'Invalid date') {
      this.setState({ showAlertMsg: true });
      return false;
    }
    this.setState({ showAlertMsg: false });
    return result;
  }

  /*
   ** Returns an alert message that disappears once the user
   ** enters a valid date.
   */
  getAlertMsg() {
    let content = null;
    const alert_style = {
      borderRadius: '2px',
      margin: '10px 0',
      maxWidth: '260px',
      padding: '10px',
    };
    content = (
      <Alert variant="warning" style={alert_style}>
        <span>Icon</span>
        {/*<Glyphicon glyph="info-sign" style={{marginRight:'5px'}}/>Please use the format <strong>mm/dd/yyyy</strong>*/}
      </Alert>
    );
    return content;
  }

  /*
   ** This function will catch the changes on the DatePickerInput
   ** but won't pass the value to this.props.onChange until the
   ** value is a valid date.
   **
   ** @param {String} - name
   ** @param {Object/String} - date (If date has an error will be a String)
   */
  handleChange(name, date) {
    let result = this.validateDate(date);
    if (result) {
      this.props.onChange({
        target: {
          name: name,
          value: result,
        },
      });
    }
  }

  render() {
    return (
      <div className={this.props.wrapperClass}>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <DatePickerInput
          name={this.props.name}
          type="nocheckbox"
          value={moment(this.props.value).format('l')}
          onChange={date => this.handleChange(this.props.name, date)}
          className={this.props.inputClass}
        />
        {this.state.showAlertMsg && this.getAlertMsg()}
      </div>
    );
  }
}
