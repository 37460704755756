import { combineReducers } from 'redux';
// import {routerReducer} from 'react-router-redux';//TODO: @React-Update-Todo
import APIReducer from './APIReducer';
import ReportsReducer from './ReportsReducer';
import ViewReducer from './ViewReducer';
import PrivilegeReducer from './PrivilegeReducer';
import ConfigReducer from './ConfigReducer';
// import { reducer as notifications } from 'react-notification-system-redux';

const rootReducer = combineReducers({
  // routing: routerReducer,
  APIReducer,
  // notifications,
  ViewReducer,
  ReportsReducer,
  PrivilegeReducer,
  ConfigReducer,
});

export default rootReducer;
