import React from 'react';
import Buttonbar from '../Buttonbar';
import DialogBox from '../DialogBox';
import { get } from '../../../actions/REST';
import { dynamicOEButtonCall } from '../../../actions/API';
import './Tree.scss';
import classNames from 'classnames';

const buttonTypes = require('../../../constants/buttonTypes.js');

class Node extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: !!this.props.node.open,
    };

    this.openNode = this.openNode.bind(this);
    this.closeNode = this.closeNode.bind(this);
    this.toggleNode = this.toggleNode.bind(this);
    this.gatherChildren = this.gatherChildren.bind(this);
    this.handleSelectedNode = this.handleSelectedNode.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
  }

  closeNode() {
    this.setState({
      open: false,
    });
  }

  openNode() {
    this.setState({
      open: true,
    });
  }

  toggleNode(e) {
    e && e.stopPropagation();
    this.state.open ? this.closeNode() : this.openNode();
  }

  //TODO: Add animation to display nodes
  //TODO: Fix selection of sub Groups with Ids matching on other groups

  gatherChildren(nodes) {
    let nodesFound = [];
    for (let node of nodes) {
      if (node.objects) {
        nodesFound = nodesFound.concat(this.gatherChildren(node.objects));
      }
      nodesFound.push(node);
    }

    return nodesFound;
  }

  handleSelectedNode(e, pushToOnDeck = false) {
    e.stopPropagation();
    //Check to see if the backend hasn't blocked this node from being clicked
    if (this.props.node.disabled) {
      return null;
    }

    let nodes = [this.props.node];
    if (this.props.node.expandable && this.props.node.objects) {
      nodes = nodes.concat(this.gatherChildren(this.props.node.objects));
    }

    if (this.props.simple) {
      this.props.selectSimpleObject(this.props.node);
    } else if (this.props.removeNodeFromSelectionTree || this.props.addNodeToSelectionTree) {
      this.props.selectedNodes.includes(this.props.node)
        ? this.props.removeNodeFromSelectionTree(nodes)
        : this.props.addNodeToSelectionTree(nodes, pushToOnDeck);
    }
  }

  handleDoubleClick(e) {
    !this.props.simple && this.handleSelectedNode(e, true);
  }

  render() {
    const extraClass = this.state.open ? 'glyphicon-menu-down' : 'glyphicon-menu-right';
    const childNodes = this.props.node.objects || []; //TODO: Check what other properties are we getting here
    const nodeClass = this.props.selectedNodes.includes(this.props.node) ? 'bold' : '';

    return (
      <div className="node">
        {this.props.node.expandable && (
          <span className={classNames('expandableNode glyphicon', extraClass)} onClick={this.toggleNode} />
        )}
        <span
          className={nodeClass}
          onClick={e => this.handleSelectedNode(e, false)}
          onDoubleClick={e => this.handleDoubleClick(e)}
        >
          {' '}
          {this.props.node.label}
        </span>
        {this.state.open &&
          childNodes.map(node => (
            <div key={node.id} style={{ marginLeft: '20px' }}>
              <Node
                node={node}
                addNodeToSelectionTree={this.props.addNodeToSelectionTree}
                removeNodeFromSelectionTree={this.props.removeNodeFromSelectionTree}
                selectedNodes={this.props.selectedNodes}
              />
            </div>
          ))}
      </div>
    );
  }
}

Node.defaultProps = {
  selectedNodes: [],
};

export default class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dialog_box: {
        data: null,
        show: false,
        dialog_type: null,
      },
      dialog: {
        open: false,
        response: null,
      },
    };

    this.handleButton = this.handleButton.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  openDialogBox(data, dialog_type, uniqueID) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: data,
        show: true,
        dialog_type,
      },
    });
  }

  //Close the DialogBox and delete the data from the state
  closeBox() {
    this.setState({
      dialog_box: {
        data: null,
        show: false,
        dialog_type: null,
      },
    });
  }

  //Open the box and pass the data to the state
  renderDialogBox(data, id, selectedObjects) {
    let selected_ids = {};
    if (selectedObjects) {
      for (let object of selectedObjects) {
        selected_ids[`ids[${object.id}]`] = true;
      }
    } else {
      //if there's no selectedObjects we set this to null to prevent to be sent to API
      selected_ids = null;
    }
    let params = Object.assign({}, selected_ids, id);
    if (data.variables) {
      params = Object.assign({}, params, data.variables);
    }

    this.setState({
      loading: true,
    });

    if (data.endpoint) {
      get(params, `/${data.endpoint}`, 'Fetching data for DialogBox').then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, data.dialog_type);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.props.openDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.props.openDialog(response));
        }
      });
    } else {
      //Inline DialogBox
      this.openDialogBox(data, data.dialog_type);
    }
  }

  handleButton(button) {
    if (button && button.action === 'clear_tmp_data') {
      this.props.clearOnDeck();
      return;
    }
    if (button.action === buttonTypes.DIALOG_BOX) {
      this.renderDialogBox(button, null, this.props.nodes);
      return;
    }

    //COMMAND Button
    let params = {};
    if (button.variables) {
      params = Object.assign({}, params, button.variables);
    }

    if (button.confirm) {
      params.confirmed = button.confirm;
    }

    dynamicOEButtonCall(button.endpoint, button.method, params).then(response => {
      if (response.status >= 200 && response.status < 300) {
        this.props.openDialog(response, this.handleButton.bind(this), button);
      }
    });
  }

  render() {
    const buttonbar = this.props.config && this.props.config.tree_buttonbar ? this.props.config.tree_buttonbar : [];

    return (
      <div className="TreePanel">
        {this.state.dialog_box.show && this.state.dialog_box.data && (
          <DialogBox
            key={'dialog_box_'}
            data={this.state.dialog_box.data}
            dialog_type={this.state.dialog_box.dialog_type}
            onClose={this.closeBox}
            refreshPage={this.props.refreshObjects}
            openDialog={this.props.openDialog}
          />
        )}
        <div className="treeToolbar">
          <div className="treeButtonbar">
            <Buttonbar buttonbar={buttonbar} handleButton={this.handleButton} />
          </div>
          {!this.props.simple && (
            <div className="pushButton" onClick={this.props.moveToOnDeck}>
              <span className="glyphicon glyphicon-forward" />
            </div>
          )}
        </div>
        <div className="Tree">
          {Array.isArray(this.props.tree) &&
            this.props.tree.map((node, index) => (
              <Node
                key={node.id + index}
                node={node}
                simple={this.props.simple}
                selectSimpleObject={this.props.selectSimpleObject}
                selectedNodes={this.props.selectedNodes}
                addNodeToSelectionTree={this.props.addNodeToSelectionTree}
                removeNodeFromSelectionTree={this.props.removeNodeFromSelectionTree}
              />
            ))}
        </div>
      </div>
    );
  }
}
