import React from 'react';
import Buttonbar from './Buttonbar';

const highlight_style = {
  backgroundColor: '#FFF59D',
  paddingLeft: '5px',
  marginLeft: '-5px',
};

export default function Records({
  loading,
  records,
  handleButton,
  titleStyle,
  hiddenFields = [],
  selectorMode = false,
  columnGetFrom,
  body_label,
}) {
  if (!(Array.isArray(records) && records.length > 0)) {
    return null;
  }

  const isDashboardColumn = columnGetFrom !== 'DashboardCoulmn';

  return (
    <div className="records-wrapper">
      {records.map((record, index) => (
        <div
          className={isDashboardColumn ? 'record' : 'record record_flex flex_col'}
          key={'record_' + record.id + record.label}
          style={record.highlighted ? highlight_style : {}}
        >
          <div className="content-icons">
            {record.lbuttonbar && (
              <Buttonbar
                loading={loading}
                selectorMode={selectorMode}
                hiddenFields={hiddenFields}
                buttonbar={record.lbuttonbar}
                handleButton={handleButton}
                uniqueID={record.id + record.label + index}
              />
            )}
            <abbr title={record.hovertext} className="record-title" style={titleStyle}>
              <span className={record.class + ' record-label'} sx={{ marginLeft: '20px' }}>
                {record.label}
              </span>
            </abbr>
            {record.buttonbar && (
              <Buttonbar
                loading={loading}
                selectorMode={selectorMode}
                hiddenFields={hiddenFields}
                buttonbar={record.buttonbar}
                handleButton={handleButton}
                uniqueID={record.id + record.label + index}
              />
            )}
          </div>
          {record.details && <pre className="details">{record.details}</pre>}
        </div>
      ))}
    </div>
  );
}
