import * as REST from './REST';

function parseOrgReports(orgReports) {
  var newOrgReports = [];
  for (var i = 0; i < orgReports.length; i++) {
    var report = orgReports[i];
    newOrgReports.push({
      id: report.id,
      label: report.label,
      children: report.reports ? parseOrgReports(report.reports) : null,
    });
  }
  return newOrgReports;
}

function parseReports(data) {
  var newData = {};
  newData.org_reports = parseOrgReports(data.config.org_reports);
  newData.custom_report = data.custom_report;
  newData.periods = data.config.periods;
  return newData;
}

export function getReports() {
  return function (dispatch) {
    const url = '/loader/reports';
    return REST.get({}, url, 'Get reports tree')
      .then(response => {
        dispatch({ type: 'LOAD_REPORTS_FULFILLED', payload: parseReports(response.data) });
      })
      .catch(err => {
        console.log('Get reports tree failed');
        console.log(err);
        dispatch({ type: 'LOAD_REPORTS_FAILED', payload: err });
      });
  };
}
