const now = new Date();
const initialState = {
  config: {
    seldate: now,
    scope: 'month',
  },
  status: {
    loading: false,
    loaded: false,
  },
  reloadJsonContent: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_VIEW_PENDING':
      return {
        config: action.payload,
        status: {
          loading: true,
          loaded: false,
        },
      };

    case 'UPDATE_VIEW_FULFILLED':
      return {
        config: action.payload,
        status: {
          loading: false,
          loaded: true,
        },
      };

    case 'RELOAD_JSON_CONTENT_PENDING':
      return {
        ...state,
        reloadJsonContent: true,
      };

    case 'RELOAD_JSON_CONTENT_FULFILLED':
      return {
        ...state,
        reloadJsonContent: false,
      };

    default:
      return state;
  }
};

export default reducer;
