import React, { use_effect,useState  } from 'react';
import { initializeApp }  from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookies from 'universal-cookie';
import { post } from './REST';
import addNotification from 'react-push-notification';


// For Push Notifications
export const NotificationsInit = (props, incrementBrowserMessages, incrementCallouts) => {
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);

  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}');

  const isIOS = () => {
    const browserInfo = navigator.userAgent.toLowerCase();

    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
      return true;
    }
    return false;
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  // Add a wrapped getToken that can be called from the UI and will 
  // generate the Enable Notifications popup for the user to choose whether or not 
  // they want to be interupted by this push notifications.
  const getPushToken = async (setTokenFound) => {
    try { 
      const currentToken = await getToken(messaging, {vapidKey: process.env.REACT_APP_GENERATED_MESSAGING_KEY});
      const cookies = new Cookies();
      if (currentToken) {

        // TODO Check if the token has changed and send a notification if it has
        const weekInSeconds = 604800;
        //Set the cookie to expire in 2 weeks
        cookies.remove('pushNotificationKey');
        cookies.set('pushNotificationKey', currentToken, {
          path: '/',
          maxAge: weekInSeconds * 2,
          sameSite: 'none',
          secure: 'true',
        });

        setTokenFound(true);

        // TODO show on the UI that permission is secured
      } else {
        cookies.remove('pushNotificationKey');
        console.log('No registration token available.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    } catch(err) {
      const cookies = new Cookies();
      cookies.remove('pushNotificationKey');
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    }
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
    });
  });


  onMessageListener().then(payload => {
    setShow(true);
    console.log("Incoming notification");
    console.log(payload);
    process_notification(payload);
  });

  getPushToken(setTokenFound);

  // Firefox requires a click to create the popup that asks permission to enable notifications
  window.addEventListener('click',() => { 
    getPushToken(setTokenFound); 

  } );

  /**
   * Returns a reference to the handler lambda function. 
   * Otherwise this fires as soon as the notification is received
   */
  const handlePushNotificationClick = (data) => {
    return () => {
      console.log("handlePushNotificationClick");
      console.log(data);

       /* Check if the current URL needs refreshing
       */
      if(window.location.pathname.includes(data.route)) {
        window.location.reload();
      } else {
        const port = (window.location.port === 80) ? '' : ':'+window.location.port;
        const opener = window.open(`${window.location.protocol}//${window.location.hostname}${port}/${data.route}`);
        opener.focus();
      }



    };
  };

  function process_notification(notification) {

    const url = '/notifications/message';
    const params = {
      notification_json: JSON.stringify(notification)
    };
    notification.data.type === 'browser_message' && incrementBrowserMessages && incrementBrowserMessages();
    notification.data.type === 'callout' && incrementCallouts && incrementCallouts();
    return post(params, url, 'Incoming Web Notification')
      .then(response => {
        if (response.data.show_notification) {
          console.log('Response from API: display notification');
          console.log(response.data);

          const notificationData = {
            route: notification.data.route
          };
          addNotification({
            title: response.data.title,
            message: response.data.body,
            onClick: handlePushNotificationClick(notificationData),
            duration: 10000,
            theme: 'darkblue',
            native: true // when using native, your OS will handle theming.
          });
        }


      })
      .catch(err => {
        console.log('Sending web notification to API failed (might be logged out)');
        // console.log(err);
      });
  };




}

