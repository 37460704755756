import React from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.parseData = this.parseData.bind(this);
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  parseData(data) {
    this.setState({
      value: data.value,
    });
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({
      value,
    });
  }

  render() {
    return (
      <div className={this.props.wrapperClass}>
        <FormGroup id={this.props.name}>
          <FormLabel className="bottomPaddingXs">{this.props.label}</FormLabel>
          <FormControl
            id={this.props.name}
            className={this.props.inputClass}
            name={this.props.name}
            type={this.props.type}
            ref={this.props.reference}
            onChange={this.handleChange.bind(this)}
            onBlur={this.props.onBlur}
            maxLength={this.props.maxLength}
            max={this.props.max}
            value={this.state.value}
            placeholder={this.props.default_option ? this.props.default_option.label : ''}
            style={{ width: '200px', height: '38px' }}
          />
        </FormGroup>
      </div>
    );
  }
}

export default Input;
