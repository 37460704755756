import React from 'react';
import { Link } from 'react-router-dom';
const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer-wrapper">
        <hr />
        <footer>
          <p>© netDuty {new Date().getFullYear()}
	    { PRIVACY_POLICY_URL && ( <Link style={{float:'right'}} to={{ pathname: PRIVACY_POLICY_URL }} target="_new" >Privacy Policy</Link> ) }
          </p>
        </footer>
      </div>
    );
  }
}
