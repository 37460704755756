import React from 'react';
import Loading from 'react-loading';
import { getContentFromEndpoint } from '../../../actions/API';
import Router from '../../AppLayout/Router';
import DropdownBtn from '../DropdownBtn';
import './WorkingArea.scss';
import classNames from 'classnames';

export default class WorkingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      configuration: null,
      objectMenu: this.props.objectMenu,
      preLoadedData: {},
      selectedTab: null,
    };

    this.getSelectedTabFromJSON = this.getSelectedTabFromJSON.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    //If we're switching to another Object we should get new Data
    this.props.currentlyViewing !== prevProps.currentlyViewing && this.getData();
  }

  getSelectedTabFromJSON({ buttons }) {
    for (let button of buttons) {
      if (button.selected) {
        return button;
      }
      if (button.type === 'dropdown_button') {
        for (let opt of button.options) {
          if (opt.selected) {
            return opt;
          }
        }
      }
    }

    return 'euser'; //TODO: @@@@@@@ Forcing this for now
  }

  getInitialData() {
    let { endpoint, endpoint_type, variables = {} } = this.props.APIConfig;
    if (!variables || (variables && !variables[this.props.idProperty])) {
      variables = {
        ...variables,
        [this.props.idProperty]: this.props.currentlyViewing[this.props.idProperty],
      };
    }

    if (this.props.selectedTab && this.props.selectedTab.endpoint && this.props.temp_navigation) {
      endpoint = this.props.selectedTab.endpoint;
      endpoint_type = this.props.selectedTab.endpoint_type;
    }

    getContentFromEndpoint(variables, endpoint)
      .then(response => {
        let selectedTab = null;

        if (response.data && response.data.object_menu) {
          selectedTab = this.getSelectedTabFromJSON(response.data.object_menu);
        } else if (response.data && response.data.config && response.data.config.object_menu) {
          selectedTab = this.getSelectedTabFromJSON(response.data.config.object_menu);
        }

        if (typeof selectedTab === 'string') {
          this.props.updateSelectedTab({ id: selectedTab });
        } else {
          this.props.updateSelectedTab(selectedTab);
          selectedTab = selectedTab ? selectedTab.id : null;
        }

        let objectMenu = null;
        if (response.data && response.data.object_menu) {
          objectMenu = response.data.object_menu;
        } else if (response.data && response.data.config && response.data.config.object_menu) {
          objectMenu = response.data.config.object_menu;
        }

        this.setState({
          objectMenu,
          selectedTab,
          configuration: {
            endpoint,
            endpoint_type,
          },
          preLoadedData: {
            [selectedTab]: response,
          },
          loading: false,
        });
      })
      .catch(err => console.warn(err) || this.setState({ loading: false }));
  }

  /**
   * This is intended to reduce unnecessary reloads if the object_menu 
   * is unchanged, but sometimes the id includes a random number, so that always 
   * triggers a reload. 
   */
  buttonsChanged(object_menu) {
    const { buttons=[] } = this.state.objectMenu;

    /*if (JSON.stringify(buttons) != JSON.stringify(object_menu.buttons)) {
        console.log(JSON.stringify(buttons));
        console.log(JSON.stringify(object_menu.buttons));
    }*/
    return (JSON.stringify(buttons) !=JSON.stringify( object_menu.buttons));
  }

  getData(APIConfig) {
    let { endpoint, endpoint_type } = APIConfig;
    let params = APIConfig.variables || {};
    if (!params || (params && !params[this.props.idProperty])) {
      params = {
        ...params,
        [this.props.idProperty]: this.props.currentlyViewing[this.props.idProperty],
      };
    }

    if (!endpoint) {
      endpoint = this.props.genericAPIConfig.endpoint;
      endpoint_type = this.props.genericAPIConfig.endpoint_type;
    }

    getContentFromEndpoint(params, endpoint)
      .then(response => {
        let objectMenu = this.state.objectMenu;
        if(response.data.config && response.data.config.object_menu && this.buttonsChanged(response.data.config.object_menu)) {
          objectMenu = response.data.config.object_menu;
        }
        this.setState(prevState => ({
          configuration: {
            endpoint,
            endpoint_type,
          },
          objectMenu,
          preLoadedData: {
            ...prevState.preLoadedData,
            [prevState.selectedTab]: response,
          },
          loading: false,
        }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  refreshTab(button, shouldRefresh, refresh_page, response=false) {
    // If a response is passed in, and it contains an object_menu, find the selected tab 
    // (that is the one with the 'selected' flag set true) and use that to update the selectedTab.
    // This means that the next/previous arrows will use the updated endpoint, including 
    // any query args set by the form.
    if (response !== false) {
      let selectedTab = null;

      if(response.data && response.data.object_menu) {
        selectedTab = this.getSelectedTabFromJSON(response.data.object_menu);
      } else if(response.data && response.data.config && response.data.config.object_menu) {
        selectedTab = this.getSelectedTabFromJSON(response.data.config.object_menu);
      }

      if(typeof selectedTab == 'string') {
        this.props.updateSelectedTab({id: selectedTab});
      } else if (selectedTab !== null ) {
        this.props.updateSelectedTab(selectedTab);
        selectedTab = selectedTab ? selectedTab.id : null;
      }

      if (shouldRefresh && response.data && response.data.reload_vars) {
        this.props.refreshObjects();
        let objectMenu = this.state.objectMenu;
        if (response.data && response.data.object_menu) {
          objectMenu = response.data.object_menu;
        } else if (response.data && response.data.config && response.data.config.object_menu) {
          objectMenu = response.data.config.object_menu;
        }

        let reload_button  = button;
        reload_button.variables = response.data.reload_vars;

        if (this.state.selectedTab !== selectedTab) {
          // Clear out the preLoadedData for the old tab
          const oldSelectedTab = this.state.selectedTab;
          this.setState(
            prevState => ({
              preLoadedData: {},
            }),
          );
        } 
        this.setState(
          prevState => ({
            selectedTab,
            objectMenu,
            preLoadedData: {
              [selectedTab]: response,
              [button.id]: null,
            },
          }),
          this.getData.bind(this, reload_button),
        );
        return; // NOTE Early return
      }

    }

    if (refresh_page) {
      this.props.refreshObjects();
    } else if (shouldRefresh) {
      this.setState(
        prevState => ({
          preLoadedData: {
            ...prevState.preLoadedData,
            [button.id]: null,
          },
        }),
        this.getData.bind(this, button),
      );
    }
  }

  switchTab(button) {
    this.props.updateSelectedTab(button);

    this.setState(
      {
        selectedTab: button.id,
      },
      () => {
        if (!this.state.preLoadedData[button.id] || button.force_refresh) {
          this.setState(
            prevState => ({
              loading: true,
              preLoadedData: {
                ...prevState.preLoadedData,
                [button.id]: null,
              },
            }),
            this.getData.bind(this, button),
          );
        }
      },
    );
  }

  getWorkingAreaBody(buttons) {
    const { selectedTab } = this.state;
    let content = [];

    for (let button of buttons) {
      const { id, type, options } = button;
      let visibilityClass = 'invisible';
      if (type === 'dropdown_button') {
        for (let opt of options) {
          let cs = 'invisible';
          if (opt.id === selectedTab && this.state.preLoadedData[selectedTab]) {
            cs = '';
          }
          content.push(
            <div key={opt.id} className={classNames('workingAreaBody tabsContent', cs)}>
              <Router
                preLoadedData={this.state.preLoadedData[opt.id]}
                content={this.props.selectedTab}
                openDialog={this.props.openDialog}
                success={(response, shouldRefresh, refresh_page) => this.refreshTab(opt, shouldRefresh, refresh_page, response)}
                noLoadingData={true}
                standAlone={false}
                history={this.props.history}
              />
            </div>,
          );
        }
      } else if (this.state.preLoadedData && this.state.preLoadedData[id]) {
        visibilityClass = id === selectedTab ? '' : 'invisible';
      }

      content.push(
        <div key={id} className={classNames('workingAreaBody tabsContent', visibilityClass)}>
          <Router
            preLoadedData={this.state.preLoadedData[id]}
            content={this.props.selectedTab}
            openDialog={this.props.openDialog}
            success={(response, shouldRefresh, refresh_page) => this.refreshTab(button, shouldRefresh, refresh_page, response)}
            noLoadingData={true}
            standAlone={false}
            history={this.props.history}
          />
        </div>,
      );
    }

    return content;
  }

  render() {
    if (this.state.objectMenu) {
      const { buttons = [] } = this.state.objectMenu;
      const { selectedTab } = this.state;

      return (
        <div className="WorkingArea" onClick={() => this.props.onClick && this.props.onClick()}>
          <div className="workingAreaTabs">
            <ul className="navTabs">
              {buttons.map(button =>
                button.type === 'dropdown_button' ? (
                  <DropdownBtn
                    key={'dropdown_button' + button.id}
                    preSelected={button.options.filter(opt => opt.id === selectedTab)}
                    options={button.options}
                    transparentStyle={true}
                    selectedClass={button.options.some(opt => opt.id === selectedTab) ? 'selected' : ''}
                    label={button.label}
                    onOptSelected={selected => this.switchTab(selected)}
                  />
                ) : (
                  <li
                    key={'tabs_' + button.id}
                    className={button.id === selectedTab ? 'selected' : ''}
                    onClick={this.switchTab.bind(this, button)}
                  >
                    {button.label}
                  </li>
                ),
              )}
            </ul>
          </div>
          {this.state.loading && (
            <div className="workingAreaBody tabsContent loading">
              <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
            </div>
          )}
          {this.getWorkingAreaBody(buttons)}
        </div>
      );
    }

    return null;
  }
}
