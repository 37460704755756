import React from 'react';
import {
  Tabs,
  Tab,
  Form,
  FormGroup,
  Col,
  Row,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormLabel,
  FormControl,
  Modal,
  Popover,
  OverlayTrigger,
  Alert,
} from 'react-bootstrap';
import {
  fetchShiftEditConfig,
  shiftCheck,
  putShift,
  deleteShift,
  fetchRosterList,
  createExchangeRequest,
  createExchangeDirect,
  fetchExchangesAvailableShifts,
} from '../../../actions/API';
import Loading from 'react-loading';
import ShiftTimes from '../../Common/ShiftTimes';
import TimeOff from './TimeOff';
import Split from './Split';

export default class ShiftEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditShiftFormSubmit = this.handleEditShiftFormSubmit.bind(this);
    this.handleEditShiftInputChange = this.handleEditShiftInputChange.bind(this);
    this.handleShiftStartChange = this.handleShiftStartChange.bind(this);
    this.handleShiftEndChange = this.handleShiftEndChange.bind(this);
    this.deleteShift = this.deleteShift.bind(this);

    this.handleExchangeInputChange = this.handleExchangeInputChange.bind(this);
    this.handleExchangeFormSubmit = this.handleExchangeFormSubmit.bind(this);
    this.handleExchangeStartChange = this.handleExchangeStartChange.bind(this);
    this.handleExchangeEndChange = this.handleExchangeEndChange.bind(this);
  }

  componentDidMount() {
    let shiftID = this.props.data.shift_id;
    let that = this;
    //TODO: modularize tabs more
    // TODO (kl): this whole thing is a mess. major refactor of entier component needed
    fetchShiftEditConfig({ shift_id: shiftID })
      .then(response => {
        // only parse this out if data is an object full of data rather than an
        // html error message
        if (response.data && typeof response.data === 'object') {
          this.setState({
            config: response.data.config,
            data: response.data.data,
            privs: response.data.privs,
            editShiftForm: {
              shiftID: shiftID,
              dutyID: response.data.data.shift_info.duty_id,
              rosterID: response.data.data.shift_info.roster_id,
              day: response.data.data.shift_info.dutydate,
              roster: null,
              hideShift: response.data.config.options.hide_shift,
              skipTimeClockCreate: response.data.config.options.skip_timeclock_create,
              shiftStart: that.props.data.timein,
              shiftEnd: that.props.data.timeout,
              shiftNotes: response.data.data.shift_info.notes ? response.data.data.shift_info.notes : '',
              shiftCategory: response.data.data.shift_info.cat_id,
              submitButton: {
                enabled: false,
                loading: false,
              },
              shiftChecker: null,
            },
            exchangeForm: {
              shiftID: shiftID,
              rosterID: response.data.data.shift_info.roster_id,
              type: 'request',
              fullShift: true,
              giveUpShift: response.data.config.edit_shift_tabs.exchange.configs.giveup,
              importance: response.data.config.edit_shift_tabs.exchange.configs.fields.importance.default,
              allowGiveUp: response.data.config.edit_shift_tabs.exchange.configs.fields.notify_type.default === 'on',
              shiftNotes: response.data.config.edit_shift_tabs.exchange.configs.fields.notes.default,
              error: null,
              shiftStart: response.data.config.edit_shift_tabs.exchange.configs.fields.start.default,
              shiftEnd: response.data.config.edit_shift_tabs.exchange.configs.fields.start.default,
              exchangeShift: null,
            },
            exchangeableShifts: null,
          });
          // otherwise, set the error state to true and display the error message
        } else {
          this.setState({
            data: response.data,
            err: true,
            loaded: true,
          });
        }
        return response.data;
      })
      .then(function (cfg) {
        return fetchRosterList({
          ranking: cfg.config.roster_dropdown.default_ranking,
          dutydate: cfg.data.shift_info.dutydate,
          duty_id: cfg.data.shift_info.duty_id,
        }).then(response => {
          console.log('roster list response: ', response.data);
          // that.setState({roster : response.data.rankings.roster_list}); //TODO:
        });
      })
      .catch(err => {
        console.log(err);
        that.setState({
          loaded: true,
          err: true,
          err_msg: err,
          config: null,
        });
      });
  }

  render() {
    // if there is data and no error, build the modal
    if (this.state && !this.state.err && this.state.config && this.state.editShiftForm) {
      const defaultTab = this.defaultTab();

      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.data.shift_label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey={defaultTab} id="shiftEditModal">
              <Tab eventKey={1} title="Edit Shift">
                <br />
                <Form horizontal onSubmit={this.handleEditShiftFormSubmit}>
                  <FormGroup controlId="shiftCategory">
                    <Col componentClass={FormLabel} sm={3}>
                      Shift Category
                    </Col>
                    <Col sm={9}>
                      <FormControl
                        componentClass="select"
                        defaultValue={this.state.data.shift_info.cat_id}
                        placeholder="select"
                        onChange={this.handleEditShiftInputChange}
                        name="shiftCategory"
                      >
                        {this.renderShiftCategories(this.state.config.shift_categories)}
                      </FormControl>
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="hideShift">
                    <Col componentClass={FormLabel} sm={3}>
                      Hide Shift
                    </Col>
                    <Col sm={9}>
                      <Form.Check
                        type="checkbox"
                        checked={this.state.editShiftForm.hideShift}
                        onChange={this.handleEditShiftInputChange}
                        name="hideShift"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="formHorizontalEmail">
                    <Col componentClass={FormLabel} sm={3}>
                      Shift Times
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="formHorizontalEmail">
                    <Col componentClass={FormLabel} sm={3} />
                    <Col sm={9}>
                      Start:{' '}
                      <ShiftTimes
                        times={this.state.config.times}
                        default={this.props.data.timein}
                        handleInputChange={this.handleShiftStartChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="formHorizontalEmail">
                    <Col componentClass={FormLabel} sm={3} />
                    <Col sm={9}>
                      End:&nbsp;{' '}
                      <ShiftTimes
                        times={this.state.config.times}
                        default={this.props.data.timeout}
                        handleInputChange={this.handleShiftEndChange}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup controlId="formHorizontalEmail">
                    <Col componentClass={FormLabel} sm={3}>
                      Shift Notes
                    </Col>
                    <Col sm={9}>
                      <FormControl
                        style={{ maxWidth: '400px' }}
                        componentClass="textarea"
                        value={this.state.editShiftForm.shiftNotes}
                        onChange={this.handleEditShiftInputChange}
                        name="shiftNotes"
                      />
                    </Col>
                  </FormGroup>

                  <Row>
                    <Col sm={2} smOffset={3}>
                      <Button
                        variant="primary"
                        className="hCenter editShiftBtn"
                        disabled={!this.state.editShiftForm.submitButton.enabled}
                        type="submit"
                      >
                        {' '}
                        {this.state.editShiftForm.submitButton.loading && (
                          <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate" />
                        )}{' '}
                        Submit
                      </Button>

                      {this.props.privs.delete === true && (
                        <Button variant="danger" className="hCenter editShiftBtn" onClick={this.deleteShift}>
                          {' '}
                          Delete
                        </Button>
                      )}
                    </Col>
                    <Col sm={5}>
                      {this.state.shiftChecker && <p>{this.state.shiftChecker.msg}</p>}
                      {this.state.shiftChecker && this.renderShiftInfoButtons(this.state.shiftChecker)}
                    </Col>
                  </Row>
                </Form>
              </Tab>

              <Tab eventKey={2} title="Exchange">
                <>
                  <ButtonGroup justified className="topPaddingMd">
                    <Button href="#" onClick={this.handleExchangeTypeChange.bind(this, 'request')}>
                      {this.state && this.state.exchangeForm.type === 'request' && (
                        <span>{/*<Glyphicon glyph="ok" />*/}</span>
                      )}
                      &nbsp;Create Request Only
                    </Button>
                    <Button href="#" onClick={this.handleExchangeTypeChange.bind(this, 'direct')}>
                      {this.state && this.state.exchangeForm.type === 'direct' && (
                        <span>{/*<Glyphicon glyph="ok" />*/}</span>
                      )}
                      &nbsp;Create Direct Exchange
                    </Button>
                  </ButtonGroup>

                  {this.state.exchangeForm.type === 'request' && (
                    <Form horizontal className="topPaddingMd">
                      <FormGroup controlId="shiftCategory">
                        <Col componentClass={FormLabel} sm={3}>
                          Importance
                        </Col>
                        <Col sm={6}>
                          <ButtonToolbar>
                            <ButtonGroup>
                              <Button name="importance" onClick={this.handleImportanceChange.bind(this, 'high')}>
                                {this.state.exchangeForm.importance === 'high' && (
                                  <span>{/*<Glyphicon glyph="ok" />*/}</span>
                                )}
                                &nbsp;High
                              </Button>
                              <Button name="importance" onClick={this.handleImportanceChange.bind(this, 'medium')}>
                                {this.state.exchangeForm.importance === 'medium' && (
                                  <span>{/*<Glyphicon glyph="ok" />*/}</span>
                                )}
                                &nbsp;Medium
                              </Button>
                              <Button name="importance" onClick={this.handleImportanceChange.bind(this, 'low')}>
                                {this.state.exchangeForm.importance === 'low' && (
                                  <span>{/*<Glyphicon glyph="ok" />*/}</span>
                                )}
                                &nbsp;Low
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="hideShift">
                        <Col componentClass={FormLabel} sm={3}>
                          Give Up Shift
                        </Col>
                        <Col sm={9}>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.exchangeForm.giveUpShift}
                            onChange={this.handleExchangeInputChange}
                            name="giveUpShift"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail">
                        <Col componentClass={FormLabel} sm={3}>
                          Shift Notes
                        </Col>
                        <Col sm={9}>
                          <FormControl
                            componentClass="textarea"
                            value={this.state.exchangeForm.shiftNotes}
                            onChange={this.handleExchangeInputChange}
                            name="shiftNotes"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="hideShift">
                        <Col componentClass={FormLabel} sm={3}>
                          Full Shift
                        </Col>
                        <Col sm={9}>
                          <Form.Check
                            type="checkbox"
                            checked={this.state.exchangeForm.fullShift}
                            onChange={this.handleExchangeInputChange}
                            name="fullShift"
                          />
                        </Col>
                      </FormGroup>

                      {!this.state.exchangeForm.fullShift &&
                        this.renderNotFullShiftTimes(this.state.config.edit_shift_tabs.exchange.configs.fields)}
                    </Form>
                  )}

                  {this.state.exchangeForm.type === 'direct' && (
                    <Row>
                      <Col xs={6}>
                        <Form horizontal className="topPaddingMd">
                          <FormGroup controlId="shiftCategory">
                            <Col componentClass={FormLabel} sm={3}>
                              User
                            </Col>
                            <Col sm={9}>
                              <FormControl
                                componentClass="select"
                                defaultValue="Select"
                                placeholder="select"
                                onChange={this.handleExchangeInputChange}
                                name="exchangeUser"
                              >
                                {this.renderRoster(this.state.roster)}
                              </FormControl>
                            </Col>
                          </FormGroup>

                          {this.state.exchangeableShifts && (
                            <FormGroup controlId="shiftCategory">
                              <Col componentClass={FormLabel} sm={3}>
                                Shift
                              </Col>
                              <Col sm={9}>
                                <FormControl
                                  componentClass="select"
                                  defaultValue="Select"
                                  placeholder="select"
                                  onChange={this.handleExchangeInputChange}
                                  name="exchangeShift"
                                >
                                  {this.renderExchangeableShifts(this.state.exchangeableShifts)}
                                </FormControl>
                              </Col>
                            </FormGroup>
                          )}

                          <FormGroup controlId="hideShift">
                            <Col componentClass={FormLabel} sm={3}>
                              Full Shift
                            </Col>
                            <Col sm={9}>
                              <Form.Check
                                type="checkbox"
                                checked={this.state.exchangeForm.fullShift}
                                onChange={this.handleExchangeInputChange}
                                name="fullShift"
                              />
                            </Col>
                          </FormGroup>

                          {!this.state.exchangeForm.fullShift &&
                            this.renderNotFullShiftTimes(this.state.config.edit_shift_tabs.exchange.configs.fields)}

                          <FormGroup controlId="hideShift">
                            <Col componentClass={FormLabel} sm={3}>
                              Give Up Shift
                            </Col>
                            <Col sm={9}>
                              <Form.Check
                                type="checkbox"
                                checked={this.state.exchangeForm.giveUpShift}
                                onChange={this.handleExchangeInputChange}
                                name="giveUpShift"
                              />
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                      <Col xs={6} style={{ marginTop: '16px' }}>
                        {this.state.exchangeableShifts &&
                          this.renderExchangeableShiftDetails(this.state.exchangeableShifts)}
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col sm={2} smOffset={3}>
                      <Button
                        variant="primary"
                        className="hCenter"
                        onClick={this.handleExchangeFormSubmit}
                        type="submit"
                      >
                        {' '}
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </>
              </Tab>
              <Tab eventKey={3} title="Time Off">
                <TimeOff />
              </Tab>

              <Tab eventKey={4} title="Split">
                <Split data={this.state} actions={this.props.actions} />
              </Tab>
            </Tabs>
          </Modal.Body>
        </div>
      );
    } else if (this.state && this.state.loaded && this.state.err) {
      // if there is already an error screen returned by the API, display it
      if (this.state.data) {
        return (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>ERROR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.state.data }} />
            </Modal.Body>
          </div>
        );

        // if not, but there is a message,  display it
      } else if (this.state.err_msg) {
        return (
          <div style={{ height: '300px', width: '300px', marginRight: 'auto', marginLeft: 'auto', marginTop: '20px' }}>
            <Alert variant="warning" style={{ paddingTop: '20px', marginLeft: 'auto', marginRight: 'auto' }}>
              {/*<Glyphicon glyph="alert" style={{display : 'inline'}} /> {this.state.err_msg.message}*/}
            </Alert>
          </div>
        );

        // otherwise return null
      } else {
        return null;
      }
    } else {
      return (
        <div className="hCenter">
          <Loading type="bars" color="#e3e3e3" />
        </div>
      );
    }
  }

  handleImportanceChange(val, e) {
    e.preventDefault();
    let f = this.state.exchangeForm;
    f.importance = val;
    this.setState({ exchangeForm: f });
  }

  renderNotFullShiftTimes(params) {
    //console.log("renderNotFullShiftTimes");
    //console.log(params);
    return (
      <div>
        <FormGroup controlId="formHorizontalEmail">
          <Col componentClass={FormLabel} sm={3} />
          <Col sm={9}>
            Start:&nbsp;{' '}
            <ShiftTimes
              times={params.start}
              default={params.start.default}
              handleInputChange={this.handleExchangeEndChange}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalEmail">
          <Col componentClass={FormLabel} sm={3} />
          <Col sm={9}>
            End:&nbsp;{' '}
            <ShiftTimes
              times={params.end}
              default={params.end.default}
              handleInputChange={this.handleExchangeStartChange}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }

  handleExchangeEndChange(t) {
    console.log(t);
    let f = this.state.exchangeForm;
    f.shiftEnd = t;
    this.setState({ form: f });
  }

  handleExchangeStartChange(t) {
    console.log(t);
    let f = this.state.exchangeForm;
    f.shiftStart = t;
    this.setState({ form: f });
  }

  handleExchangeInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = this.state.exchangeForm;
    f[name] = value;
    let that = this;
    console.log(that);

    if (name === 'exchangeUser') {
      this.setState({ exchangeableShifts: null });
      fetchExchangesAvailableShifts({
        start: that.state.exchangeForm.shiftStart,
        end: that.state.exchangeForm.shiftEnd,
        rosterID: that.state.exchangeForm.rosterID,
        shiftID: that.state.exchangeForm.shiftID,
      }).then(response => {
        that.setState({
          exchangeableShifts: response.data.data,
        });
      });
    } else {
      this.setState({
        exchangeForm: f,
      });
    }
  }

  renderExchangeableShifts(shifts) {
    let options = [];
    for (let key in shifts.available) {
      if (shifts.available.hasOwnProperty(key)) {
        // console.log(shifts.available[key]);
        options.push(
          <option value={key} key={key}>
            {shifts.available[key].label}
          </option>,
        );
      }
    }
    return options;
  }

  renderExchangeableShiftDetails(shifts) {
    let options = [];
    for (let key in shifts.available) {
      if (shifts.available.hasOwnProperty(key)) {
        // console.log(shifts.available[key]);
        options.push(
          <p style={{ margin: '0 0 0px' }} key={key}>
            {shifts.available[key].label}: {shifts.available[key].details}
          </p>,
        );
      }
    }
    return options;
  }

  //TODO:put in own component
  renderShiftInfoButtons(check) {
    console.log(check);
    if (check.code === 400) return;
    let cats = ['annual', 'month', 'overtime', 'payperiod'];
    let data = []; //to be returned

    for (let i = 0; i < cats.length; i++) {
      let cat = check.data[cats[i]];
      let info = [];
      for (let key in cat.data) {
        if (cat.data.hasOwnProperty(key)) {
          // console.log(key + ': ' + cat.data[key]);
          info.push(
            <p>
              {key} : {cat.data[key]}
            </p>,
          );
        }
      }
      let popover = (
        <Popover id="popover-positioned-top" title={cat.label}>
          {info}
        </Popover>
      );
      data.push(
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <Button>{cats[i]}</Button>
        </OverlayTrigger>,
      );
    }
    return data;
  }

  handleExchangeFormSubmit() {
    let that = this;
    // exchangeForm : {
    //   shiftID : shiftID,
    //   type : "direct",
    //   fullShift : true,
    //   giveUpShift : false,
    //   rosterID : null,
    //   importance : 2,
    //   allowGiveUp : false,
    //   shiftNotes : "",
    //   error : null
    // }
    if (this.state.exchangeForm.type === 'direct') {
      let params = {
        shift_id: this.state.exchangeForm.shiftID,
        giveup: this.state.exchangeForm.giveUpShift,
        offer_roster_id: this.state.exchangeForm.rosterID,
        offer_shift_id: this.state.exchangeForm.shiftID,
        start: this.state.exchangeForm.shiftStart,
        end: this.state.exchangeForm.shiftEnd,
        test: 1,
      };
      createExchangeDirect(params).then(response => {
        that.props.actions.pushNotification();
        that.props.closeAction();
        that.props.actions.fetchExchangeRequests();
      });
    } else if (this.state.exchangeForm.type === 'request') {
      let params = {
        shift_id: this.state.exchangeForm.shiftID,
        giveup: this.state.exchangeForm.giveUpShift,
        test: 0,
      };
      createExchangeRequest(params).then(response => {
        that.props.actions.pushNotification();
        that.props.closeAction();
        that.props.actions.fetchExchangeRequests();
      });
    }
  }

  handleExchangeTypeChange(t, e) {
    e.preventDefault();
    let ex = this.state.exchangeForm;
    ex.type = t;
    this.setState({ exchangeForm: ex });
  }

  renderRoster(roster) {
    let options = [];
    options.push(<option value="select">Select...</option>);
    for (let key in roster) {
      if (roster.hasOwnProperty(key)) {
        options.push(
          <option value={key} key={key}>
            {roster[key].label}
          </option>,
        );
        // console.log(key + " -> " + roster[key]);
      }
    }
    return options;
  }

  renderShiftCategories(cats) {
    let options = [];
    options.push(
      <option value="select" key={'default'}>
        Select...
      </option>,
    );
    for (let key in cats) {
      if (cats.hasOwnProperty(key)) {
        options.push(
          <option value={key} key={key}>
            {cats[key]}
          </option>,
        );
        // console.log(key + " -> " + cats[key]);
      }
    }
    return options;
  }

  defaultTab() {
    if (this.props.tab || (this.state && this.state.config && this.state.config.default_view)) {
      const t = this.props.tab || this.state.config.default_view;
      switch (t) {
        case 'edit':
          return 1;
        case 'exchange':
          return 2;
        default:
          return 0;
      }
    } else {
      return 1;
    }
  }

  deleteShift(e) {
    let that = this;
    e.preventDefault();
    console.log('Deleting ' + this.props.data.shift_id + '...');
    deleteShift({ shift_id: this.props.data.shift_id })
      .then(response => {
        that.props.closeAction();
        let d = that.state.data.dutydate.split('-');
        console.log('refreshing day....');
        that.props.actions.fetchDay({ year: d[0], month: d[1], day: d[2] });
        return response;
      })
      .then(response => {
        that.props.closeAction();
        that.props.actions.pushNotification();
      });
  }

  checkShiftValid() {
    // console.log("Checking...");
    // console.log(this.state.editShiftForm);
    let f = this.state.editShiftForm;
    f.submitButton.loading = true;
    f.submitButton.enabled = false;
    this.setState({ form: f });
    shiftCheck(this.state.editShiftForm)
      .then(response => {
        // console.log('shift checked:', response);
        if (response.data.success === '1' || response.data.success === 1) {
          f.submitButton.enabled = true;
          f.submitButton.loading = false;
          this.setState({ form: f, shiftChecker: response.data });
        } else if (response.data.success === '0' || response.data.success === 0) {
          f.submitButton.enabled = false;
          f.submitButton.loading = false;
          this.setState({ shiftChecker: response.data, form: f });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  handleEditShiftFormSubmit(e) {
    let that = this;
    // console.log('that', that);
    e.preventDefault();
    putShift(this.state.editShiftForm)
      .then(response => {
        that.props.closeAction();
        let d = that.state.data.dutydate.split('-');
        console.log('refreshing day....');
        that.props.actions.fetchDay({ year: d[0], month: d[1], day: d[2] });
        return response;
      })
      .then(response => {
        that.props.actions.pushNotification();
      });
  }

  handleEditShiftInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = this.state.editShiftForm;
    f[name] = value;

    this.setState(
      {
        form: f,
      },
      this.checkShiftValid,
    );
  }

  handleShiftStartChange(t) {
    // console.log(t);
    let f = this.state.editShiftForm;
    f.shiftStart = t;
    this.setState({ form: f }, this.checkShiftValid);
  }

  handleShiftEndChange(t) {
    // console.log(t);
    let f = this.state.editShiftForm;
    f.shiftEnd = t;
    this.setState({ form: f }, this.checkShiftValid);
  }
}
