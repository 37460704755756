const initialState = {
  signup: null,
  duties: null,
  headerTabs: null,
  userPage: {
    config: {
      header: null,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //TODO: make less ugly
    case 'STORE_DUTYROSTER_CONFIG':
      return {
        ...state,
        signup: {
          ...state.signup,
          ...action.payload.signup,
        },
        duties: {
          ...state.duties,
          ...action.payload.duties,
        },
      };

    case 'STORE_USERPAGE_CONFIG':
      return {
        ...state,
        userPage: {
          ...action.payload,
        },
      };

    case 'STORE_HEADER_TABS':
      return {
        ...state,
        headerTabs: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
