import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/dailyRosterTheme.scss';

export default class MenuTabs extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0, //tracks selected tab for tabs that are not router based
      tabData: null,
    };                // TODO: link this with the selected state in Admin Tabs
  }

  navTo(tab,url){
    const tabData = this.state.tabData;
    if (tabData[tab.id].url == url && !tabData[tab.id].active) {
      // Update active
      const newTabs = tabData.map((t,index) => {return {
        url: t.url, 
        id: index,
        active: t.url == url,
        name: t.name,
        } 
      });

      const selectedTab=newTabs.filter(tab => tab.active)[0] ? newTabs.filter(tab => tab.active)[0].id : 0;
      //console.log("selectedTab "+selectedTab);
      this.setState({
        tabData: newTabs,
        selectedTab: selectedTab
      });
    } else {
      const newTabs = this.getActiveTabs();
      this.setState({
        tabData: newTabs,
        selectedTab: newTabs.filter(tab => tab.active)[0] ? newTabs.filter(tab => tab.active)[0].id : 0,
      });
    }

    let tabChanged = false;
    if(tabData.length && !this.state.tabData.length || tabData.length != this.state.tabData.length){
      tabChanged = true;
    } else {
      for (let i=0; i<tabData.length; i++) {
        if (tabData[i].url != this.state.tabData[i].url) {
          tabChanged = true;
        }
      }

    }

    if(tabChanged){
      this.setState({
        tabData: tabData,
        selectedTab: tabData.filter(tab => tab.active)[0] ? tabData.filter(tab => tab.active)[0].id : 0,
      });
    }

      this.props.navigationHandler(url);
  }
  getTabClickFunction(tab){
    return function(){
      /* Instead of updating the local state with this tab we update the storeHeaderTabs and we
      ** set this Tab to have active:true and the rest active:false and because the store has changed
      ** those props come through componentWillReceiveProps where we update the local state. This allow
      ** us to change the storeHeaderTabs anywhere else and successfully change the Active tab in this
      ** component.
      **
      ** This was done to create a quick link (open_link) on Dashboard's dutyroster.*/
      if (!tab || !tab.name) {
        return {}
      }


      this.props.globalActions.storeHeaderTabs(this.state.tabData.map(storedTab => {
        if(storedTab.name && storedTab.name.toUpperCase() === tab.name.toUpperCase()){
          /** It's important that we reset the `open_link` property every time a Tab is clicked **/ 
          return Object.assign({}, storedTab, {active: true, open_link: null})
        }
        return Object.assign({}, storedTab, {active: false, open_link: null})
      }));
      //This will display the right content (created on Tabs.js)
      tab.onClick();
    };
  }
  
  componentDidMount(){
    const tabData = this.props.userTabs.map((tab,index) => {return {
      url: tab.route, 
      id: index,
      active: this.state.selectedTab == index,
      name: tab.label,
    } });
    
    this.setState({tabData: tabData});
  }

  getActiveTabs() {
    let tabData = this.state.tabData;

    if (this.props.currentPage.match(/^\/user/)) {
      tabData = this.props.userTabs.map((tab,index) => {return {
        url: tab.route, 
        id: index,
        active: this.state.selectedTab == index,
        name: tab.label,
        } });
    } else if (this.props.currentPage.match(/^\/admin/)) {
      tabData = this.props.adminTabs.map((tab,index) => {return {
        url: tab.route, 
        id: index,
        active: this.state.selectedTab == index,
        name: tab.label,
        } });
    } else if (this.props.currentPage.match(/^\/orgman/)) {
      tabData = this.props.orgmanTabs.map((tab,index) => {return {
        url: tab.route, 
        id: index,
        active: this.state.selectedTab == index,
        name: tab.label,
        } });
    }
    return tabData;

  }

  componentDidUpdate(prevProps, prevState){
    let tabData = this.getActiveTabs();

    let tabChanged = false;
    if(tabData.length && !this.state.tabData.length || tabData.length != this.state.tabData.length){
      tabChanged = true;
    } else {
      for (let i=0; i<tabData.length; i++) {
        if (tabData[i].url != this.state.tabData[i].url) {
          tabChanged = true;
        }
      }

    }

    if(tabChanged){
      this.setState({
        tabData: tabData,
        selectedTab: tabData.filter(tab => tab.active)[0] ? tabData.filter(tab => tab.active)[0].id : 0,
      });
    }
   /* if(false && this.props !== prevProps && this.props.selected){
      console.log("MenuTabs Did Update state");
      this.setState(
        {
          selectedTab: this.props.selected.id,
          tabData: tabData
        },() =>
        this.props.selected.onClick()
        );
    }
    //This will update the selectedTab everytime it's changed in the Redux Store
    if(false && tabData !== prevState.tabData){
      console.log("MenuTabs Did Update tabData state");
      this.setState({
        selectedTab: tabData.filter(tab => tab.active)[0] ? tabData.filter(tab => tab.active)[0].id : 0,
      });
    }
    // very hacky. force the selectedTab to reset if we switch away from admin
    // and then come back. This prevents the selected tab here and the one stored
    // in the admin tab component from getting out of synch
    // TODO: find a better solution for this
    if(false && (this.props.tabData && this.props.tabData[0] && !this.props.tabData[0].url) &&
            (prevProps.tabData && prevProps.tabData[0] && prevProps.tabData[0].url)){
      this.setState({
        selectedTab: this.props.tabData.filter(tab => tab.active)[0].id || 0,
      });
    }
    */
  }
  
  renderTab(tab){
    
    const urlRegexp = /\d\d\d\d-\d\d-\d\d/;
    const isDayURL = urlRegexp.test(this.props.currentPage);
    
    let cl = "";
    if (this.props.currentPage.includes(tab.url) ||
       this.props.currentPage.slice(1, this.props.currentPage.length) == tab.url ||
       (this.props.currentPage == "/" && tab.url == 'dashboard') ||
       (isDayURL && tab.url == 'calendar') ||
       (!tab.url && tab.id == this.state.selectedTab)) cl += "active ";

    //if the tab has a url property then create a link to that url
    if(tab.url){
      let u = tab.url;
      if(this.props.view && this.props.view.config.scope == 'day' && tab.url == 'calendar' && !isDayURL) u = this.props.view.config.day;

      return (
        //<li className={cl} key={tab.id} onClick={() => this.props.navigationHandler(u)}>{tab.name}</li>
        <li className={cl} key={tab.id} onClick={() => this.navTo(tab,u)}>{tab.name}</li>
      );

    //otherwise use the tab's onclick callback function
    // TODO: the "link" is still an <a/> element here for styling
    // consistancy. When we rework the styles, make it something that
    // makes more sense like a <span/> with the correct class
    }else{
      return (
        <li className={cl} key={tab.id}><a onClick={this.getTabClickFunction(tab).bind(this)}>{tab.name}</a></li>
      );
    }
  }
  
  render() {
    return (
      <div className="menuTabs">
        <div className="nd-row">
          <ul className="nav nav-tabs hidden-xs">
            {this.state.tabData && this.state.tabData.map(this.renderTab.bind(this))}
          </ul>
        </div>
      </div>
    );
  }
}

