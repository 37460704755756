import React from 'react';
import { FormControl, Row, Col } from 'react-bootstrap';
import moment from 'moment';

export default class ShiftTimes extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.props.handleInputChange.bind(this);
  }

  componentWillMount() {
    let h = moment(this.props.default);
    let m = h.format('mm');
    this.setState({
      hour: h,
      minute: m,
    });
  }

  render() {
    //TODO: handle both moment()s and badly formatted YYYY-MM-DD HH:00:00 dates (i.e. most of the dates returned by the api)
    let options = [];
    let timeStart = moment(this.props.times.range.start);
    let timeEnd = moment(this.props.times.range.end);

    while (timeEnd > timeStart) {
      options.push(
        <option value={timeStart.format('YYYY-MM-DD HH:mm:ss')} key={timeStart}>
          {timeStart.format('ddd HH')}
        </option>,
      );
      timeStart.add(1, 'hour');
    }

    if (this.state) {
      // console.log('shift time:');
      // console.log(this.props);
      // console.log(this.state);
      // console.log('default value is ' + moment(this.props.default).format('YYYY-MM-DD HH:mm:ss'));
      return (
        <Row>
          <Col xs={12}>
            <FormControl
              componentClass="select"
              defaultValue={moment(this.props.default).format('YYYY-MM-DD HH:00:00')}
              placeholder="select"
              onChange={this.setHour.bind(this)}
              ref="hour"
            >
              {options}
            </FormControl>
            <FormControl
              componentClass="select"
              defaultValue={this.state.minute}
              placeholder="select"
              onChange={this.setMinute.bind(this)}
              ref="minute"
            >
              <option value="00" key="00">
                00
              </option>
              <option value="15" key="15">
                15
              </option>
              <option value="30" key="30">
                30
              </option>
              <option value="45" key="45">
                45
              </option>
            </FormControl>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  setHour(val) {
    let h = moment(val.target.value);
    // console.log('hour: ', h.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('minute', this.state.minute);
    // console.log('new time : ', h.format('YYYY-MM-DD HH:' + this.state.minute));
    this.setState({ hour: h });
    this.handleInputChange(h.format('YYYY-MM-DD HH:' + this.state.minute));
  }

  setMinute(val) {
    // console.log('hour: ', this.state.hour.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('minute', val.target.value);
    // console.log('new time : ', this.state.hour.format('YYYY-MM-DD HH:' + val.target.value));
    this.setState({ minute: val.target.value });
    this.handleInputChange(this.state.hour.format('YYYY-MM-DD HH:' + val.target.value));
  }
}
