/* Here we define the heights that are being used in ContentBox and
 ** DialogBox.
 **
 ** You can add more sizes by adding a property to HEIGHTS and then add
 ** another case to getSizeInPixel. And you can also change the default
 ** size of those HEIGHTS constants, e.g. you can change the case of SMALL
 ** to be 150px instead of 200px. */

export const HEIGHTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  DIALOG_LARGE: 'dialog_large',
};

// TODO: Better responsive height implementation
export default function getSizeInPixel(size) {
  switch (size) {
    case HEIGHTS.SMALL:
      return '200px';
    case HEIGHTS.MEDIUM:
      return '450px';
    case HEIGHTS.DIALOG_LARGE:
      return '550px';
    case HEIGHTS.LARGE:
      return 'calc(100vh - 250px)';
    default:
      return null;
  }
}
