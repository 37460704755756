import React from 'react';
import { connect } from 'react-redux';
import { ListGroup, Modal, Image } from 'react-bootstrap';
import Shift from './Shift';
import ShiftCreateModal from './ShiftCreate/ShiftCreateModal';
import classNames from 'classnames';

class Duty extends React.Component {
  componentDidMount() {
    // console.log("Did:");
    // console.log(this.props);
    this.close = this.close.bind(this, this.props.data.label);
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  render() {
    // console.log("Duty rendering with : " );
    // console.log(this.props);
    return (
      <div style={this.showDuty()} className="duty">
        <div className={classNames('dutyLabel', this.props.data.class)}>
          {this.renderIcon()} {this.props.data.label}
        </div>
        <ListGroup fill>
          {this.props.data.shifts && this.props.data.shifts.length > 0 && this.renderShifts(this.props.data.shifts)}
        </ListGroup>

        <Modal show={this.state && this.state.showModal} onHide={this.close} dialogClassName="shiftCreateModal">
          <ShiftCreateModal
            data={this.props.data}
            day={this.props.day}
            closeAction={this.close}
            actions={this.props.actions}
          />
        </Modal>
      </div>
    );
  }

  renderShifts(shifts) {
    let that = this;
    return shifts.map(function (shift, key) {
      return <Shift data={shift} key={key} actions={that.props.actions} />;
    });
  }

  renderIcon() {
    const classes = this.props.data.class.split(' ');

    if (this.props.privs && this.props.privs.signups && this.props.privs.signups.edit === true) {
      if (!(classes.indexOf('alert') > -1)) {
        //does class array contain alert?
        return (
          <Image
            src={require('../../img/signup.svg').default}
            className="dutyIcon"
            onClick={this.open.bind(this, this.props.data.label)}
          />
        );
      } else {
        //TODO: what happens when alert?
        return (
          <Image
            src={require('../../img/signup.svg').default}
            className="dutyIcon"
            onClick={this.open.bind(this, this.props.data.label)}
          />
        );
      }
    } else {
      return <div className="dutyIcon" />;
    }
  }

  showDuty() {
    if (this.props.data.class.split(' ').indexOf('hideableDuty') > -1 && this.props.showHideable === false) {
      return {
        display: 'none',
      };
    }
  }
}

function mapStateToProps(state) {
  // console.log(state);
  return {
    privs: state.PrivilegeReducer,
  };
}

export default connect(mapStateToProps)(Duty);
