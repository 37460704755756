import React from 'react';
import { Form, FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';

export default class SkillInput extends React.Component {
  render() {
    return (
      <div className="form-inline">
        <h4>Skill Select</h4>
        {this.renderOptions(this.props.skills)}
      </div>
    );
  }

  renderOptions(skills) {
    const inputStyle = {
      maxWidth: '50px',
      padding: '5px',
    };

    let options = [];
    for (let i = 0; i < skills.length; i++) {
      let skill = skills[i];
      options.push(
        <Row style={{ paddingTop: '5px' }} key={i} className="formRow">
          <Col xs={3}>{skill.label}</Col>
          <Col xs={9}>
            <Form inline>
              <FormGroup>
                <FormLabel className="nopadding">Min&nbsp;</FormLabel>
                <FormControl
                  style={inputStyle}
                  type="text"
                  placeholder="1"
                  onChange={this.props.handleInputChange.bind(this, 'min_skill_' + skill.skill_id)}
                />{' '}
                <FormLabel className="nopadding">Max&nbsp;</FormLabel>
                <FormControl
                  style={inputStyle}
                  type="text"
                  placeholder="5"
                  onChange={this.props.handleInputChange.bind(this, 'max_skill_' + skill.skill_id)}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>,
      );
    }
    return options;
  }
}
