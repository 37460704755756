import React from 'react';
import { Form, FormGroup, Col, Card, FormControl, Button, FormLabel, Alert } from 'react-bootstrap';
import Loading from 'react-loading';
import { fetchMyAccountValues } from '../../actions/API';

export default class MyAccount extends React.Component {
  componentDidMount() {
    // console.log("MyAccount Did:");
    // console.log(this.props);
    let that = this;
    fetchMyAccountValues()
      .then(res => {
        // console.log('My account response:');
        // console.log(res);
        that.setState({ myAccount: res.data.data.myaccount });
      })
      .catch(function (err) {
        console.error(err);
        that.setState({
          loaded: true,
          err: true,
          err_msg: err,
        });
      });
  }

  save(e) {
    e.preventDefault();
    console.log(this.state.myAccount);
  }

  render() {
    if (this.state && this.state.myAccount) {
      return (
        <Card header="My Account" variant="primary">
          <Form horizontal>
            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={FormLabel} sm={3}>
                First Name
              </Col>
              <Col sm={9}>
                <FormControl type="text" defaultValue={this.state.myAccount.fname} />
              </Col>
            </FormGroup>

            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={FormLabel} sm={3}>
                Last Name
              </Col>
              <Col sm={9}>
                <FormControl type="text" defaultValue={this.state.myAccount.lname} />
              </Col>
            </FormGroup>

            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={FormLabel} sm={3}>
                Roster Label
              </Col>
              <Col sm={9}>
                <FormControl type="text" defaultValue={this.state.myAccount.roster_label} />
              </Col>
            </FormGroup>

            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={FormLabel} sm={3}>
                Username
              </Col>
              <Col sm={9}>
                <FormControl type="text" disabled={true} defaultValue={this.state.myAccount.username} />
              </Col>
            </FormGroup>

            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={FormLabel} sm={3}>
                Email
              </Col>
              <Col sm={9}>
                <FormControl type="text" disabled={true} defaultValue={this.state.myAccount.notification_email} />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col smOffset={3} sm={9}>
                <Button type="submit" onClick={this.save.bind(this)}>
                  Save
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Card>
      );
    } else if (this.state && this.state.loaded && this.state.err) {
      return (
        <div style={{ height: '300px', width: '300px', marginRight: 'auto', marginLeft: 'auto', marginTop: '20px' }}>
          <Alert variant="warning" style={{ paddingTop: '20px', marginLeft: 'auto', marginRight: 'auto' }}>
            {/*<Glyphicon glyph="alert" style={{display : 'inline'}} /> {this.state.err_msg.message}*/}
          </Alert>
        </div>
      );
    } else {
      return (
        <div className="hCenter">
          <Loading type="bars" color="#e3e3e3" />
        </div>
      );
    }
  }
}
