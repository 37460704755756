import React from 'react';
import ScheduleBuilderApp from '../ScheduleBuilder/ScheduleBuilderApp.js';

export default class UserSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  displaySchedule() {
    return <ScheduleBuilderApp scheduleData={this.props.scheduleData} />;
  }

  render() {
    //TODO integrate the schedule builder
    var contents = this.props.scheduleData ? (
      this.displaySchedule()
    ) : (
      <div className="naMessage">Schedule for this user is unavailable</div>
    );
    return <div className="userSchedule">{contents}</div>;
  }
}
