import React from 'react';
import { connect } from 'react-redux';
import { deleteShift } from '../../actions/API';
import { Modal, Image } from 'react-bootstrap';
import ShiftEditModal from './ShiftEdit/ShiftEditModal';

class Shift extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, tab: 'edit' };
    this.close = this.close.bind(this, this.props.data.label);
  }

  close() {
    this.setState({ showModal: false });
  }

  open(tab) {
    this.setState({ showModal: true, tab: tab });
  }

  render() {
    // console.log("Shift rendering with:");
    // console.log(this);
    if (this.props && this.props.privs) {
      return (
        <div className="shiftContainer">
          <div className="shiftLabel">
            {this.props.data.roster_label} [ {this.props.data.details} ]
            <Image
              title={this.props.data.edit_hovertext}
              src={require('../../img/editShift.png').default}
              style={this.iconStyle('edit')}
              onClick={this.open.bind(this, 'edit')}
              className="shiftIcon"
            />
            <Image
              title={this.props.data.exchange_hovertext}
              src={require('../../img/exchange.svg').default}
              style={this.iconStyle('exchange')}
              onClick={this.open.bind(this, 'exchange')}
              className="shiftIcon"
            />
            <Image
              src={require('../../img/userSpecificShift.png').default}
              style={this.iconStyle('request_pto')}
              onClick={this.open.bind(this, 'request_pto')}
              className="shiftIcon"
            />
            <Image
              title={this.props.data.split_hovertext}
              src={require('../../img/partialShift.png').default}
              style={this.iconStyle('edit')}
              onClick={this.open.bind(this, 'split')}
              className="shiftIcon"
            />
            <Image
              title={this.props.data.delete_hovertext}
              src={require('../../img/warning.png').default}
              style={this.iconStyle('delete')}
              onClick={this.deleteShift.bind(this)}
              className="shiftIcon"
            />
          </div>

          <Modal show={this.state && this.state.showModal} onHide={this.close} dialogClassName="shiftEditModal">
            <ShiftEditModal
              data={this.props.data}
              closeAction={this.close}
              actions={this.props.actions}
              tab={this.state.tab}
              privs={this.props.privs.shifts[this.props.data.shift_id]}
            />
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }

  iconStyle(action) {
    //determines if the icon should be displayed based on privs. only show if explicitly set to true
    if (
      this.props.privs &&
      this.props.privs.shifts &&
      this.props.privs.shifts[this.props.data.shift_id] &&
      this.props.privs.shifts[this.props.data.shift_id][action] === true
    ) {
      return {
        display: 'inline',
      };
    }
    return {
      display: 'none',
    };
  }

  deleteShift() {
    let that = this;
    deleteShift({ shift_id: that.props.data.shift_id })
      .then(response => {
        // console.log(response);
        let d = that.props.data.dutydate.split('-');
        that.props.actions.fetchDay({ year: d[0], month: d[1], day: d[2] });
        return response;
      })
      .then(response => {
        that.props.actions.pushNotification();
      })
      .catch(err => {
        console.log(err);
        that.props.actions.pushNotification();
      });
  }
}

function mapStateToProps(state) {
  return {
    privs: state.PrivilegeReducer,
  };
}

export default connect(mapStateToProps)(Shift);
