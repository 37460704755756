import React from 'react';
import { Row, Col, Button, FormLabel, Form, Popover, OverlayTrigger, Container } from 'react-bootstrap';
import Select from '../../FormBuilder/Select';
import Radio from '../../FormBuilder/Radio';
import TextArea from '../../FormBuilder/TextArea';
import ShiftTimes from '../../FormBuilder/ShiftTimes';
import { shiftCheck, createShift } from '../../../actions/API';

export default class AssignByStaff extends React.Component {
  constructor() {
    super();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.setState({
      duty_id: this.props.data.duty_id,
      roster_id: this.props.data.rankings.roster_list[0].id,
      dutydate: this.props.data.dutydate,
      timein: this.props.config.times.default.timein,
      timeout: this.props.config.times.default.timeout,
      notes: '',
      hidden: false,
      skip_timeclock_create: false,
      cat_id: null,
      preset_timeclock_cat_id: null,
      shift_bid_id: null,
      list_id: null,
      event_id: null,
      position_id: null,
      apparatus_id: null,
      child_timein: null,
      child_timeout: null,
      test: 1,
      submitButtonLoading: false,
      submitButtonEnabled: false,
      shiftChecker: null,
    });
  }

  render() {
    // console.log('AssignByStaff rendering with');
    // console.log(this.props);
    if (!this.state) return null;
    //TODO: maybe abstract the checkboxes into FormBuilder components? Unsure if they will need that level of customization...
    return (
      <Container className="assignByStaff">
        <Row className="topPaddingMd">
          <Col xs={6}>
            <Row className="formRow">
              <Col componentClass={FormLabel} xs={3}>
                Shift Times
              </Col>
              <Col xs={9}>
                <ShiftTimes
                  label="Start:"
                  name="timein"
                  times={this.props.config.times}
                  default={this.props.config.times.default.timein}
                  handleInputChange={this.handleInputChange}
                />
                <ShiftTimes
                  label="End:"
                  name="timeout"
                  times={this.props.config.times}
                  default={this.props.config.times.default.timeout}
                  handleInputChange={this.handleInputChange}
                />
              </Col>
            </Row>

            <Radio
              label="Rank"
              name="cat_id"
              handleInputChange={this.handleInputChange.bind(this)}
              options={this.props.config.roster_dropdown.ranking_options}
            />
            <Select
              label="Select from Roster"
              name="roster_id"
              handleInputChange={this.handleInputChange.bind(this)}
              options={this.props.data.rankings.roster_list}
            />
            <TextArea label="Notes" name="notes" handleInputChange={this.handleInputChange.bind(this)} />
            <Select
              label="Shift Category"
              name="cat_id"
              handleInputChange={this.handleInputChange.bind(this)}
              options={this.props.config.shift_categories}
            />
            {this.props.config.options.hide_shift && (
              <Row className="formRow">
                <Col componentClass={FormLabel} xs={3}>
                  Hide Shift?
                </Col>
                <Col xs={9}>
                  <Form.Check
                    type="checkbox"
                    name="hidden"
                    checked={this.state.hidden}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            )}
            {this.props.config.options.skip_timeclock_create && (
              <Row>
                <Col componentClass={FormLabel} xs={3}>
                  Hide Shift?
                </Col>
                <Col xs={9}>
                  <Form.Check
                    type="checkbox"
                    name="skip_timeclock_create"
                    checked={this.state.skip_timeclock_create}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={6}>
            <Button
              onClick={this.submitAssignByStaffForm.bind(this)}
              variant="primary"
              className="hCenter"
              disabled={!this.state.submitButtonEnabled && this.props.config.shift_check}
              type="submit"
            >
              {' '}
              {this.state.submitButtonLoading && (
                <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate" />
              )}{' '}
              Submit
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            {this.state.shiftChecker && (
              <div className="shiftCheckMsg">{this.state.shiftChecker.msg || this.state.shiftChecker.errmsg}</div>
            )}
            {this.state.shiftChecker && this.renderShiftInfoButtons(this.state.shiftChecker)}
          </Col>
        </Row>
      </Container>
    );
  }

  submitAssignByStaffForm(e) {
    e.preventDefault();
    const params = this.prepareParamsFromForm();
    let that = this;
    createShift(params).then(response => {
      // console.log(response);
      that.props.actions.pushNotification();
      let d = that.props.data.dutydate.split('-');
      that.props.actions.fetchDay({ year: d[0], month: d[1], day: d[2] });
    });
  }

  handleInputChange(name, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // console.log('v ' + value);
    // console.log('n ' + name);
    // console.log(name + ': ' + value);

    this.setState(
      {
        [name]: value,
      },
      this.checkShiftCreateValid,
    );
  }

  checkShiftCreateValid() {
    if (this.props.config.shift_check === false) {
      this.setState({ submitButtonEnabled: true });
      return;
    }
    // console.log("Checking...");
    // console.log(this.state);
    const params = this.prepareParamsFromForm();
    this.setState({ submitButtonLoading: true, submitButtonEnabled: false });
    shiftCheck(params)
      .then(response => {
        // console.log('shift check response');
        // console.log(response);
        if (response.data.success === '1' || response.data.success === 1) {
          this.setState({
            shiftChecker: response.data,
            submitButtonLoading: false,
            submitButtonEnabled: true,
          });
        } else if (response.data.success === '0' || response.data.success === 0) {
          this.setState({
            shiftChecker: response.data,
            submitButtonEnabled: false,
            submitButtonLoading: false,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  prepareParamsFromForm() {
    //required fields
    let params = {
      duty_id: this.state.duty_id,
      roster_id: this.state.roster_id,
      dutydate: this.state.dutydate,
    };

    //optional, append to params if set. allows for per-field appendage (see notes and hidden)
    this.state.apparatus_id && (params.apparatus_id = this.state.apparatus_id);
    this.state.cat_id && (params.cat_id = this.state.cat_id);
    this.state.child_timein && (params.child_timein = this.state.child_timein);
    this.state.child_timeout && (params.child_timeout = this.state.child_timeout);
    this.state.event_id && (params.event_id = this.state.event_id);
    this.state.hidden && this.props.config.options.hide_shift && (params.hidden = this.state.hidden);
    this.state.list_id && (params.list_id = this.state.list_id);
    this.state.notes && this.state.notes.length > 1 && (params.notes = this.state.notes);
    this.state.position_id && (params.position_id = this.state.position_id);
    this.state.preset_timeclock_cat_id && (params.preset_timeclock_cat_id = this.state.preset_timeclock_cat_id);
    this.state.shift_bid_id && (params.shift_bid_id = this.state.shift_bid_id);
    this.state.skip_timeclock_create &&
      this.props.config.options.skip_timeclock_create &&
      (params.skip_timeclock_create = this.state.skip_timeclock_create);
    this.state.timein && (params.timein = this.state.timein);
    this.state.timeout && (params.timeout = this.state.timeout);

    return params;
  }

  renderShiftInfoButtons(check) {
    // console.log(check);
    if (check.code === 400) return;
    let cats = ['annual', 'month', 'overtime', 'payperiod'];
    let data = []; //to be returned

    for (let i = 0; i < cats.length; i++) {
      let cat = check.data[cats[i]];
      let info = [];
      for (let key in cat.data) {
        if (cat.data.hasOwnProperty(key)) {
          // console.log(key + ': ' + cat.data[key]);
          info.push(
            <p>
              {key} : {cat.data[key]}
            </p>,
          );
        }
      }
      let popover = (
        <Popover id="popover-positioned-top" title={cat.label}>
          {info}
        </Popover>
      );
      data.push(
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <Button>{cats[i]}</Button>
        </OverlayTrigger>,
      );
    }
    return data;
  }
}
