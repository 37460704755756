import React from 'react';

const Dropdown = props => {
  return (
    <div className={props.wrapperClass}>
      <label className="dropdown-label" htmlFor={props.name}>
        {props.label}
      </label>
      <span className="plain-select">
        <select
          id={props.name}
          name={props.name}
          value={props.value ? props.value : props.default_option.label}
          onChange={e => props.onChange(e, props.endpoint, props.method)}
        >
          <option key={props.default_option.id} disabled={true} value={props.default_option.label}>
            {props.default_option.label}
          </option>
          {props.options.map(option => (
            <option key={`opt#${option.id}`} className={option.class} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
      </span>
    </div>
  );
};

export default Dropdown;
