import React from 'react';
import dayjs from 'dayjs';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const labelStyle = {
    fontWeight: 'bold',
    color: 'black',
    display: 'block',
    position: 'static',
};

const MuiDateRange = ({
  startLabel,
  endLabel,
  onChange,
  start,
  end,
  format = 'MM/DD/YYYY',
  startDefault,
  endDefault,
  hovertext,
}) => {
  const [startDate, setStartDate] = React.useState(startDefault || start);
  const [endDate, setEndDate] = React.useState(endDefault || end);

  const handleStartDateChange = newStartDate => {
    try {
      onChange({
        start: newStartDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
      });
      setStartDate(newStartDate);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEndDateChange = newEndDate => {
    try {
      onChange({
        start: startDate.format('YYYY-MM-DD'),
        end: newEndDate.format('YYYY-MM-DD'),
      });
      setEndDate(newEndDate);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <abbr title={hovertext}>
        <Grid>
          <FormLabel sx={labelStyle}>{startLabel}</FormLabel>
          <DatePicker
            name="start"
            format={format}
            value={dayjs(startDate)}
            minDate={dayjs(start)}
            maxDate={dayjs(end)}
            onChange={handleStartDateChange}
            renderInput={params => {
              return <TextField variant="outlined" size="small" {...params} color="primary" sx={{ maxWidth: 240 }} />;
            }}
          />
        </Grid>
        <Grid>
          <FormLabel sx={labelStyle}>{endLabel}</FormLabel>
          <DatePicker
            name="end"
            format={format}
            value={dayjs(endDate)}
            minDate={dayjs(start)}
            maxDate={dayjs(end)}
            onChange={handleEndDateChange}
            renderInput={params => {
              return <TextField variant="outlined" size="small" {...params} color="primary" sx={{ maxWidth: 240 }} />;
            }}
          />
        </Grid>
      </abbr>
    </LocalizationProvider>
  );
};

export default MuiDateRange;
