import React from 'react';
import { Row, Col, Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';

export default class TimeOff extends React.Component {
  componentDidMount() {
    //TODO: spec, do everything
    // timeOffConfig().then(response => {
    //   // console.log('dat response:', response);
    //   this.setState({
    //     config : response.config
    //   });
    // })
    // .catch(err => {
    //   console.log(err);
    //   this.setState({
    //     loaded : true,
    //     err : true,
    //     err_msg : err,
    //     config : null
    //   })
    // })
  }

  render() {
    return (
      <Row>
        <Form horizontal className="topPaddingMd">
          <FormGroup controlId="shiftCategory">
            <Col componentClass={FormLabel} sm={3}>
              User
            </Col>
            <Col sm={7}>
              <FormControl componentClass="select" defaultValue="Select" placeholder="select" name="exchangeUser">
                whoo
              </FormControl>
            </Col>
          </FormGroup>

          <FormGroup controlId="hideShift">
            <Col componentClass={FormLabel} sm={3}>
              Full Shift
            </Col>
            <Col sm={9}>
              <Form.Check type="checkbox" name="fullShift" />
            </Col>
          </FormGroup>
        </Form>

        <Col sm={2} smOffset={3}>
          <Button variant="primary" className="hCenter" disabled={true} type="submit">
            {' '}
            Submit
          </Button>
        </Col>
      </Row>
    );
  }

  handleClick(btn, e) {
    e.preventDefault();
  }
}
