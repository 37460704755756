import React from 'react';
import { dynamicOEButtonCall } from '../../actions/API';
import Router from '../AppLayout/Router';
import Buttonbar from './Buttonbar';

export default class Ribbon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionMap: {},
      selected: [],
      menu: [],
      required: [],
      optional: [],
      routerData: null,
      endpoint_info: {
        endpoint: '',
        endpoint_type: '',
        variables: {},
      },
    };

    this.parseData = this.parseData.bind(this);
  }

  componentDidMount() {
    this.parseData(this.props.data);
  }

  parseData({ config, menu, variables: updateVariables }, update = false) {
    const { endpoint, method, variables = {}, selected = [] } = config;
    //Gather all variables to track and their preset values
    let variablesToTrack = {
      ...this.state.endpoint_info.variables,
    };
    if (!update) {
      for (let v of [...config.required, ...config.optional]) {
        variablesToTrack[v] = variables[v] || null;
      }
    }
    if (update) {
      variablesToTrack = Object.assign({}, variablesToTrack, updateVariables);
    }
    //Check if all the required variables are in place to make the call
    if (!update && this.validateRequiredVariables(variables)) {
      //Make the call
      dynamicOEButtonCall(`/${endpoint}`, method, variables).then(response => {
        this.setState({
          menu,
          selected,
          routerData: {
            id: new Date().getTime(),
            ...response.data,
          },
          endpoint_info: {
            endpoint: config.endpoint,
            endpoint_type: config.endpoint_type,
            variables: variablesToTrack,
          },
        });
      });
    } else {
      this.setState({
        menu,
        selected,
        endpoint_info: {
          endpoint: config.endpoint,
          endpoint_type: config.endpoint_type,
          variables: variablesToTrack,
        },
      });
    }

    //TODO:
    // - Make the Call to get the Endpoint ********************************************************************
    // - Use Router to load the Working Area ******************************************************************
    // - Display the Menu *************************************************************************************
    // - Handle default selected Options (could be using values in config) ************************************
    // - Integrate Filters
    // - Style buttons
    // - Handle Selection of buttons with endpoint and without endpoints (using the required variables)
  }

  validateRequiredVariables(variables) {
    if (variables) {
      for (let key of this.props.data.config.required) {
        if (!variables[key]) {
          return false;
        }
      }
      return true;
    } else if (this.props.data.config.required.length === 0) {
      return true;
    }
  }

  handleOnClick(data) {
    const { endpoint, endpoint_type, variables: variablesFromBtn } = data;
    let variables = {
      ...this.state.endpoint_info.variables,
    };
    variables = Object.assign(variables, variablesFromBtn);
    //Validate the new Variables
    if (!this.validateRequiredVariables(variables)) {
      return null;
    }

    const endpoint_info = {
      ...this.state.endpoint_info,
      variables,
    };
    const selected = Object.values(endpoint_info.variables).filter(v => v);

    if (endpoint && endpoint_type) {
      //Use the current data as Router Info
      this.setState({
        routerData: {
          id: new Date().getTime(),
          ...data,
          //variables: data.variables ? Object.assign({}, data.variables, endpoint_info.variables) : endpoint_info.variables,
        },
        selected,
        endpoint_info,
      });
    } else {
      const { endpoint, method } = this.props.data.config;

      //Call Component Request to get the right RouterInfo
      dynamicOEButtonCall(`/${endpoint}`, method, variables).then(response => {
        this.setState({
          routerData: {
            id: new Date().getTime(),
            ...response.data,
            variables: response.variables ? Object.assign({}, variables, response.variables) : {},
          },
          selected,
          endpoint_info,
        });
      });
    }
  }

  render() {
    const ribbon_class = process.env.REACT_APP_THEME == "epr" ? 'Ribbonbg' : 'Ribbonbg-netduty';
    const wrapper_class = process.env.REACT_APP_THEME == "epr" ? 'RibbonWrapper' : 'RibbonWrapper-netduty';

    return (
      <div className={ribbon_class}>
        <div className={wrapper_class}>
          {this.state.menu.map((buttonbar, index) => {
            let selected = null;
            buttonbar.map(btn => {
              if (this.state.selected.includes(btn.id)) {
                selected = btn.id;
              }
              return btn;
            });
            return (
              <div key={index + selected} className="vertical-line">
                <Buttonbar
                  groupButton={index}
                  selectorMode={true}
                  selected={selected}
                  buttonbar={buttonbar}
                  onClick={this.handleOnClick.bind(this)}
                />
              </div>
            );
          })}
        </div>
        {this.state.routerData && (
          <Router
            key={'router' + this.state.routerData.id}
            content={this.state.routerData}
            params={this.state.routerData.variables}
            globalActions={this.props.globalActions}
            updateRibbon={this.parseData}
            history={this.props.history}
          />
        )}
      </div>
    );
  }
}
