import React from 'react';
import { ListGroupItem, Card } from 'react-bootstrap';
import Duties from './Duties';

export default class Stations extends React.Component {
  render() {
    // console.log("Rendering stations with:");
    // console.log(this.props.data);
    const that = this;
    return (
      <div>
        {this.props.data &&
          this.props.data.length > 0 &&
          this.props.data.map(function (station, key) {
            // console.log(station);
            // console.log(station.containers);
            // let cl = "stationPanel " + station.class;
            // let dutiesClass = station.hasOwnProperty(containers) ? station.containers[0].class : "";
            // let data = station.hasOwnProperty(containers) ? station.containers[0].duties : "";
            if (station.containers) {
              let data = [];
              for (let i = 0; i < station.containers.length; i++) {
                let ct = station.containers[i];
                if (ct.label) {
                  //duties can optionally be contained in a 'container' which can then be styled dynamically
                  data.push(
                    <Card key={key} className={ct.class} collapsible defaultExpanded header={'▾ ' + ct.label}>
                      <ListGroupItem key={key} fill>
                        <Duties
                          className={ct.class}
                          data={ct.duties}
                          key={key}
                          day={that.props.day}
                          showHideable={that.props.showHideable}
                          actions={that.props.actions}
                        />
                      </ListGroupItem>
                    </Card>,
                  );
                } else {
                  data.push(
                    <ListGroupItem key={key} fill>
                      <Duties
                        className={ct.class}
                        data={ct.duties}
                        key={key}
                        day={that.props.day}
                        showHideable={that.props.showHideable}
                        actions={that.props.actions}
                      />
                    </ListGroupItem>,
                  );
                }
              }
              return (
                <Card key={Math.random()} collapsible defaultExpanded header={'▾ ' + station.label}>
                  {data}
                </Card>
              );
            } else {
              return (
                <Card key={key} className={station.class} collapsible defaultExpanded header={'▾ ' + station.label}>
                  <ListGroupItem key={key} fill>
                    <Duties
                      className={station.class}
                      data={null}
                      key={key}
                      day={that.props.day}
                      showHideable={that.props.showHideable}
                      actions={that.props.actions}
                    />
                  </ListGroupItem>
                </Card>
              );
            }
          })}
      </div>
    );
  }
}
