import React from 'react';
import './Navigation.scss';

export default class Navigation extends React.Component {
  handleNavigation(node) {
    node && this.props.selectOnDeckNode(node, true);
  }

  render() {
    const { currentlyViewing, nextObject, prevObject } = this.props;

    return (
      <div className="OnDeckNavigation">
        <div className="navBack" onClick={this.handleNavigation.bind(this, prevObject)}>
          <span className="glyphicon glyphicon-menu-left" />
          <span className="navLabel">{prevObject && prevObject.label}</span>
        </div>
        <div className="currentlyViewing">
          <span>{currentlyViewing && currentlyViewing.label}</span>
        </div>
        <div className="navForward" onClick={this.handleNavigation.bind(this, nextObject)}>
          <span className="navLabel">{nextObject && nextObject.label}</span>
          <span className="glyphicon glyphicon-menu-right" />
        </div>
      </div>
    );
  }
}
