import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/GlobalActions';
import TimeClock from '../components/TimeClock';

export const TimeClockPage = props => {
  // console.log("AdminPage page");
  // console.log(props);
  // console.log('-----');
  return <TimeClock GlobalActions={props.actions} auth={props.auth} notifications={props.notifications} />;
};

function mapStateToProps(state) {
  // console.log("Header container, mapping state to props:");
  // console.log(state);
  // console.log('-----');
  return {
    auth: state.APIReducer.auth,
    notifications: state.APIReducer.data.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeClockPage);
