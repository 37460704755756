import React from 'react';
import { dynamicOEButtonCall } from '../../actions/API';
import ContentBox from '../Common/ContentBox';
//import {isItMobile} from '../DayView/DayView';
import { IdleTimer } from '../IdleTimer';

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      refresh_timer: null,
      dutys_black_list: [],
      stations_black_list: [],
    };
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);
  }

  componentDidMount() {
    this.parseData(this.props.data.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.data && this.props.data.data) {
      this.parseData(this.props.data.data);
    }
  }

  parseData(data) {
    if (!data || !data.cols) {
      return null;
    }
    let duty_filters;
    let station_filters;
    let stations_black_list = [];
    let dutys_black_list = [];
    const columns = data.cols;

    for (let column of columns) {
      if (Array.isArray(column.boxes)) {
        for (let box of column.boxes) {
          if (box.configs && box.configs.box_type === 'dutyroster') {
            if (box.data && box.data.filters) {
              const { filters } = box.data;
              if (filters.duty) {
                duty_filters = Object.keys(filters.duty).map(key => filters.duty[key]);
              }
              if (filters && filters.station) {
                station_filters = Object.keys(filters.station).map(key => filters.station[key]);
              }
            }
          }
        }
      }
    }

    //Creating Black Lists
    if (duty_filters && duty_filters.length > 0) {
      duty_filters
        .filter(filter => !filter.selected)
        .map(filter => {
          if (filter.controlled && filter.controlled.length > 0) {
            for (let id of filter.controlled) {
              dutys_black_list.push(id);
            }
          }
          return filter;
        });
    }
    if (duty_filters && station_filters.length > 0) {
      station_filters
        .filter(filter => !filter.selected)
        .map(filter => {
          if (filter.controlled && filter.controlled.length > 0) {
            for (let id of filter.controlled) {
              stations_black_list.push(id);
            }
          }
          return filter;
        });
    }

    this.setState({
      stations_black_list,
      dutys_black_list,
      columns,
      refresh_timer: data.refresh_timer,
    });
  }

  refreshTheTimer() {
    const { refresh_timer } = this.state;

    dynamicOEButtonCall(
      refresh_timer.refresh_endpoint,
      refresh_timer.refresh_method,
      refresh_timer.refresh_variables,
    ).then(response => {
      if (response.status >= 200 && response.status < 300) {
        this.parseData(response.data);
      }
    });
  }

  /*
   ** Creates a column of collapsible boxes
   **
   ** param {Object} - col
   */
  getColumn(col) {
    return col.boxes.map((box,index) => (
      <ContentBox
        key={box.id + index}
        data={box}
        dutys_black_list={this.state.dutys_black_list}
        stations_black_list={this.state.stations_black_list}
        refreshPage={this.props.refreshObjects}
        handleQuickLink={this.handleQuickLink.bind(this)}
        idleTimer={this.idleTimer}
        onSuccess={() => this.props.refreshObjects()}
        columnGetFrom={'DashboardCoulmn'}
        history={this.props.history}
      />
    ));
  }

  /* param {Array} - columns*/
  renderColumns(columns) {
    return columns.map((col, index) =>
      col.boxes.length > 0 ? (
        <div key={'col' + index} className={col.boxes.length === 0 ? null : 'column'}>
          {this.getColumn(col)}
        </div>
      ) : null,
    );
  }

  /* param {Object} - open_link */
  handleQuickLink(open_link) {
    let calendar_tab = this.props.tabs.filter(tab => tab.name.toUpperCase() === 'CALENDAR')[0];
    this.props.globalActions.storeHeaderTabs(
      this.props.tabs.map(storedTab => {
        if (storedTab.name.toUpperCase() === calendar_tab.name.toUpperCase()) {
          //Switch the Content to Calendar
          calendar_tab.onClick();
          //Switch the Calendar Tab to be active:true and pass `open_link`
          return Object.assign({}, storedTab, {
            active: true,
            open_link,
          });
        }
        return Object.assign({}, storedTab, { active: false });
      }),
    );
  }

  onIdle() {
    if (this.idleTimer.isIdle()) {
      //double checking
      this.refreshTheTimer();
      this.idleTimer.reset();
    }
  }

  render() {
    const { columns } = this.state;
    const dash_style = (process.env.REACT_APP_THEME == "epr") ? "dashboard" : "dashboard-netduty"; 
    if (columns.length > 0) {
      const renderedDashboard = (
        <div key="dashboard-id" className={dash_style}>
          <div key="dashboard-content-id" className="dashboard-content">{this.renderColumns(columns)}</div>
        </div>
      );

      if (this.state.refresh_timer) {
        return (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            key="idleTimer"	
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={3 || this.state.refresh_timer.idletime}
          >
            {renderedDashboard}
          </IdleTimer>
        );
      } else {
        return renderedDashboard;
      }
    } else {
      return (
        <div className={dash_style}>
          <strong>No data</strong>
        </div>
      );
    }
  }
}
