import React from 'react';
import axios from 'axios';
import qs from 'qs';
import Form from '../components/Common/Form';
import concatURL from '../helpers/concatURL';

export default class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formData: null,
    };

    this.params = {};
  }

  componentWillMount() {
    //Collect all the params from the URL
    const url = new URL(window.location.href);
    url.searchParams.forEach((value, key) => {
      this.params = {
        ...this.params,
        [key]: value,
      };
    });
  }

  componentDidMount() {
    this.setState({ loading: true });

    axios
      .request({
        url: concatURL('/services/form'),
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: qs.stringify(this.params),
      })
      .then(response => {
        let formData;
        if (response.status >= 200 && response.status < 300 && response.data) {
          formData = response.data.data;
        }

        this.setState({
          formData,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        console.error('error', err);
      });
  }

  render() {
    return (
      <div className="login-container">
        <div style={{ marginTop: '50px', width: '100%' }}>
          {this.state.formData && <Form formData={this.state.formData} standAlone={true} />}
        </div>
      </div>
    );
  }
}
