import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/API';
import { fetchUserPageConfig, clearApiKey } from '../actions/GlobalActions';
import { injectStyles } from '../actions/Style';

class AuthContainer extends React.Component {
  componentDidMount() {
    if (this.props.GlobalActions) {
      this.props.GlobalActions.fetchUserPageConfig()
        .then(response => {
          if (response.config) {
            response.config.stylesheet && require(`../styles/${response.config.stylesheet}`);
            response.config.dynamic_styles && injectStyles(response.config.dynamic_styles);
          }
        })
        .catch(err => console.log(err));
    }
  }

  render() {
    // console.log('auth container rendering with');
    // console.log(this);
    if (this.props.isLoggedIn) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

// Grab a reference to the current URL. If this is a web app and you are
// using React Router, you can use `ownProps` to find the URL. Other
// platforms (Native) or routing libraries have similar ways to find
// the current position in the app.
function mapStateToProps(state, ownProps) {
  // console.log('mappin state to props in authcontainer');
  // console.log(state);
  // console.log(ownProps);
  return {
    isLoggedIn: state.APIReducer.auth.loggedIn,
    // currentURL: ownProps.location.pathname,
    data: state.APIReducer.auth,
  };
}

function mapDispatchToProps(dispatch) {
  // console.log('mappin dispatch to props');
  // console.log(dispatch);
  return {
    actions: bindActionCreators(actions, dispatch),
    GlobalActions: bindActionCreators({ fetchUserPageConfig }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
