import axios from 'axios';
import Cookies from 'universal-cookie';
import concatURL from '../helpers/concatURL';
import React from 'react';

class APIKey extends React.Component {
  constructor(props) {
    super(props);
    this.keyUpdated = new Date();
    this.key = null;
    this.ax = null;
  }

  getKey() {
    let now = new Date();
    const timeDiff = Math.abs(now - this.keyUpdated) / 36e5;

    if (this.key === null || timeDiff > 0.8) {
      const cookies = new Cookies();
      let key = cookies.get('ndAPIKey');
      if (key) {
        this.setKey(key);
        return Promise.resolve(key);
      } else {
        const pathname = window.location.pathname;
        if (pathname.includes('reset') || pathname.includes('form') || pathname.includes('setup')) {
          return Promise.resolve(undefined);
        } else {
          return Promise.reject('No Key was found');
        }
      }
    } else {
      return Promise.resolve(this.key);
    }
  }

  setKey(key) {
    this.key = key;
  }

  get(endpoint) {
    return this.getKey()
      .then(key => {
        console.log('Using key ', this.key);
        return axios.get(concatURL(endpoint), { headers: { 'X-API-KEY': key } }).then(res => {
          return res;
        });
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }
}

// TODO: Find a better way to do this, maybe export the entire functionality to AppContainer.js
// eslint-disable-next-line
export default new APIKey();
