import React from 'react';
import MyAccount from './MyAccount';
import { Row, Col } from 'react-bootstrap';

export default class Settings extends React.Component {
  componentWillMount() {}

  render() {
    return (
      <div className="topPaddingMd">
        <Row>
          <Col xs={12}>
            <MyAccount />
          </Col>
        </Row>
      </div>
    );
  }
}
