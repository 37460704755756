//Navigation view for smaller screens (x<768)

import classNames from 'classnames';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from 'react-onclickoutside';

class AccordionNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      noAnimation: true,
      tabData: null,
    };
  }

  componentDidMount(){
    const tabData = this.props.userTabs.map((tab,index) => {return {
      url: tab.route, 
      id: index,
      active: this.state.selectedTab == index,
      name: tab.label,
    } });
    this.setState({tabData: tabData});
  }

  componentDidUpdate(prevProps,prevState){
    let tabData = this.state.tabData;

    if (this.props.currentPage.includes('/user')) {
      tabData = this.props.userTabs.map((tab,index) => {return {
        url: tab.route, 
        id: index,
        active: this.state.selectedTab == index,
        name: tab.label,
        } });
    } else if (this.props.currentPage.includes('/admin')) {
      tabData = this.props.adminTabs.map((tab,index) => {return {
        url: tab.route, 
        id: index,
        active: this.state.selectedTab == index,
        name: tab.label,
        } });
    } else if (this.props.currentPage.includes('/orgman')) {
      tabData = this.props.orgmanTabs.map((tab,index) => {return {
        url: tab.route, 
        id: index,
        active: this.state.selectedTab == index,
        name: tab.label,
        } });
    }
    if ( tabData.length > 0 && (!prevState.tabData || prevState.tabData.length == 0))  {
      this.setState({tabData: tabData}); 
    } else if ( tabData.length > 0 && prevState.tabData[0].url != tabData[0].url) {
      this.setState({tabData: tabData}); 
    };

  }

  handleClickOutside() {
    this.state.show && this.setState({ show: false });
  }

  toggleMenu() {
    this.setState({
      show: !this.state.show,
      noAnimation: false,
    });
  }

  selectOption(tab) {
    this.toggleMenu();
    this.props.navigationHandler(tab.url);
  }
  //Navigation component for when the page is xs
  //TODO: https://stackoverflow.com/questions/35687353/react-bootstrap-link-item-in-a-navitem
  //TODO: merge with TabNav
  render() {
    const extraClass = this.state.show ? '' : this.state.noAnimation ? ' display-none' : ' hide-menu';
    return (
      <div className="AccordionNavigation">
        <FontAwesomeIcon className="menu-icon hidden-sm hidden-md hidden-lg" icon="fa-bars" onClick={this.toggleMenu.bind(this)} />

        <ul className={classNames('menu-list', extraClass)}>
          {this.state.tabData && this.state.tabData.map(tab => (
            <li className="menu-option" onClick={() => this.selectOption(tab)} key={tab.id}>
              {tab.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default onClickOutside(AccordionNavigation);
