import React from 'react';
import Drag from './Drag';

export default class APIResponseDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.confirm = this.confirm.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  componentDidUpdate(prevProps) {
    // if we are updating to open, open the dialog. handle closing on our own
    if (this.props.open && !prevProps.open && this.props.response) {
      this.openDialog();
    }
  }

  closeDialog(e) {
    e && e.stopPropagation(); //Prevent the propagation of the Click event
    this.setState(
      {
        open: false,
      },
      this.props.onClose && this.props.onClose(),
    );
  }

  openDialog() {
    this.setState({
      open: true,
    });

    const { notify, response } = this.props;

    // if this is a NOTIFY dialog, close after 30 seconds, unless it's for confirmation
    if (notify && (!response.data || !response.data.confirm)) {
      if (response.data && response.data.timeout) {
        window.setTimeout(this.closeDialog, response.data.timeout);
      }
    }
  }

  confirm(e) {
    let { button } = this.props;
    if (typeof button === 'undefined') return;

    button.confirm = 1;
    const fun = this.props.buttonCallback(button);
    if (typeof fun === 'function') {
      fun();
    }
    this.closeDialog(e);
  }

  getConfirm() {
    const { data } = this.props.response;
    return (
      <Drag close={this.closeDialog} className={data.class} help={data.help} headerText={data.msg}>
        <div className="formDialog">{<div dangerouslySetInnerHTML={{ __html: data.fullmsg }} />}</div>
        <div className="confirmButtons">
          <input
            key="buttonConfirm"
            type="button"
            className="formButton nd-button green"
            value="Confirm"
            onClick={this.confirm}
          />
          <input
            key="buttonCancel"
            type="button"
            className="formButton nd-button red"
            value="Cancel"
            onClick={this.closeDialog}
          />
        </div>
      </Drag>
    );
  }

  getError() {
    const { response } = this.props;

    if (response && response.data && response.data.errmsg) {
      return (
        <Drag close={this.closeDialog} className={response.data.class} headerText={response.data.msg}>
          <div className="formDialog">{<div dangerouslySetInnerHTML={{ __html: response.data.errmsg }} />}</div>
        </Drag>
      );
    } else {
      return null;
    }
  }

  getSuccess() {
    const { response } = this.props;
    if (response && response.data && response.data.msg) {
      return (
        <Drag close={this.closeDialog} className={response.data.class} help={response.data.help}>
          <div className="formDialog">
            <div dangerouslySetInnerHTML={{ __html: response.data.msg }} />
          </div>
        </Drag>
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.state.open && this.props.response) {
      if (this.props.response.data && this.props.response.data.confirm) {
        return this.getConfirm();
      } else if (this.props.response.status === 200 || this.props.response.status === 201) {
        // PJW Bugfix: Delete the Confirm button if not explicitly requested
        let { button } = this.props;
        if (button) {
          delete button.confirm;
        }

        return this.getSuccess();
      } else {
        return this.getError();
      }
    } else {
      return null;
    }
  }
}
