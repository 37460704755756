import React from 'react';
import { FormControl, Col, Row, FormLabel } from 'react-bootstrap';

export default class TextArea extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Row className="formRow">
        <Col componentClass={FormLabel} xs={3}>
          {this.props.label}
        </Col>
        <Col xs={9}>
          <FormControl
            componentClass="textarea"
            placeholder="Enter notes here..."
            onChange={this.props.handleInputChange.bind(this, this.props.name)}
            name={this.props.name}
          />
        </Col>
      </Row>
    );
  }
}
