import React from 'react';
import { FormLabel, FormControl, FormGroup, Col, Row } from 'react-bootstrap';

export default class Select extends React.Component {
  constructor() {
    super();
    this.state = {
      value: null,
    };
  }

  render() {
    return (
      <Row className="formRow formBuilderSelect">
        <FormGroup>
          <Col componentClass={FormLabel} xs={3}>
            {this.props.label}
          </Col>
          <Col xs={9}>
            <FormControl
              componentClass="select"
              className="formControlSelect"
              placeholder="select"
              onChange={this.props.handleInputChange.bind(this, this.props.name)}
            >
              {this.renderOptions(this.props.options)}
            </FormControl>
          </Col>
        </FormGroup>
      </Row>
    );
  }

  renderOptions(opts) {
    let options = [];
    for (let key in opts) {
      if (opts.hasOwnProperty(key)) {
        options.push(
          <option key={key} value={opts[key].id}>
            {opts[key].label || opts[key]}
          </option>,
        );
      }
    }
    return options;
  }
}
