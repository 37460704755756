export const DELETE = 'DELETE';
export const DISABLE = 'PATCH';
export const SAVE = 'PUT';
export const POST = 'POST';
export const CLEAR = 'clear_tmp_data';
export const OPEN_NEW_WINDOW = '_blank';
//The value should be dialog_box because we will have dialog_form as well.
export const DIALOG_BOX = 'dialog';
export const OUTPUT = 'output';
export const REDIRECT = 'redirect';
