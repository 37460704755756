import React from 'react';
import { getTimeClock } from '../actions/API';
import Tabs from './AppLayout/Tabs.js';

export default class TimeClock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonTabs: [],
      error: null,
    };
  }

  componentDidMount() {
    getTimeClock().then(response => {
      if (response.data && response.data.config) {
        this.setState({
          jsonTabs: response.data.config.usertabs,
          error: null,
        });
      } else {
        //if config doesn't exist, assume data is an html error message
        this.setState({
          jsonTabs: [],
          error: response.data,
        });
      }
    });
  }

  render() {
    // if we have tabs to render, render them
    if (this.state.jsonTabs.length > 0) {
      return (
        <div>
          <Tabs key="timeclockTabs" jsonTabs={this.state.jsonTabs} globalActions={this.props.GlobalActions} />
        </div>
      );
      // otherwise just show the header while we wait
    } else {
      return <div className="error" dangerouslySetInnerHTML={{ __html: this.state.error }} />;
    }
  }
}
