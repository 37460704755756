import React from 'react';

const Checkbox = props => {
  return (
    <div className={props.wrapperClass}>
      <input
        id={props.name}
        className={props.inputClass}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        placeholder={props.placeholder}
        ref={props.reference}
        maxLength={props.maxLength}
        max={props.max}
        checked={props.value ? true : false}
      />
      <label className={props.labelClass} htmlFor={props.name}>
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
