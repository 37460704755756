import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import APIResponseDialog from '../components/Common/APIResponseDialog';
import qs from 'qs';
import './ResetContainer.scss';
import MuiTextField from '../components/MuiForm/TextField';
import classNames from 'classnames';
import concatURL from '../helpers/concatURL';

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      loading: false,
      dialog: {
        open: false,
        response: null,
        buttonCallback: null,
        redirectTo: null,
      },
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isUsernameValid() {
    return this.state.username.length > 0;
  }

  handleUsernameChange(e) {
    e.preventDefault();
    const username = e.target.value;

    this.setState({
      username,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.isUsernameValid()) {
      this.setState({
        loading: true,
      });

      const params = {
        username: this.state.username,
      };

      axios
        .request({
          url: concatURL('/services/reset'),
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify(params),
        })
        .then(response => {
          this.setState(
            {
              loading: false,
              dialog: {
                open: true,
                response: response,
                redirectTo: response.data.redirect,
              },
            },
            () => {
              this.setState({
                dialog: {
                  open: false,
                  response: response,
                  redirectTo: response.data.redirect,
                },
              });
            },
          );
        })
        .catch(err => {
          this.setState({
            loading: false,
            dialog: {
              open: false,
              response: null,
              redirectTo: null,
            },
          });

          console.error('error', err);
        });
    }
  }

  render() {
    const inactiveClass = this.isUsernameValid() ? '' : ' inactive';

    return (
      <div className="login-container">
        <APIResponseDialog
          open={this.state.dialog.open}
          response={this.state.dialog.response}
          onClose={() => {
            this.state.dialog.redirectTo && this.props.history.push(this.state.dialog.redirectTo);
          }}
          notify={true}
        />
        <div className="form-section">
          <p className="form-title">Reset your password</p>
          <div className="form-body">
            <p className="login-description">
              To reset your password, just enter the username you use to log into netDuty. If the username is
              recognized, and there is an associated email, we will send you a link to reset your password.
            </p>
            <div style={{ textAlign: 'center' }}>
              <MuiTextField
                type="text"
                name="username"
                label="Username"
                outerStyle={{ justifyContent: 'center' }}
                placeholder="Enter your username"
                value={this.state.username}
                onChange={this.handleUsernameChange}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <button className={classNames('login-button', inactiveClass)} onClick={this.handleSubmit}>
                Send
              </button>
              <button className={'login-button'} onClick={() => this.props.history.goBack()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FormContainer);
