import React from 'react';
import { Row, Col, Button, FormGroup, FormLabel } from 'react-bootstrap';
import Select from '../../FormBuilder/Select';
import ShiftTimes from '../../FormBuilder/ShiftTimes';
import { splitShiftByForm } from '../../../actions/API';

export default class Split extends React.Component {
  constructor() {
    super();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.setState({
      type: 'shift',
      start: this.props.data.config.times.default.timein,
      end: this.props.data.config.times.default.timeout,
      duty_id: this.props.data.data.shift_info.duty_id,
      shift_cat: Object.keys(this.props.data.config.edit_shift_tabs.split.shift_cats)[0],
      shift_id: this.props.data.data.shift_info.shift_id,
    });
  }

  render() {
    // console.log('Split rendering with');
    // console.log(this);
    return (
      <Row className="topPaddingMd">
        <Col xs={6}>
          <FormGroup>
            <Col componentClass={FormLabel} xs={3}>
              Shift Times
            </Col>
          </FormGroup>

          <ShiftTimes
            label="Start:"
            name="start"
            times={this.props.data.config.times}
            default={this.props.data.config.times.default.timein}
            handleInputChange={this.handleInputChange}
          />
          <ShiftTimes
            label="End:"
            name="end"
            times={this.props.data.config.times}
            default={this.props.data.config.times.default.timeout}
            handleInputChange={this.handleInputChange}
          />
          <Select
            label="Shift Category"
            name="shift_cat"
            handleInputChange={this.handleInputChange.bind(this)}
            options={this.props.data.config.edit_shift_tabs.split.shift_cats}
          />
          <Select
            label="Duties"
            name="duties"
            handleInputChange={this.handleInputChange.bind(this)}
            options={this.props.data.config.edit_shift_tabs.split.duties}
          />
          <Button onClick={this.submitSplitByForm.bind(this)}>Submit</Button>
        </Col>
      </Row>
    );
  }

  submitSplitByForm(e) {
    e.preventDefault();
    let that = this;
    splitShiftByForm(this.state).then(response => {
      // console.log(response);
      that.props.actions.pushNotification();
      let d = that.props.data.editShiftForm.day.split('-');
      that.props.actions.fetchDay({ year: d[0], month: d[1], day: d[2] });
    });
  }

  handleInputChange(name, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // console.log('v ' + value);
    // console.log('n ' + name);

    this.setState({
      [name]: value,
    });
  }
}
