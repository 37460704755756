import React from 'react';

export default class Hidden extends React.Component {
  constructor() {
    super();
    this.state = {
      value: null,
    };
  }

  render() {
    return <input type="hidden" name="notify_type" value={this.state.value} />;
  }
}
