import React from 'react';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { fetchShiftCreateConfig } from '../../../actions/API';
import Loading from 'react-loading';
import CalloutRequest from './CalloutRequest';
import AssignByStaff from './AssignByStaff';

import 'rc-time-picker/assets/index.css';

export default class ShiftCreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    fetchShiftCreateConfig({ duty_id: this.props.data.duty_id, dutydate: this.props.day })
      .then(response => {
        // console.log('fetchShiftCreateConfig response:', response.data);
        this.setState({
          config: response.data.config,
          data: response.data.data,
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loaded: true,
          err: true,
          err_msg: err,
          config: null,
        });
      });
  }

  render() {
    // console.log("ShiftCreateModal rendering with");
    // console.log('Props:');
    // console.log(this.props);
    // console.log('State:');
    // console.log(this.state);
    if (this.state && this.state.config && this.state.data) {
      const defaultTab = this.defaultTab();

      return (
        <div>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.config.create_shift_label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey={defaultTab} id="shiftCreateModal">
              <Tab eventKey={1} title="Assign By Staff">
                <AssignByStaff data={this.state.data} config={this.state.config} actions={this.props.actions} />
              </Tab>

              <Tab eventKey={2} title="Callout Request">
                <CalloutRequest dutyID={this.props.data.duty_id} day={this.props.day} />
              </Tab>
            </Tabs>
          </Modal.Body>
        </div>
      );
    } else {
      return (
        <div className="hCenter">
          <Loading type="bars" color="#e3e3e3" />
        </div>
      );
    }
  }

  defaultTab() {
    if (this.state && this.state.config && this.state.config.default_view) {
      switch (this.state.config.default_view) {
        case 'assign_staff':
          return 1;
        case 'callout':
          return 2;
        default:
          return 1;
      }
    } else {
      return 1;
    }
  }
}
