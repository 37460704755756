import React from 'react';
import Loading from 'react-loading';
import { get } from '../../actions/REST';
import { dynamicOEButtonCall } from '../../actions/API';
import Records from '../Common/Records';
import DialogBox from '../Common/DialogBox';
import Buttonbar from '../Common/Buttonbar';
import APIResponseDialog from '../Common/APIResponseDialog';

const buttonTypes = require('../../constants/buttonTypes.js');

export default class DayContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog_box: {
        data: null,
        show: false,
        uniqueID: '',
      },
      dialog: {
        open: false,
        response: null,
      },
      loading: false,
      temp_duty_ids: [],
      temp_bid_ids: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      //Reset the Temporary States
      this.setState({
        temp_duty_ids: [],
        temp_bid_ids: [],
      });
    }
  }

  openAPIDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState({ dialog: { open: true, response: response } }, () => {
      setTimeout(() => {
        this?.setState({ dialog: { open: false, response: response } });
      }, 3000);
    });
  }

  //Close the box and delete the data from the state
  closeDialogBox() {
    this.setState({
      dialog_box: {
        data: null,
        show: false,
      },
    });
  }

  openDialogBox(response, dialog_type, uniqueID, mousePos) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: mousePos ? Object.assign({}, response.data, { mousePos }) : response.data,
        show: true,
        dialog_type,
      },
    });
  }

  getDialogBoxData(button, uniqueID, mousePos) {
    this.setState({ loading: true });
    if (!button.endpoint) {
      //inline dialogBox
      if (button.data) {
        this.openDialogBox(button, button.dialog_type, uniqueID, mousePos);
      } else {
        console.warn('Tried to open an Inline DialogBox (no endpoint) without a data property.');
        this.setState({ loading: false });
      }
      return null;
    }
    get(button.variables, `/${button.endpoint}`, 'Fetching data for DialogBox')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response, button.dialog_type, uniqueID, mousePos);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.openAPIDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.openAPIDialog(response));
        }
      })
      .catch(err => this.setState({ loading: false }, () => this.openAPIDialog(err)));
  }

  handleButton(button, uniqueID, mousePos) {
    if (button.action === buttonTypes.DIALOG_BOX) {
      this.getDialogBoxData(button, uniqueID, mousePos);
    } else if (button.endpoint && button.method) {
      const variables = button.variables || {};
      dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables).then(response => {
        if (response.status >= 200 && response.status < 300) {
          let temp_duty_id = null;
          let temp_bid_id = null;
          if (button.variables.duty_id) {
            temp_duty_id = 'duty.' + button.variables.duty_id;
          } else if (button.variables.bid_id) {
            temp_bid_id = button.variables.bid_id;
          }
          if (temp_duty_id) {
            //update the temp_duty_ids to add visual temporary state to duties
            this.setState(prevState => ({
              temp_duty_ids: prevState.temp_duty_ids.concat(temp_duty_id),
            }));
          } else if (temp_bid_id) {
            //update the temp_bid_ids to add visual temporary state to bids
            this.setState(prevState => ({
              temp_bid_ids: prevState.temp_bid_ids.concat('' + temp_bid_id),
            }));
          }
          this.props.onSuccess();
        }
        this.openAPIDialog(response);
      });
    } else {
      console.warn('endpoint and/or method not found');
    }
  }

  /*
   ** Returns an array of <li> elements containing the data to display,
   ** some may include buttons.
   **
   ** @param {Object} record
   */
  getItems(record) {
    let content = [];
    if (record.details && record.details.length > 0) {
      content.push(
        record.details.map(detail => (
          <li key={detail.label} className={detail.class}>
            - {detail.label}
          </li>
        )),
      );
    }
    if (record.containers && record.containers.length > 0) {
      let localContainers = [];
      //this black list is passed from Day View, where the Filters are handled.
      if (this.props.dutys_black_list && this.props.dutys_black_list.length > 0) {
        localContainers = record.containers.filter(duty => !this.props.dutys_black_list.includes(duty.duty_id));
      } else {
        localContainers = record.containers;
      }
      content.push(
        localContainers.map(detail => {
          /* Because we're debouncing the Calendar mDay View Refresh method, we need a way
           ** to let the user know that a POST has been made, for this we check if we have an id
           ** on any of the arrays (temp_bid_ids and temp_duty_ids) to see if they need the temporary
           ** state.
           **
           ** This temporary state will automatically be reset once the Component is Updated by the
           ** Parent Component (check ComponentDidUpdate).*/
          let extraClass = '';
          if (detail.id && this.state.temp_duty_ids.length > 0 && this.state.temp_duty_ids.includes(detail.id)) {
            extraClass = 'temporary_state';
          }
          const records = detail.shifts || detail.records;
          if (records && records.length > 0) {
            for (let record of records) {
              if (this.state.temp_bid_ids.length > 0 && this.state.temp_bid_ids.includes(record.id)) {
                extraClass = 'temporary_state';
              }
            }
          }
          return (
            <>
              {this.props.columnGetFrom !== 'DashboardCoulmn' ? (
                <li key={'duty' + detail.label + detail.duty_id + detail.id} className={extraClass}>
                  <ul className="detail-list ">
                    <div className="header">
                      {detail.lbuttonbar && (
                        <Buttonbar
                          key="left_buttonbar"
                          uniqueID={detail.label + detail.duty_id}
                          buttonbar={detail.lbuttonbar}
                          handleButton={this.handleButton.bind(this)}
                        />
                      )}
                      <abbr className="detail-label" title={detail.duty_hovertext}>
                        <span className={detail.class}>{detail.label}</span>
                      </abbr>
                      {detail.buttonbar && (
                        <Buttonbar
                          key="right_buttonbar"
                          uniqueID={detail.label + detail.duty_id}
                          buttonbar={detail.buttonbar}
                          handleButton={this.handleButton.bind(this)}
                        />
                      )}
                    </div>
                    {(detail.shifts || detail.records) && ( //Handle DutyRoster (shifts) and Records (records)
                      <div key="records" className="shift-wrapper">
                        <Records
                          titleStyle={{ fontWeight: 'normal' }}
                          records={detail.shifts || detail.records}
                          handleButton={this.handleButton.bind(this)}
                          columnGetFrom={this.props.columnGetFrom}
                        />
                      </div>
                    )}
                  </ul>
                </li>
              ) : (
                <div>
                  <p className={`list-content-label`}>
                    <span className={detail.class}>{detail.label}</span>
                  </p>
                  <div key={'duty' + detail.label + detail.duty_id + detail.id} className={extraClass}>
                    {(detail.shifts || detail.records) && ( //Handle DutyRoster (shifts) and Records (records)
                      <Records
                        titleStyle={{ fontWeight: 'normal' }}
                        records={detail.shifts || detail.records}
                        handleButton={this.handleButton.bind(this)}
                        columnGetFrom={this.props.columnGetFrom}
                      />
                    )}
                    {detail.lbuttonbar && (
                      <>
                        <Buttonbar
                          key="left_buttonbar"
                          uniqueID={detail.label + detail.duty_id}
                          buttonbar={detail.lbuttonbar}
                          handleButton={this.handleButton.bind(this)}
                        />
                      </>
                    )}

                    {detail.buttonbar && (
                      <Buttonbar
                        key="right_buttonbar"
                        uniqueID={detail.label + detail.duty_id}
                        buttonbar={detail.buttonbar}
                        handleButton={this.handleButton.bind(this)}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          );
        }),
      );
    }
    return content.length > 0 ? content : null;
  }

  render() {
    const { record } = this.props;
    return (
      <div>
        {this.state.dialog_box.show && this.state.dialog_box.data && (
          <DialogBox
            key={'dialog_box' + this.state.dialog_box.uniqueID}
            data={this.state.dialog_box.data}
            dialog_type={this.state.dialog_box.dialog_type}
            initialPosition={this.props.dialogInitialPos}
            openDialog={this.openAPIDialog.bind(this)}
            onClose={this.closeDialogBox.bind(this)}
            onSuccess={this.props.onSuccess}
          />
        )}
        {this.state.loading && (
          <div style={{ position: 'absolute', left: 0, top: 0 }}>
            <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
          </div>
        )}
        {/* <div className="toggle-day-content">
          <span className={`title ${record.class}`}>{record.label}</span>
        </div> */}

        <div className="toggle-day-content">
          <div className={`title day-content-header-label`}>{record.label}</div>
        </div>

        <div className="detail-list-wrapper">{this.getItems(record)}</div>

        <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
      </div>
    );
  }
}
