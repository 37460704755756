import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/GlobalActions';
import * as reportsActions from '../actions/ReportsActions';
import Reports from '../components/Reports/Reports';

export const ReportsPage = props => {
  return (
    <Reports
      GlobalActions={props.actions}
      reportsActions={props.reportsActions}
      auth={props.auth}
      notifications={props.notifications}
      reports={props.reports}
    />
  );
};

function mapStateToProps(state) {
  return {
    auth: state.APIReducer.auth,
    notifications: state.APIReducer.data.notifications,
    reports: state.ReportsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    reportsActions: bindActionCreators(reportsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
