import React from 'react';
import { Link, Route } from 'react-router-dom';
import Router from './Router';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Ribbon from '../Common/Ribbon';
import Buttonbar from '../Common/Buttonbar';

export default class TabNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };

    this.getContent = this.getContent.bind(this);
    this.doesRouteExist = this.doesRouteExist.bind(this);
  }

  componentDidMount() {
    const { tabData } = this.props;

    if (Array.isArray(tabData) && tabData.length > 0) {
      const match = this.doesRouteExist(tabData);
      const searchParams = new URLSearchParams(window.location.search);
      const user_n = searchParams.get('user_n');
      const param = user_n ? `?user_n=${user_n }` : '';


      if (!match) {
        const value = `/${tabData[0].route}${param}`;
        this.setState(
          {
            value,
          },
          () => this.props.history.push(value),
        );
      } else {
        this.setState({
          value: match+param,
        });
      }
    }
  }

  doesRouteExist(tabs) {
    let match = false;
    for (let tab of tabs) {
      if (tab.topmenu && `/${tab.route}` !== this.props.location.pathname) {
        if (this.doesRouteExist(tab.topmenu)) {
          return `/${tab.route}`;
        }
      } else if (`/${tab.route}` === this.props.location.pathname) {
        match = `/${tab.route}`;
        break;
      }
    }

    return match;
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handlerTabClick = route => ev => {
    if (window.location.pathname.includes(route)) {
      // trigger loadJsonContent in Router
      this.props.globalActions.reloadJsonContent();
    }
  };

  getContent(tab) {
    if (tab.buttonbar || tab.lbuttonbar) {
      return (
        <Buttonbar
          buttonbar={tab.buttonbar || tab.lbuttonbar}
          handleButtonbar={() => console.log('handle Buttonbar - not yet implemented')}
        />
      );
    } else if (tab.ribbon) {
      // CONTENT WITH RIBBONS
      return <Ribbon data={tab.ribbon} globalActions={this.props.globalActions} history={this.props.history}/>;
    } else if (tab.topmenu) {
      return (
        <TabNavigation
          globalActions={this.props.globalActions}
          tabData={tab.topmenu}
          location={this.props.location}
          history={this.props.history}
        />
      );
    } else {
      return (
	      <Router 
            content={tab} 
            tabs={this.props.tabData} 
            globalActions={this.props.globalActions} 
            history={this.props.history}
        />
      );
    }
  }

  render() {
    if (!this.state.value) {
      return <div />;
    }
    const div_sx = process.env.REACT_APP_THEME === "epr" ? {marginBottom: '2rem'} : "";
    const tab_sx = process.env.REACT_APP_THEME === "epr" ? {} : {marginLeft: '10px', marginTop: '10px',};
    const tab_class = process.env.REACT_APP_THEME === "epr" ? "" : "netduty-tabs";
    const tab_indicator = process.env.REACT_APP_THEME === "epr" ? {} : {style: { display: 'none' }};

    return (
      <>
        <div sx={div_sx}>
          <Tabs
            value={this.state.value}
            sx={tab_sx}
            TabIndicatorProps={tab_indicator}
            onChange={this.handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {this.props.tabData.map((tab, index) => {
              return (
                <Tab
                  className={tab_class}
                  sx={{
                    outline: 'none !important',
                    textDecoration: 'none !important',
                    p: '12px 16px !important',
                  }}
                  key={tab.id + tab.label + index}
                  label={tab.label}
                  component={Link}
                  to={`/${tab.route}`}
                  value={`/${tab.route}`}
                  onClick={this.handlerTabClick(tab.route)}
                />
              );
            })}
          </Tabs>
        </div>
        {this.props.tabData.map((tab, index) => (
          <Route key={tab.label + index} path={`/${tab.route}`}>
            {this.getContent(tab)}
          </Route>
        ))}
      </>
    );
  }
}
