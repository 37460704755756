import React from 'react';
import TimePicker from 'rc-time-picker';
import CustomToolTip from './CustomToolTip';
import Buttonbar from './Buttonbar';

// a wrapper class for react-datetime's Datetime component which adds max and min dateTimes
export default class WrappedTimePicker extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidMount(){
    if(this.props.value){
      this.updateValue(this.props.value);
    }
  }


  //update the value and the timeconstraints accordingly in the local state
  updateValue(newValue){
    if(newValue) {
      if (newValue === this.state.value) {
        return;
      }
      this.props.onChange(newValue.format("HH:mm"));
    } else {
      this.props.onChange(null);
    }

    this.setState({value: newValue});
  }


  render(){
    return(
      <div>
        {this.props.label && (
            <abbr key={'abbr' + new Date().getTime() } title={this.props.hovertext}>
            <label key={'label' + new Date().getTime() }style={{marginRight:'5px'}}className="inputLabel" htmlFor={this.props.name}>
            {this.props.label}
            </label>
            {this.props.icon && <CustomToolTip icon={this.props.icon} iconClass={this.props.iconClass} /> }
            </abbr>
        )}
        {this.props.buttonbar && (
            <Buttonbar
            buttonbar={this.props.buttonbar}
            loading={this.state.loading}
            handleButton={this.props.handleButton}
            />
        )}
        <TimePicker
	    key={"clock" + this.props.name}
	    name={this.props.name}
	    use12Hours={this.props.format == 12}
	    value={this.state.value}
	    onChange={this.updateValue.bind(this)}
	    minuteStep={this.props.minuteStep}
	    hourStep={this.props.hourStep}
	    showSecond={this.props.showSecond}
        />
        </div>
    );
  }
}

