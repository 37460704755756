import React from 'react';
import Buttonbar from './Buttonbar';
import sortBy from 'sort-by';
import classNames from 'classnames';

//compares two raw tree nodes to see if they match
//TODO: find a better place for this
function compareJsonNodes(node1, node2) {
  return node1.label === node2.label && node1.id === node2.id;
}

//a helper component. a recursive single node of the tree which spawns its own children
class TreeNode extends React.Component {
  constructor(props) {
    super(props);

    //open: whether or not this node is open and displaying its children
    this.state = {
      open: !!this.props.node.open,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  //TODO: make this more efficient
  componentDidUpdate(prevProps) {
    if (this.props.selected !== null) {
      //if the selected prop has changed, and we aren't already aligned with it, toggle this node
      if (this.props.selected !== prevProps.selected && this.props.selected !== this.isSelected()) {
        this.props.clickCallBack(this.props.node);
      }
    }
  }

  //opens or closes this node
  toggleOpen() {
    const { id } = this.props.node;

    this.setState(
      prevState => ({
        open: !prevState.open,
      }),
      () => {
        if (this.props.addToTreeOpenNodes || this.props.removeToTreeOpenNodes) {
          this.state.open ? this.props.addToTreeOpenNodes(id) : this.props.removeToTreeOpenNodes(id);
        }
      },
    );
  }

  //uses the callback to select or deselect the node
  toggleSelected(disabled) {
    /* This allows the back-end to block a specific
     ** node just by sending {selectable: false}. */
    if (
      disabled ||
      (this.props.node && Object.keys(this.props.node).includes('selectable') && !this.props.node.selectable)
    ) {
      return null;
    }
    if (this.isSelectable()) {
      this.props.clickCallBack(this.props.node);
    }
  }

  toggleSelectedLocally() {
    //if this is not selectable, do nothing. Otherwise, toggle selected
    if (this.isSelectable()) {
      this.props.setSelectedLocally(this.props.node);
    }
  }

  //recursively creates the child TreeNode objects for this TreeNode
  getChildren() {
    //if this node has an 'objects' property or a 'children' property, it has children. create them
    //TODO: make properties consistent, reduce this to one single check
    let rawChildren = this.props.node.children ? this.props.node.children : this.props.node.objects;
    if (rawChildren) {
      rawChildren.sort(sortBy('order', 'label'));
      let isSelected = this.isSelected();
      let children = [];
      for (let i = 0; i < rawChildren.length; i++) {
        let rawChild = rawChildren[i];
        children.push(
          <TreeNode
            node={rawChild}
            key={rawChild.label + rawChild.id}
            selected={isSelected}
            addToTreeOpenNodes={this.props.addToTreeOpenNodes}
            removeToTreeOpenNodes={this.props.removeToTreeOpenNodes}
            clickCallBack={this.props.clickCallBack.bind(this)}
            setSelectedLocally={this.props.setSelectedLocally.bind(this)}
            selectedNodes={this.props.selectedNodes}
            simple={this.props.simple}
            buttonBar={rawChild.buttonbar}
            handleButton={this.props.handleButton}
            pushNodes={this.props.pushNodes}
          />,
        );
      }
      return children;
      //otherwise there are no children. return null
    } else {
      return null;
    }
  }

  isSelectable() {
    return !(this.props.simple && (this.props.node.children || this.props.node.objects));
  }

  //returns whether or not this node is currently selected
  isSelected() {
    //TODO: make this more efficient. Perhaps add unique indices to node objects and
    // keep an array of their selected state rather than a bunch of whole objects
    let inSelected = this.props.selectedNodes.find(ele => compareJsonNodes(ele, this.props.node));

    //convert inSelected to a boolean
    return !!inSelected;
  }

  handleButton(button) {
    this.toggleSelectedLocally();
    if (this.props.handleButton) {
      this.props.handleButton(button);
    }
  }

  //If the user double clicks we simply toggle the element again so it stays selected
  //(because onClick will trigger twice as well, causing the node to be selected and then unselected real quick)
  //so doing it again here in the handleDoubleClick function guarantee that it's going to stay selected.
  //and then we push the Nodes to onDeck. For more info DEV-477
  handleDoubleClick(disabled) {
    if (disabled) {
      return null;
    }
    this.toggleSelected(disabled);
    this.props.pushNodes();
  }

  render() {
    let inSelected = this.isSelected();

    const selectedClass = inSelected ? ' selected' : '';

    let expandableClass = this.props.node.children || this.props.node.objects ? ' expandable' : ' leaf';

    //create an expand button only for expandable nodes. nodes are expandable if they have a child array
    let expandClass = this.state.open ? 'glyphicon-menu-down' : 'glyphicon-menu-right';
    let expandButton =
      this.props.node.children || this.props.node.objects ? (
        <span onClick={this.toggleOpen} className={classNames('expandButton glyphicon', expandClass)} />
      ) : null;

    // keep the children mounted to maintain selected state, but only display them if the node is open
    let childrenVisibleClass = this.state.open ? '' : ' hidden';

    let label = this.props.node.label ? this.props.node.label : this.props.node.id;
    const { disabled } = this.props.node;
    let selectableClass = this.isSelectable() || disabled ? ' selectable' : '';

    return (
      <div className={classNames('treeNodeContainer', expandableClass)}>
        <div className="editableObject">
          {expandButton}
          <div className="buttonContainer">
            <Buttonbar buttonbar={this.props.buttonBar} handleButton={this.handleButton.bind(this)} />
          </div>
          <span
            onClick={this.toggleSelected.bind(this, disabled)}
            onDoubleClick={this.handleDoubleClick.bind(this, disabled)}
            className={'objectLabel' + selectedClass + selectableClass + expandableClass}
          >
            {label}
          </span>
        </div>
        <div className={classNames('branchContainer', childrenVisibleClass)}>{this.getChildren()}</div>
      </div>
    );
  }
}

export default class ObjectTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locallySelectedNodes: this.props.selectedNodes,
    };

    this.pushNodes = this.pushNodes.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps !== this.props &&
      nextProps.selectedNodes &&
      nextProps.selectedNodes.length === 0 &&
      this.props.treeOpenNodes &&
      nextProps.treeOpenNodes &&
      this.props.treeOpenNodes.length === nextProps.treeOpenNodes.length
    ) {
      this.setState({
        locallySelectedNodes: [],
      });
    }
  }

  // sets the selected node internally without calling the callback
  setSelectedLocally(node) {
    //if it's simple, only select one at a time, otherwise let the array grow
    let newNodes = [node];
    if (!this.props.simple) {
      newNodes = this.state.locallySelectedNodes;

      //the index of the current element, if already present in the array, otherwise -1
      let nodeIndex = newNodes.findIndex(function (ele) {
        return compareJsonNodes(ele, node);
      });

      //if the given node is not in the selected node array yet, add it
      // if it's already there, remove it
      if (nodeIndex > -1) {
        newNodes.splice(nodeIndex, 1);
      } else {
        newNodes.push(node);
      }
    }

    this.setState({
      locallySelectedNodes: newNodes,
    });

    return newNodes;
  }

  //pushes the newly selected node onto the array of selected nodes
  setSelected(node) {
    let newNodes = this.setSelectedLocally(node);
    //if this is a simple editor, go ahead and open each node as it is selected
    if (this.props.simple) {
      this.props.setSelectedCallback(newNodes);
    }
  }

  //use the provided callback function to push the currently selected nodes to the object editor
  pushNodes() {
    //only push object if it is a leaf node. it's a leaf node if it has no children
    let selectedLeafNodes = this.state.locallySelectedNodes.filter(node => !(node.children || node.objects));
    this.props.setSelectedCallback(selectedLeafNodes);
  }

  //returns a tree of UI elements each representing an editable object
  getObjects() {
    //the tree at its base is an array of base nodes. iterate over it to initialize the base tree nodes
    let baseNodes = [];
    let tree = this.props.tree || [];
    for (let i = 0; i < tree.length; i++) {
      let node = tree[i];
      baseNodes.push(
        <TreeNode
          node={node}
          key={node.label + node.id + i}
          clickCallBack={this.setSelected.bind(this)}
          setSelectedLocally={this.setSelectedLocally.bind(this)}
          selectedNodes={this.state.locallySelectedNodes}
          simple={this.props.simple}
          addToTreeOpenNodes={this.props.addToTreeOpenNodes}
          removeToTreeOpenNodes={this.props.removeToTreeOpenNodes}
          buttonBar={node.buttonbar}
          handleButton={this.props.handleButton}
          pushNodes={this.pushNodes}
        />,
      );
    }
    return baseNodes;
  }

  render() {
    let pushButton = this.props.simple ? null : (
      <div className="pushButton glyphicon glyphicon-forward" onClick={this.pushNodes} />
    );
    return (
      <div className="objectTree">
        {pushButton}
        <div className="treeContainer">{this.getObjects()}</div>
      </div>
    );
  }
}
