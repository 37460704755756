export const ADD = 'add'; //Bootstrap
export const ALERT = 'alert';
export const APPARATUS = 'apparatus';
export const APPROVE = 'approve';
export const BID = 'bid';
export const CALLOUT = 'callout';
export const CANCEL = 'cancel';
export const CLOCKIN = 'clockin';
export const CLOCKOUT = 'clockout';
export const CREATE = 'create';
export const CROSSSTATION = 'cross_station';
export const DAY = 'day';
export const DELETE = 'delete';
export const DENY = 'deny';
export const EDIT = 'edit'; //Bootstrap
export const ENVELOPE = 'envelope'; //Bootstrap
export const ENVELOPE_OPEN = 'envelope_open';
export const EVENT = 'event';
export const EXCHANGE = 'exchange';
export const FULL = 'full';
export const HELP = 'help'; //Bootstrap
export const INFO = 'info'; //Bootstrap
export const MONTH = 'month';
export const OFFER = 'offer';
export const OFFER2 = 'offer2';
export const ONDUTY = 'onduty';
export const ONDUTYPOLICE = 'onduty_police';
export const ONSHIFT_BADGE = 'onshift_badge';
export const OVERTIME = 'overtime';
export const PARTIAL = 'partial';
export const PAYPERIOD = 'payperiod';
export const PLUS = 'plus'; //Bootstrap
export const PTO = 'pto';
export const REPORTS = 'reports';
export const STATION = 'station';
export const SETUP = 'setup'; //Bootstrap
export const SCHEDULED_DUTY = 'scheduled_duty';
export const SHIFT = 'shift';
export const SHIFTBOARD = 'shiftboard';
export const SIGNUP = 'signup';
export const SIGNUP_EVENT = 'signup_event';
export const SUB = 'sub';
export const TIME_CHANGE = 'time_change';
export const TIMECLOCK_RECORD = 'timeclock_record';
export const TIMEOFF = 'timeoff';
export const VIEW = 'view'; //Bootstrap
export const WEEK = 'week';

//Bootstrap Icons//
export const ASTERISK = 'asterisk';
export const BULLHORN = 'bullhorn';
export const CALENDAR = 'calendar';
export const COMMENT = 'comment';
export const CERTIFICATE = 'certificate';
export const CLOUD = 'cloud';
export const COG = 'cog';
export const DASHBOARD = 'dashboard';
export const DOWNLOAD = 'download';
export const DOWNLOAD_ALT = 'download-alt';
export const CLOUD_DOWNLOAD = 'cloud-download';
export const EYE_OPEN = 'eye-open';
export const FILE = 'file';
export const FIRE = 'fire';
export const FILTER = 'filter';
export const FLASH = 'flash';
export const FLAG = 'flag';
export const FOLDER_CLOSE = 'folder-close';
export const FOLDER_OPEN = 'folder-open';
export const HAND_UP = 'hand-up';
export const HAND_DOWN = 'hand-down';
export const HAND_LEFT = 'hand-left';
export const HAND_RIGHT = 'hand-right';
export const HOURGLASS = 'hourglass';
export const INBOX = 'inbox';
export const LIST_ALT = 'list-alt';
export const LOCK = 'lock';
export const MINUS = 'minus';
export const MENU = 'menu';
export const MENU_LEFT = 'menu-left';
export const MENU_RIGHT = 'menu-right';
export const MENU_DOWN = 'menu-down';
export const MENU_UP = 'menu-up';
export const OK = 'ok';
export const PUSHPIN = 'pushpin';
export const REFRESH = 'refresh';
export const RANDOM = 'random';
export const SAVE = 'save';
export const SCALE = 'scale';
export const STAR = 'star';
export const STAR_EMPTY = 'star-empty';
export const SEARCH = 'search';
export const TIME = 'time';
export const TRASH = 'trash';
export const THUMBS_UP = 'thumbs-up';
export const THUMBS_DOWN = 'thumbs-down';
export const TAGS = 'tags';
export const TASKS = 'tasks';
export const USER = 'user';
export const WARNING_SIGN = 'warning-sign';
export const ZOOM_IN = 'zoom-in';
export const ZOOM_OUT = 'zoom-out';
