import React from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import qs from 'qs';
import APIResponseDialog from '../components/Common/APIResponseDialog';
import classNames from 'classnames';
import concatURL from '../helpers/concatURL';

class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      dialog: {
        open: false,
        response: null,
        redirectTo: null,
      },
    };

    this.token = null;
    this.username = null;
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
  }

  componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get('t');
    const username = url.searchParams.get('username');

    this.token = token;
    this.username = username;
  }

  passwordMatch() {
    const { password, confirmPassword } = this.state;

    if (
      password &&
      password.trim().length > 0 &&
      confirmPassword.trim().length > 0 &&
      confirmPassword &&
      password === confirmPassword
    ) {
      return true;
    }

    return false;
  }

  handlePasswordChange(e) {
    const password = e.target.value;

    this.setState({
      password,
    });
  }

  handleConfirmPasswordChange(e) {
    const confirmPassword = e.target.value;

    this.setState({
      confirmPassword,
    });
  }

  handleResetPassword(e) {
    e.preventDefault();

    if (this.passwordMatch() && this.token && this.username) {
      this.setState({
        loading: true,
      });

      const params = {
        token: this.token,
        username: this.username,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      };

      axios
        .request({
          url: concatURL('/auth/reset'),
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify(params),
        })
        .then(response => {
          this.setState(
            {
              loading: false,
              dialog: {
                open: true,
                response: response,
                redirectTo: response.data.redirect,
              },
            },
            () => {
              this.setState({
                dialog: {
                  open: false,
                  response: response,
                  redirectTo: response.data.redirect,
                },
              });
            },
          );
        })
        .catch(err => {
          this.setState({
            loading: false,
          });
          console.error('error', err);
        });
    }
  }

  render() {
    const inactiveClass = this.passwordMatch() ? '' : ' inactive';

    return (
      <div className="login-container">
        <APIResponseDialog
          open={this.state.dialog.open}
          response={this.state.dialog.response}
          onClose={() => {
            this.state.dialog.redirectTo && this.props.history.push(this.state.dialog.redirectTo);
          }}
          notify={true}
        />
        <div className="form-section" style={{ height: '300px' }}>
          <p className="form-title">Enter your new password</p>
          <div className="form-body">
            <input
              type="password"
              name="password"
              className="input-form"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              autoFocus={isMobile ? false : true}
              autoComplete="off"
            />
            {!this.passwordMatch() && this.state.confirmPassword ? (
              <span
                style={{
                  width: '300px',
                  alignSelf: 'center',
                  marginTop: '10px',
                  color: '#ff3333',
                  fontWeight: 'bold',
                }}
              >
                <span
                  className="glyphicon glyphicon-info-sign"
                  style={{
                    marginRight: '5px',
                    color: '#FF0000',
                  }}
                />
                Passwords must match.
              </span>
            ) : null}
            <input
              type="password"
              name="confirmPassword"
              className="input-form"
              style={!this.passwordMatch() && this.state.confirmPassword ? { marginTop: '10px' } : {}}
              placeholder="Confirm password"
              value={this.state.confirmPassword}
              onChange={this.handleConfirmPasswordChange}
              autoComplete="off"
            />
            <button className={classNames('login-button', inactiveClass)} onClick={this.handleResetPassword}>
              Reset Password
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPasswordContainer);
