import React from 'react';
import Form from '../../Common/Form';
import { dynamicOEButtonCall } from '../../../actions/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class APIMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: null,
      records: null,
      buttonbar: null,
    };
  }

  componentDidMount() {
    this.updateState(this.props.data.data.config);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateState(nextProps.data.data.config);
  }

  updateState({ buttonbar, records }) {
    this.setState({
      selectedButton: buttonbar ? buttonbar[0].label : null,
      buttonbar,
      records,
    });
  }

  /* Handles the click on the buttons that will update the state with
  the selected button, this will cause a re-render and the right form will
  be rendered.
  - selected (string)
  */
  handleSelectedButton(selected) {
    //Only change the state if clicking a different button.
    selected !== this.state.selectedButton && this.setState({ selectedButton: selected });
  }

  /* Extracting the buttons from the json, creating an Array of buttons
  that have an onClick event to change the state of the selectedButton.
  - buttonbar (array)
  */
  getToggleButtons = buttonbar => {
    return buttonbar
      .filter(button => button.form)
      .map(button => (
        <li
          key={button.label}
          onClick={() => this.handleSelectedButton(button.label)}
          className={
            this.state.selectedButton === button.label ? 'selected toggle-buttons-item' : 'toggle-buttons-item'
          }
        >
          <span>{button.label}</span>
        </li>
      ));
  };

  /* Handling the result of the API after submiting the form */
  handleResult = data => {
    if (data) {
      data.statusText === 'OK' && this.props.refreshState();
    } else {
      console.error('No response received!');
    }
  };

  /* We map through each button and filter the one that matches the selected button,
  using the Form component we create the selected form.
  - buttonbar (array)
  */
  getForms(buttonbar) {
    return buttonbar
      .filter(button => button.label === this.state.selectedButton)
      .map(button => (
        <div className="form-apimap" key={button.label}>
          <Form formData={button.form} standAlone={false} success={this.handleResult} />
        </div>
      ));
  }

  /* - records (object) */
  getAllRecords(records) {
    //verify if there's any records
    if (records && records.length === 0) {
      return <div className="no-records">No records found!</div>;
    }

    /* TEST - FEEL FREE TO DELETE THIS
    ** Uncomment this to test multiple group of records using the same
    ** records under apparatus.
        records['Testing'] = records['apparatus'];
        records['Testing2'] = records['apparatus'];
        records['Testing3'] = records['apparatus'];
        records['Testing4'] = records['apparatus'];
    */

    //extracting the groups name of record (apparatus, position)
    const record_group_list = Object.keys(records);

    return record_group_list.map(group_name => {
      //For each group_name extract the record type (roster, duty...)
      const records_type_list = Object.keys(records[group_name]);
      return (
        <div className="record-group-box" key={group_name}>
          <div className="cardHeader">{group_name}</div>
          {records_type_list.map(record_name => (
            <div className="cardBody" key={record_name}>
              <span className="group-record-title">{record_name}</span>
              <ul className="record-group-list">{this.getRecordByType(records[group_name][record_name])}</ul>
            </div>
          ))}
        </div>
      );
    });
  }

  /* Goes through a list of records and returns a list element that includes
  the record label and the button next to it.
  - records (array)
  */
  getRecordByType(records) {
    return records.map(record => (
      <li className="record-group-item" key={record.apimap_id}>
        {this.getButtonsOfRecord(record)}
        {record.label}
      </li>
    ));
  }

  getButtonsOfRecord(record) {
    return record.buttons.map(button => {
      if (button.method) {
        switch (button.method) {
          case 'DELETE':
            return (
              <FontAwesomeIcon
                key={`close_${button.apimap_id}`}
                icon="times"
                className="record-button"
                style={{ color: '#b71c1c' }}
                onClick={() => this.handleRecordButton(record)}
              />
            );
          default:
            return (
              <span
                key={`close_${button.apimap_id}`}
                className="record-button"
                style={{ color: '#b71c1c' }}
                onClick={() => this.handleRecordButton(record)}
              >
                {button.label}
              </span>
            );
        }
      }
      return <span />;
    });
  }

  /* When a button is clicked we use dynamicOEButtonCall to send the
  necessary data to the API. Making sure we receive an OK status before
  refreshing the state and cause a re-render. */
  handleRecordButton(record_clicked) {
    const button = record_clicked.buttons[0]; //Assuming only 1 button
    let variables = button.method === 'DELETE' && button.variables;
    dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
      response.data.code === 200 && this.props.refreshState();
      console.log(button.label + ' success. Response: ');
      console.log(response);
    });
  }

  render() {
    const { selectedButton, records, buttonbar } = this.state;

    return (
      <div className="APIMap">
        {selectedButton && (
          <div className="content-wrapper">
            <div className="left-content contentCard">
              <div className="cardHeader">Select Form</div>
              <div className="cardBody">
                <ul className="toggle-buttons-group">{this.getToggleButtons(buttonbar)}</ul>
              </div>
            </div>
            <div className="right-content">
              <div className="contentCard">
                <div className="cardHeader">Forms</div>
                <div className="cardBody" style={{ overflow: 'auto' }}>
                  {this.getForms(buttonbar)}
                </div>
              </div>
              <div className="records contentCard">
                <div className="cardHeader">Records</div>
                <div className="record-wrapper cardBody">
                  {records ? this.getAllRecords(records) : <div className="no-records">No records found!</div>}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default APIMap;
