import React from 'react';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Box } from '@mui/material';

const backgroundBlue = {
  backgroundColor: '#ccf2ff',
};

export default class Drag extends React.Component {
  //Wraps components in a popup draggable window

  render() {
    const { help } = this.props;
    //TODO consider making this a <Panel/> header if we want to style it as such
    let header = this.props.headerText ? <span className="header">{this.props.headerText}</span> : null;
    return (
      <Draggable cancel=".formDialog" defaultPosition={isMobile ? { x: 43, y: 0 } : { x: 0, y: 0 }}>
        <div className="dragPopup">
          <Box className={classNames(this.props.className, 'dragContainer')} style={help ? backgroundBlue : {}}>
            {header}
            <FontAwesomeIcon icon="times-circle" size="lg" className="closeButton" onClick={this.props.close} />
            <Box className="dragContent" sx={{ display: 'flex', flexDirection: 'column' }}>
              {this.props.children}
            </Box>
          </Box>
        </div>
      </Draggable>
    );
  }
}
