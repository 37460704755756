import React from 'react';
import Records from '../Records/Records';
import { getReport, dynamicOEButtonCall } from '../../actions/API';
import Buttonbar from '../Common/Buttonbar';

const buttonTypes = require('../../constants/buttonTypes.js');

export default class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData: null,
      recordsData: null,
      menuData: null,
    };
  }
  setData(data, menu, records) {
    this.setState({
      resultData: data ? data : null,
      recordsData: records,
      menuData: menu,
    });
  }

  //when the component mounts, pull the correct report
  componentDidMount() {
    getReport({ report_id: this.props.report.id }).then(response => {
      if (response.status === 200) {

        let menu, data, records;
        if (response && response.data && response.data.menu) {
          menu = response.data.menu;
        }
        data = response.data.data !== '' ? response.data.data : null;
        records = response.data.config &&  response.data.config.records ? response.data : null;
        this.setData(data, menu, records);
      } else {
        console.error(`Results::getReport: Error response from API ${response.code}`);
      }
    });

  }

  openNewWindow(button) {
    const variables = button.variables || {};
    dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables).then(response => {
      if (response.status >= 200 && response.status < 300) {
        window.open().document.write(response.data.data ? response.data.data : response.data);
      }
      //If not open an APIDialog
    });
  }

  handleButton(button) {
    if (button.action === buttonTypes.OPEN_NEW_WINDOW) {
      return this.openNewWindow(button);
    }
    /*if(button.action === buttonTypes.DIALOG_BOX){
      return this.getDialogBoxData(button, uniqueID);
    }else if(button.endpoint && button.method){
      const variables = button.variables || {};
      dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables)
        .then(response => {
          if(response.status >= 200 && response.status < 300){
            this.props.refreshPage();
          }
          this.openAPIDialog(response);
        }
      );
    }else {
      console.warn("please include endpoint and method");
    }*/
  }

  /* We still need to bring the complete Buttonbar Functionality, including:
   ** - DialogBoxes
   ** - APIResponseDialog
   */

  getMenu(menuData) {
    let renderedButtonbar = null;
    if (menuData.buttons) {
      renderedButtonbar = (
        <Buttonbar
          buttonbar={menuData.buttons}
          handleButton={this.handleButton.bind(this)}
          style={{ position: 'absolute', right: '5px', top: '25px' }}
        />
      );
    }
    return renderedButtonbar;
  }

  //render the raw HTML of the report under the appropriate label
  render() {
    if (this.state.recordsData) return (
      <Records  data={this.state.recordsData}  />
    );
    return (
      <div className="results">
        {this.state.menuData && this.getMenu(this.state.menuData)}
        <div className="lable">{this.props.report.label}</div>

      {this.state.resultData && <div style={{ marginTop: '25px' }} dangerouslySetInnerHTML={{ __html: this.state.resultData }} />}
      </div>
    );
  }
}
