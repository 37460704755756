import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/GlobalActions';

import Day from '../components/Day/Day';
import NotFoundPage from '../components/AppLayout/NotFoundPage';

export const DayPage = props => {
  // console.log("Day page");
  // console.log(props);
  // console.log('-----');

  if (!props.params.year || !props.params.month || !props.params.day) {
    return <NotFoundPage />;
  } else {
    const day = `${props.params.year}-${props.params.month}-${props.params.day}`;
    try {
      const dayData = props.data.data.calendar.dayData[day];
      // console.log("Dat day data:");
      // console.log(dayData);
      return (
        <Day
          GlobalActions={props.actions}
          data={dayData}
          params={props.params}
          notifications={props.notifications}
          apikey={props.data.config}
          privs={props.privs}
        />
      );
    } catch (e) {
      // console.log(e);
      return <Day GlobalActions={props.actions} data={null} params={props.params} />;
    }
  }
};

function mapStateToProps(state) {
  // console.log("DayPage container, mapping state to props:");
  // console.log(state);
  // console.log('-----');
  return { data: state.APIReducer, notifications: state.notifications, privs: state.PrivilegeReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPage);
