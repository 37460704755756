import React from 'react';
import { Form, FormGroup, Col, Row, Button, FormLabel, FormControl } from 'react-bootstrap';
import { createEvent, editEvent, deleteEvent } from '../../actions/API';
import Loading from 'react-loading';
//import moment from 'moment';
import { ShiftTimes, Radio, SkillInput } from '../FormBuilder/';
import Drag from '../Common/Drag';
import { connect } from 'react-redux';

//a draggable modal popup to edit/create events
class EventModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isInvalidTime = this.isInvalidTime.bind(this);
  }

  componentWillMount() {
    const rangeStart = this.props.day.toDate();
    const rangeEnd = this.props.day.clone().add(36, 'hours').toDate();

    const defaultStart = this.props.day.format('YYYY-MM-DD HH:mm:ss');
    const defaultEnd = this.props.day.clone().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss');

    // console.log(this.props.day);

    //construct standard time config object
    const config = {
      times: {
        range: {
          start: rangeStart,
          end: rangeEnd,
        },
        default: {
          start: defaultStart,
          end: defaultEnd,
        },
      },
    };

    // pull the relevant data and initialize the state.
    // if we were provided with a label and description, use them as default values
    this.setState({
      item_label: this.props.event ? this.props.event.item_label : '',
      item_desc: this.props.event ? this.props.event.item_desc : '',
      item_date: this.props.day.format('YYYY-MM-DD'),
      type: this.props.event ? this.props.event.type : 'event',
      config: config,
    });
  }

  render() {
    if (this.state) {
      // should the submit button create or save the event?
      var submitFunc = this.props.event ? editEvent : createEvent;
      return (
        <Drag close={this.props.close.bind(this)} headerText="Event" className="eventModal">
          <div className="eventModalContent">
            <Form horizontal>
              <Row>
                <Col xs={12}>
                  {this.props.privs.events
                    .create /* if this user has create privileges and also we are not modifying an existing event, show the type selector*/ &&
                    this.props.privs.signups.create &&
                    !this.props.event && (
                      <Radio
                        label="Type"
                        name="type"
                        handleInputChange={this.handleInputChange.bind(this)}
                        default="event"
                        options={{ event: 'Normal', signup: 'Signup' }}
                      />
                    )}

                  {this.state.type === 'signup' && (
                    <div>
                      <ShiftTimes
                        label="Start:"
                        name="datetime_start"
                        times={this.state.config.times}
                        default={this.state.config.times.default.timein}
                        handleInputChange={this.handleInputChange}
                      />
                      <ShiftTimes
                        label="End:"
                        name="datetime_end"
                        times={this.state.config.times}
                        default={this.state.config.times.default.timeout}
                        handleInputChange={this.handleInputChange}
                      />
                    </div>
                  )}

                  <FormGroup controlId="label">
                    <FormLabel>Event Name</FormLabel>
                    <FormControl
                      type="text"
                      label="Text"
                      name="item_label"
                      placeholder="..."
                      defaultValue={this.props.event ? this.props.event.item_label : null}
                      onChange={this.handleInputChange.bind(this, 'item_label')}
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Description</FormLabel>
                    <FormControl
                      componentClass="textarea"
                      onChange={this.handleInputChange.bind(this, 'item_desc')}
                      defaultValue={this.props.event ? this.props.event.item_desc : null}
                    />
                  </FormGroup>

                  {this.props.privs.signups.create && this.state.type === 'signup' && (
                    <SkillInput
                      skills={this.props.config.signup.skills}
                      handleInputChange={this.handleInputChange.bind(this)}
                    />
                  )}

                  {this.dutySelect(this.props.config.duties)}

                  <Button
                    variant="primary"
                    className="hCenter"
                    type="submit"
                    onClick={this.getEventChangeFunction(submitFunc).bind(this)}
                    disabled={this.isInvalidTime()}
                  >
                    Submit
                  </Button>
                  {this.props.event ? (
                    <Button
                      variant="primary"
                      className="hCenter deleteButton"
                      type="submit"
                      onClick={this.getEventChangeFunction(deleteEvent).bind(this)}
                    >
                      Delete
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </div>
        </Drag>
      );
    } else {
      return (
        <div className="hCenter">
          <Loading type="bars" color="#e3e3e3" />
        </div>
      );
    }
  }

  getEventChangeFunction(eventFunc) {
    return function (e) {
      let that = this;
      // console.log('that', that);
      e.preventDefault();
      //console.log("getEventChangeFunction");
      //console.log(this);
      let params = this.state;
      delete params.config;
      if (this.props.event) {
        params.dayitem_id = this.props.event.day_item_id;
      }
      eventFunc(params)
        .then(response => {
          that.props.close();
          let d = that.props.day.format('YYYY-MM-DD').split('-');
          // console.log('refreshing day....');
          that.props.GlobalActions.fetchDay({ year: d[0], month: d[1], day: d[2] });
          return response;
        })
        .then(response => {
          that.props.GlobalActions.pushNotification();
        });
    };
  }

  dutySelect(duties) {
    //if only one duty, send that, otherwise, render select
    if (Object.keys(duties).length === 1 && duties.constructor === Object && !this.state.duty_id) {
      this.handleInputChange('duty_id', {
        target: {
          type: 'select',
          value: duties[0].duty_id,
        },
      });
    } else if (Object.keys(duties).length > 1 && duties.constructor === Object) {
      let options = [];
      for (let key in duties) {
        if (duties.hasOwnProperty(key)) {
          options.push(
            <option key={key} value={duties[key].duty_id}>
              {duties[key].label}
            </option>,
          );
        }
      }
      return (
        <FormControl
          componentClass="select"
          placeholder="select"
          onChange={this.handleInputChange.bind(this, 'roster_id')}
        >
          {options}
        </FormControl>
      );
    }
  }

  isInvalidTime() {
    return false;
    //TODO: start actually checking the time. why is this commented out??
    //return moment(this.state.form.startTime).isAfter(moment(this.state.form.endTime));
  }

  handleInputChange(name, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }
}

function mapStateToProps(state) {
  return {
    privs: state.PrivilegeReducer,
    config: state.ConfigReducer,
  };
}

export default connect(mapStateToProps)(EventModal);
