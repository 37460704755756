import React from 'react';
import { get } from '../../actions/REST';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'react-loading';
import Router from '../AppLayout/Router';
import DayPanel from './DayPanel';
import DayView from '../DayView/DayView';
import MuiDropdown from '../Common/MuiDropdown';
import Buttonbar from '../Common/Buttonbar';
import DialogBox from '../Common/DialogBox';
import CustomToolTip from '../Common/CustomToolTip';
import APIResponseDialog from '../Common/APIResponseDialog';
import { dynamicOEButtonCall } from '../../actions/API';
import classNames from 'classnames';

const buttonTypes = require('../../constants/buttonTypes.js');
const endpointTypes = require('../../constants/endpointTypes.js');

//CONSTANTS
const nav = {
  FETCH_BY_SELDATE: 'FETCH_BY_SELDATE',
  NEXT_MONTH: 'NEXT_MONTH',
  PREV_MONTH: 'PREV_MONTH',
  BACK: 'BACK',
  FORWARD: 'FORWARD',
  CURRENT_MONTH: 'CURRENT_MONTH',
};

const filterTypes = {
  DUTY: 'duty',
  STATION: 'station',
};

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mday_type: null,
      group_id: null,
      days: null,
      scope: null,
      filters_hidden: false,
      currentMonth: {
        seldate: null,
        label: null,
        class: '',
        icon: '',
        iconClass: '',
      },
      navigate: {
        scope_list: [],
        nextMonth: null,
        prevMonth: null,
        navigateBack: [],
        navigateForward: [],
      },
      weekly_scope: [],
      dayView: {
        show: this.props.showDayView ? this.props.showDayView : false,
        endpoint_type: null,
        endpoint: null,
        displayRouter: false,
      },
      dialog_box: {
        data: null,
        show: false,
        uniqueID: '',
      },
      dialog: {
        open: false,
        response: null,
      },
      loading: false,
      top_buttonbar: null,
      show_filters: false,
      duty_filters: null,
      dutys_black_list: [],
      station_filters: null,
      stations_black_list: [],
      filters_api: {
        endpoint: null,
        endpoint_type: null,
        method: null,
        variables: null,
      },
      clear_filter_btn: false,
      clear_filter_btn_label: '',
    };
    this.parseData = this.parseData.bind(this);
    /* We debounce the reloadCalendar method to control the amount of GET Requests
     ** we make to the Back-end when we are bidding (POST to back-end).
     **
     ** If we POST a bid, we call ReloadCalendar and because it's debounced it will
     ** wait 3 seconds to see if we call reloadCalendar again (by making another bid)
     ** if we do, then it resets the counter and the 3 countdown starts again. If the
     ** 3 seconds expire then we finally make the GET request to refresh the Calendar.
     **
     ** Enhancing the performance of this Component.
     **
     ** We now do the same with saveFilters.
     */
    this.reloadCalendar = this.debounce(this.reloadCalendar, 3000);
    this.saveFilters = this.debounce(this.saveFilters, 3000);
  }

  debounce(func, delay) {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  }

  componentDidMount() {
    //validating props!
    if (this.props.showDayView) {
      return;
    }
    if (this.props.data && this.props.data.data && this.props.data.data.config && this.props.data.data.data) {
      this.parseData(this.props.data.data);
    } else {
      console.warn('INVALID JSON');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /* Quick_link */
    let dayView = {
      show: false,
      endpoint_type: null,
      endpoint: null,
      displayRouter: false,
    };
    if (nextProps.tabs) {
      const calendar_tab = nextProps.tabs.filter(tab => tab.label.toUpperCase() === 'CALENDAR')[0];
      if (calendar_tab && calendar_tab.open_link !== null) {
        //Accessed through Open link
        const open_link = calendar_tab.open_link;
        dayView['show'] = true;
        dayView['endpoint_type'] = open_link.endpoint_type;
        dayView['endpoint'] = open_link.endpoint;
        dayView['open_link'] = true;
        this.setState({ dayView });
      } else {
        /* This means that we accessed Calendar from Navigation Tabs
         ** We set open_link to false to let DayView knows that it shouldn't fetch a new Date. */
        this.setState(prevState => ({
          dayView: Object.assign({}, prevState.dayView, {
            open_link: false,
          }),
        }));
      }
    }
  }

  parseData({ config, data }) {
    //Destructuring
    const { scope, mday_type, group_id } = config;
    const currentMonth = {
      seldate: config.seldate,
      label: config.label,
      class: config.class,
      icon: config.icon,
      iconClass: config.iconClass,
    };

    /** Highlighting Dropdown **/
    const top_panel_buttonbar = config.highlight_buttonbar;

    /** Top Buttonbar **/
    let top_buttonbar = null;

    /** Filters **/
    let duty_filters = null;
    let station_filters = null;
    let dutys_black_list = [];
    let stations_black_list = [];
    let clear_filter_btn = false; //Default
    let clear_filter_btn_label; //Default
    let filters_api = {
      endpoint: null,
      endpoint_type: null,
      method: null,
      variables: null,
    };
    let filters_hidden = false;

    if (config && config.filters) {
      filters_hidden = config.filters.hidden;
      clear_filter_btn_label = config.filters.clear_btn_label || 'Show All';
      if (config.filters.clear_btn) {
        //Allow the back-end to show the clear all button
        clear_filter_btn = config.filters.clear_btn;
      }
      if (config.filters.duty) {
        duty_filters = Object.keys(config.filters.duty).map(key => config.filters.duty[key]);
      }
      if (config.filters.station) {
        station_filters = Object.keys(config.filters.station).map(key => config.filters.station[key]);
      }
      filters_api['endpoint'] = config.filters.endpoint;
      filters_api['endpoint_type'] = config.filters.endpoint_type;
      filters_api['method'] = config.filters.method;
      filters_api['variables'] = config.filters.variables;
    }

    if (config && config.buttonbar) {
      top_buttonbar = config.buttonbar;
    }

    //Creating Black Lists
    if (duty_filters.length > 0) {
      duty_filters
        .filter(filter => !filter.selected)
        .map(filter => {
          if (filter.controlled && filter.controlled.length > 0) {
            for (let id of filter.controlled) {
              dutys_black_list.push(id);
            }
          }
          return filter;
        });
    }
    if (station_filters.length > 0) {
      station_filters
        .filter(filter => !filter.selected)
        .map(filter => {
          if (filter.controlled && filter.controlled.length > 0) {
            for (let id of filter.controlled) {
              stations_black_list.push(id);
            }
          }
          return filter;
        });
    }

    const navigation = config.navigation;
    let next, last, jump;

    /* Navigation is optional */
    if (navigation) {
      next = navigation.next || null;
      last = navigation.last || null;
      jump = navigation.jump || null;
    }

    let back_list, forward_list;
    /* Jump is optional */
    if (jump) {
      back_list = !jump.back
        ? []
        : Object.keys(jump.back).map(seldate => ({
            seldate: seldate,
            date: jump.back[seldate],
          }));
      forward_list = !jump.forward
        ? []
        : Object.keys(jump.forward).map(seldate => ({
            seldate: seldate,
            date: jump.forward[seldate],
          }));
    }

    const scope_list = Object.keys(config.scope_options).map(scope_name => config.scope_options[scope_name]);

    const navigate = {
      scope_list,
      navigateBack: back_list,
      navigateForward: forward_list,
      nextMonth: next,
      prevMonth: last,
    };

    //Calendar Content
    const keys = Object.keys(data).filter(obj => typeof data[obj] === 'object');
    const days = keys.map(key => ({
      date: key.split('-').join('/'),
      data: data[key],
    }));

    //This should be sent from the back-end along with the rest of the scopes
    let weekly_scope = [];

    /*
     ** Creating the Weekly Scope for all the scopes except Week.
     ** Currently Saturday is the last day of the week. Creating an
     ** array of objects with "date" and "seldate".
     */
    if (config.scope !== 'week') {
      for (let day of days) {
        new Date(day.date).toString().split(' ')[0] === 'Sat' &&
          weekly_scope.push({
            date: day.date,
            seldate: new Date(day.date).getTime() / 1000, //Time in seconds
          });
      }
      const last_day = days[days.length - 1];
      new Date(last_day.date).toString().split(' ')[0] !== 'Sat' &&
        weekly_scope.push({
          date: last_day.date,
          seldate: new Date(last_day.date).getTime() / 1000, //Time in seconds
        });
    }

    /* Quick_link */
    let dayView = {
      show: false,
      endpoint_type: null,
      endpoint: null,
      displayRouter: false,
    };
    if (this.props.tabs) {
      const calendar_tab = this.props.tabs.filter(tab => tab.label.toUpperCase() === 'CALENDAR')[0];
      if (calendar_tab && calendar_tab.open_link) {
        const open_link = calendar_tab.open_link;
        dayView['show'] = true;
        dayView['endpoint_type'] = open_link.endpoint_type;
        dayView['endpoint'] = open_link.endpoint;
        dayView['displayRouter'] = open_link.endpoint_type !== endpointTypes.DAYVIEW ? true : false;
      }
    }

    //Updating the state with the correct Format
    return this.setState({
      filters_hidden,
      top_panel_buttonbar,
      top_buttonbar,
      currentMonth,
      navigate,
      days,
      scope,
      weekly_scope,
      mday_type,
      group_id,
      duty_filters,
      station_filters,
      dutys_black_list,
      stations_black_list,
      dayView,
      filters_api,
      clear_filter_btn,
      clear_filter_btn_label,
    });
  }

  getNewCalendar(action_type, seldate = null, endpoint = null, scope = null) {
    let params;
    switch (action_type) {
      case nav.FETCH_BY_SELDATE:
        params = {
          scope: this.state.scope,
          seldate,
        };
        break; //If there's no more cases transform this switch
      case 'FETCH_BY_SCOPE':
        params = {
          seldate,
        };
        break;
      default:
        params = {};
    }
    if (scope) {
      params['scope'] = scope;
    }
    if (this.state.group_id) {
      params['group_id'] = this.state.group_id;
    }

    endpoint = endpoint || this.props.endpoint_info.endpoint;
    get(params, `/${endpoint}`, 'fetching calendar').then(response => {
      if (response.statusText !== 'OK') {
        console.error('API response', response);
        return false;
      }
      return this.parseData(response.data);
    });
  }

  changeCurrentMonth(action_type) {
    let currentMonth;
    switch (action_type) {
      case nav.NEXT_MONTH:
        currentMonth = this.state.navigate.nextMonth;
        break;
      case nav.PREV_MONTH:
        currentMonth = this.state.navigate.prevMonth;
        break;
      case nav.CURRENT_MONTH:
        currentMonth = Object.assign({}, this.state.currentMonth, {
          endpoint: this.state.navigate.nextMonth.endpoint,
        });
        break;
      default:
        currentMonth = currentMonth = Object.assign({}, this.state.currentMonth, {
          endpoint: this.state.navigate.nextMonth.endpoint,
        });
    }
    this.getNewCalendar(nav.FETCH_BY_SELDATE, currentMonth.seldate, currentMonth.endpoint);
  }

  getJumpOptions(action_type) {
    const { navigateForward, navigateBack } = this.state.navigate;

    switch (action_type) {
      case nav.FORWARD:
        return (
          <MuiDropdown
            key="jumpForward"
            id="jumpForwardMenu"
            label="Forward"
            className={'forward calendar-button ' + this.state.currentMonth.class}
            handleSelection={value => this.getNewCalendar(nav.FETCH_BY_SELDATE, value)}
            options={
              navigateForward.length > 0 &&
              navigateForward.map(nav => ({
                label: nav.date,
                value: nav.seldate,
              }))
            }
          />
        );
      case nav.BACK:
        return (
          <MuiDropdown
            key="jump_back"
            id="jumpBackMenu"
            label="Back"
            className={'back calendar-button ' + this.state.currentMonth.class}
            handleSelection={value => this.getNewCalendar(nav.FETCH_BY_SELDATE, value)}
            options={
              navigateBack.length > 0 &&
              navigateBack.map(nav => ({
                label: nav.date,
                value: nav.seldate,
              }))
            }
          />
        );
      default:
        return null;
    }
  }

  getWeekButton(week) {
    return (
      <Button
        key={week.seldate}
        className={classNames('calendar-button ', this.state.currentMonth.class)}
        onClick={() => this.getNewCalendar(nav.FETCH_BY_SELDATE, week.seldate, null, 'week')}
        style={{
          maxWidth: '31px',
          maxHeight: '100px',
          minWidth: '31px',
          minHeight: '100px',
          padding: '0',
        }}
      >
        <abbr title={'week view ' + week.date}>
          <FontAwesomeIcon icon="angle-right" style={{ fontSize: '30px' }} />
        </abbr>
      </Button>
    );
  }

  reloadCalendar() {
    this.changeCurrentMonth(nav.CURRENT_MONTH);
  }

  /* starting day should be configurable */
  getCalendarRows(days, days_per_row = 7) {
    //width to limit to 7 days per row in this case
    const limit_to = 100 / days_per_row;

    /* Creates the empty days to align with the correct Day Column. */
    let empty_days = [];
    let first_day = new Date(days[0].date).getDay();
    for (let counter = 0; counter < first_day; counter++) {
      empty_days.push(
        <DayPanel
          key={`empty_days_${counter}`}
          data={null}
          limit_to={limit_to}
          onDaySelected={null}
          left_button={
            this.state.scope !== 'week' && counter === 0 ? this.getWeekButton(this.state.weekly_scope[0]) : null
          }
        />,
      );
    }

    const DAYS_HEADER = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const calendar_header = DAYS_HEADER.map(day => (
      <div key={day} style={{ width: `${limit_to}%` }}>
        <strong>{day}</strong>
      </div>
    ));
    const rendered_dayPanel = days.map((day, index) => (
      <DayPanel
        key={day.date}
        mday_type={this.state.mday_type}
        dialogInitialPos={{ position: 'absolute', left: '0' }}
        dutys_black_list={this.state.dutys_black_list}
        stations_black_list={this.state.stations_black_list}
        data={day.data}
        limit_to={limit_to}
        onSuccess={this.reloadCalendar.bind(this)}
        onDaySelected={(endpoint_type, endpoint) => this.showDayView(endpoint_type, endpoint)}
        left_button={
          this.state.scope !== 'week' && (index + empty_days.length) % 7 === 0
            ? this.getWeekButton(this.state.weekly_scope[(index + empty_days.length) / 7])
            : null
        }
      />
    ));
    const calendar_body = empty_days.concat(rendered_dayPanel);
    /* USE A TABLE TO RENDER CALENDAR */
    return (
      <div className="calendar-body-wrapper">
        <div className="calendar-days-header">{calendar_header}</div>
        {calendar_body}
      </div>
    );
  }

  /*
   ** param {String} - endpoint_type
   ** param {String} - endpoint
   */
  showDayView(endpoint_type, endpoint) {
    return this.setState({
      dayView: {
        show: true,
        endpoint_type,
        endpoint,
        displayRouter: endpoint_type !== endpointTypes.DAYVIEW ? true : false,
      },
    });
  }

  /*
   ** Returns the Button to navigate to the previous month/week/period.
   **
   ** @param {Object} prev
   */
  getPrevButton(prev) {
    return (
      <div className="prev-calendar" onClick={() => this.changeCurrentMonth(nav.PREV_MONTH)}>
        <FontAwesomeIcon icon="angle-double-left" style={{ marginRight: '5px' }} />
        <span>{prev.label}</span>
      </div>
    );
  }

  /*
   ** Returns the Button to navigate to the next month/week/period.
   **
   ** @param {Object} prev
   */
  getNextButton(next) {
    return (
      <div className="next-calendar" onClick={() => this.changeCurrentMonth(nav.NEXT_MONTH)}>
        <span>{next.label}</span>
        <FontAwesomeIcon icon="angle-double-right" style={{ marginLeft: '5px' }} />
      </div>
    );
  }

  renderScopeOptions(navigate) {
    return navigate.scope_list.map(scope => (
      <Button
        key={scope.type}
        size="small"
        className={classNames('calendar-button ', this.state.currentMonth.class)}
        onClick={() => this.getNewCalendar('FETCH_BY_SCOPE', scope.seldate, scope.endpoint)}
      >
        {scope.label}
      </Button>
    ));
  }

  renderCalendarHeader(navigate, currentMonth) {
    let nextButton = null;
    if (navigate.nextMonth) {
      nextButton = this.getNextButton(navigate.nextMonth, nav.NEXT_MONTH);
    }

    let prevButton = null;
    if (navigate.prevMonth) {
      prevButton = this.getPrevButton(navigate.prevMonth, nav.PREV_MONTH);
    }
    return (
      <div className={classNames('calendar-header cardHeader ', currentMonth.class)}>
        {prevButton}
        <div className="current-calendar">
          <CustomToolTip icon={currentMonth.icon} iconClass={currentMonth.iconClass} />
          <span>{currentMonth.label}</span>
        </div>
        {nextButton}
      </div>
    );
  }

  renderDayView() {
    const { currentMonth } = this.state;
    const dayView = {
      show: false,
      endpoint_type: null,
      endpoint: null,
    };
    let endpoint = this.props.dayViewEndpoint ? this.props.dayViewEndpoint : this.state.dayView.endpoint;
    let use_endpoint = false;
    if (this.props.history.location.state && this.props.history.location.state.endpoint) {
      endpoint = this.props.history.location.state.endpoint;
      use_endpoint = true;
    }
    console.log("renderDayView endpoint="+endpoint);
    console.log(this.props);
    console.log(this.state.open_link);
    return (
      <DayView
        history={this.props.history}
        endpoint={endpoint}
        open_link={this.state.dayView.open_link}
        updateRibbon={this.props.updateRibbon}
        data={this.props.showDayView  && !use_endpoint ? this.props.data : null}
        onChangeToMDay={seldate => {
          this.getNewCalendar(nav.FETCH_BY_SELDATE, seldate, currentMonth.endpoint);
          this.setState({ dayView, currentMonth: { seldate: null } });
        }}
      />
    );
  }

  /*
   ** Here we save the filters in the API.
   **
   ** Note: This function in debounced in the constructor, meaning that this
   ** function will wait 3 seconds after being called, if it gets called again
   ** before the 3 seconds it resets the timer and wait again, If it doesn't get
   ** called in 3 seconds then it's finally executed.
   */
  saveFilters() {
    if (this.state.filters_api.endpoint) {
      let variables = {};
      if (this.state.station_filters) {
        //Add Station Filters
        for (let filter of this.state.station_filters) {
          Object.assign(variables, {
            [filter.name]: filter.selected,
          });
        }
      }
      if (this.state.duty_filters) {
        //Add Duty Filters
        for (let filter of this.state.duty_filters) {
          Object.assign(variables, {
            [filter.name]: filter.selected,
          });
        }
      }
      //Add variables sent from the Back-end
      if (Object.keys(this.state.filters_api.variables).length > 0) {
        Object.assign(variables, this.state.filters_api.variables);
      }
      const { endpoint, method } = this.state.filters_api;
      dynamicOEButtonCall(`/${endpoint}`, method, variables).then(response => {
        this.openAPIDialog(response);
      });
    } else {
      console.warn('no endpoint was found');
    }
  }

  /* Change all the filters to be checked and then save in the back-end. */
  clearAllFilters() {
    this.setState(
      prevState => ({
        dutys_black_list: [],
        stations_black_list: [],
        duty_filters: prevState.duty_filters.map(filter => Object.assign(filter, { selected: true })),
        station_filters: prevState.station_filters.map(filter => Object.assign(filter, { selected: true })),
      }),
      () => this.saveFilters(),
    );
  }

  /*
   ** Add the Array of ids to the black_list so it can
   ** be hidden and change the selected of the Filter that was
   ** clicked.
   **
   ** Note: We keep the logic in the Front-End, so the changes you
   ** see by checking a filter are handled in the front-end, but we now
   ** use saveFilters to persist these preferences in the back-end.
   **
   ** @param {Array} - ids
   ** @param {Object} - newFilter
   */
  addToBlackList(ids, newFilter, filter_type) {
    this.setState(
      prevState => {
        newFilter['selected'] = !newFilter.selected;
        switch (filter_type) {
          case filterTypes.DUTY:
            return {
              dutys_black_list: prevState.dutys_black_list.concat(ids),
              duty_filters: prevState.duty_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
            };
          case filterTypes.STATION:
            return {
              stations_black_list: prevState.stations_black_list.concat(ids),
              station_filters: prevState.station_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
            };
          default:
            return {};
        }
      },
      () => this.saveFilters(),
    );
  }

  /*
   ** Remove the Array of ids from the black_list so it can
   ** be displayed on the screen and change the selected of the
   ** Filter that was clicked.
   **
   ** Note: We keep the logic in the Front-End, so the changes you
   ** see by checking a filter are handled in the front-end, but we now
   ** use saveFilters to persist these preferences in the back-end.
   **
   ** @param {Array} - ids
   ** @param {Object} - newFilter
   */
  removeFromBlackList(ids, newFilter, filter_type) {
    this.setState(
      prevState => {
        newFilter['selected'] = !newFilter.selected;
        switch (filter_type) {
          case filterTypes.DUTY:
            return {
              duty_filters: prevState.duty_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
              dutys_black_list: ids
                ? prevState.dutys_black_list.filter(id => !ids.includes(id))
                : prevState.dutys_black_list,
            };
          case filterTypes.STATION:
            return {
              station_filters: prevState.station_filters.map(prevFilter =>
                prevFilter.name === newFilter.name ? newFilter : prevFilter,
              ),
              stations_black_list: ids
                ? prevState.stations_black_list.filter(id => !ids.includes(id))
                : prevState.stations_black_list,
            };
          default:
            return {};
        }
      },
      () => this.saveFilters(),
    );
  }

  handleFilter(filter_clicked, filter_type) {
    let controlled = filter_clicked.controlled;
    if (filter_clicked.selected) {
      this.addToBlackList(controlled, filter_clicked, filter_type);
    } else {
      this.removeFromBlackList(controlled, filter_clicked, filter_type);
    }
  }

  renderFilters(filters, filter_type) {
    let rendered_filters = [];
    if (filters && filters.length > 0) {
      rendered_filters = filters
        .filter(filter => filter.input_type && (filter.name || filter.label))
        .map(filter => (
          <div key={'filter_' + filter.name + filter.value + filter.label} className="filter-wrapper">
            <input
              key={filter.name}
              className="filter-input"
              name={filter.name}
              type={filter.input_type}
              onChange={() => this.handleFilter(filter, filter_type)}
              checked={filter.selected}
            />
            <label className="filter-label" htmlFor={filter.name}>
              {filter.label}
            </label>
          </div>
        ));
    }
    return rendered_filters;
  }

  toggleFilter() {
    this.setState(prevState => ({
      show_filters: !prevState.show_filters,
    }));
  }

  openAPIDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState({ dialog: { open: true, response: response } }, () => {
      setTimeout(() => {
        this?.setState({ dialog: { open: false, response: response } });
      }, 3000);
    });
  }

  //Close the box and delete the data from the state
  closeDialogBox() {
    this.setState({
      dialog_box: {
        data: null,
        show: false,
      },
    });
  }

  openDialogBox(response, dialog_type, uniqueID) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: response,
        show: true,
        dialog_type,
      },
    });
  }

  getDialogBoxData(button, uniqueID) {
    this.setState({ loading: true });
    if (!button.endpoint) {
      //inline dialogBox
      if (button.data) {
        this.openDialogBox(button, button.dialog_type, uniqueID, button.variables);
      } else {
        console.warn('Tried to open an Inline DialogBox (no endpoint) without a data property.');
        this.setState({ loading: false });
      }
      return null;
    }
    get(button.variables, `/${button.endpoint}`, 'Fetching data for DialogBox')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, button.dialog_type, uniqueID);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.openAPIDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.openAPIDialog(response));
        }
      })
      .catch(err => this.setState({ loading: false }, () => this.openAPIDialog(err)));
  }

  handleButton(button, uniqueID) {
    if (button.action === buttonTypes.DIALOG_BOX) {
      this.getDialogBoxData(button, uniqueID);
    } else if (button.endpoint && button.method) {
      const variables = button.variables || {};
      dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables).then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (variables.group_id) {
            this.setState({
              scope: variables.scope,
              group_id: variables.group_id,
            });
          }
        }
        if (response.status >= 200 && response.status < 300) {
          this.changeCurrentMonth(nav.CURRENT_MONTH);
        }
        this.openAPIDialog(response);
      });
    } else {
      console.warn('endpoint and/or method not found');
    }
  }

  renderButtonbar(buttonbar) {
    if (!buttonbar) {
      return null;
    }
    return (
      <Buttonbar
        ndClass={this.state.currentMonth.class}
        style={{ marginLeft: '-2px', marginBottom: '5px' }}
        buttonbar={buttonbar}
        handleButton={this.handleButton.bind(this)}
      />
    );
  }

  render() {
    const { currentMonth, navigate, dayView } = this.state;
    const visibility_class = this.state.show_filters ? '' : 'display-none';
    const control_text = this.state.show_filters ? 'Hide Filters' : 'Show Filters';
    const icon = this.state.show_filters ? 'angle-left' : 'angle-right';
    const max_width = this.state.show_filters ? {} : { maxWidth: '125px' };
    const top_buttonbar = this.state.top_buttonbar;
    if (this.state.dayView && this.state.dayView.displayRouter) {
      return <Router content={this.state.dayView} updateRibbon={this.props.updateRibbon} history={this.props.history} />;
    } else if (dayView.show) {
      return this.renderDayView();
    } else if (currentMonth.seldate) {
      return (
        <div className="calendar">
          <div>
            {this.state.dialog_box.show && this.state.dialog_box.data && (
              <DialogBox
                key={'dialog_box' + this.state.dialog_box.uniqueID}
                data={this.state.dialog_box.data}
                dialog_type={this.state.dialog_box.dialog_type}
                openDialog={this.openAPIDialog.bind(this)}
                onClose={this.closeDialogBox.bind(this)}
                onSuccess={() => this.changeCurrentMonth(nav.CURRENT_MONTH)}
              />
            )}
            {!this.state.filters_hidden && (
              <div className="top-panel" style={max_width}>
                <div className={'control ' + this.state.currentMonth.class} onClick={this.toggleFilter.bind(this)}>
                  <span className="control-label">{control_text}</span>
                  <FontAwesomeIcon size="lg" icon={icon} className="control-icon" />
                </div>
                <div key="filters" className={`filters ${this.state.currentMonth.class} ${visibility_class}`}>
                  {this.renderFilters(this.state.duty_filters, filterTypes.DUTY)}
                  {this.renderFilters(this.state.station_filters, filterTypes.STATION)}
                </div>
                {this.state.clear_filter_btn && (
                  <button
                    className={`btn btn-default nd-button ${visibility_class} ${this.state.currentMonth.class}`}
                    style={{
                      marginTop: 0,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    onClick={this.clearAllFilters.bind(this)}
                  >
                    <CustomToolTip icon="delete" iconClass="svg-img-white" />
                    {this.state.clear_filter_btn_label}
                  </button>
                )}
              </div>
            )}
            <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
            <div className="nav-options">
              {this.state.top_panel_buttonbar && (
                <Buttonbar
                  ndClass={this.state.currentMonth.class}
                  buttonbar={this.state.top_panel_buttonbar}
                  handleButton={this.handleButton.bind(this)}
                />
              )}
              <div className="jump-section">
                {navigate.navigateBack && this.getJumpOptions(nav.BACK)}
                {navigate.navigateForward && this.getJumpOptions(nav.FORWARD)}
              </div>
              <div className="scope-section">{this.renderScopeOptions(navigate)}</div>
              {this.renderButtonbar(top_buttonbar)}
            </div>
            <div className="calendar-content contentCard">
              {this.renderCalendarHeader(navigate, currentMonth)}
              <div className="calendar-body cardBody">{this.state.days && this.getCalendarRows(this.state.days)}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading type="bars" color="#e3e3e3" />;
    }
  }
}
