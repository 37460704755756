import React from 'react';
import { Button, Alert } from 'react-bootstrap';
import Checkbox from './Checkbox';
import DateSelect from './DateSelect';
import Dropdown from './Dropdown';
import Input from './Input';
import Loading from 'react-loading';
import { get, post } from '../../../actions/REST';

/* Type of Fields */
const input_type = {
  INPUT: 'input',
  DROPDOWN: 'dropdown',
  DATE_SELECT: 'date_select',
  CHECKBOX: 'checkbox',
};

/*********************** MAIN COMPONENT ***********************
 ** This is an independent Form for now, but we should work on
 ** getting all this to Form.js and have a single "Form Handler"
 ** to avoid duplicating code.
 */

export default class ScheduleBuilderApp extends React.Component {
  /* @black_list -> ID of the controlled fields under each Filter with False
  value, IDs are removed when a filter changes to true.*/
  constructor(props) {
    super(props);
    this.state = {
      field_groups: [],
      filters: [],
      black_list: [],
      buttons: [],
      alert: null,
      loading: false,
    };
    this.parseData = this.parseData.bind(this);
    this.getParams = this.getParams.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleControlPanel = this.handleControlPanel.bind(this);
    this.setScheduleDetails = this.setScheduleDetails.bind(this);
  }

  componentDidMount() {
    this.parseData(this.props.scheduleData);
  }

  parseData(data) {
    if (!data.config) {
      return null;
    }
    let { filters, field_groups, control_panel } = data.config;
    let black_list = [];

    for (let filter of filters.filter(filter => !filter.value)) {
      for (let id of filter.form_controller.controlled) {
        black_list.push(id);
      }
    }

    this.setState({
      loading: false,
      field_groups,
      filters,
      black_list,
      buttons: control_panel.buttons,
    });
  }

  /*
   ** This set all the filters "unchecked" (value of false) and since these fields
   ** are being hid, then we have to clear the value of the fields that they
   ** control and add all the controlled fields to the black_list.
   **
   ** Note: this does not clear the Fields that are not being control by a Filter.
   */
  clearFilters() {
    const { filters } = this.state;
    let ids_to_filter = [];
    for (let filter of filters) {
      ids_to_filter = ids_to_filter.concat(filter.form_controller.controlled);
    }
    this.setState(prevState => ({
      filters: prevState.filters.map(filter => Object.assign({}, filter, { value: false })),
      black_list: ids_to_filter,
      field_groups: this.updateFieldGroups(prevState.field_groups, null, '', ids_to_filter),
    }));
  }

  getFilters(filters) {
    let render_filters = filters
      .filter(filter => filter.input_type)
      .map(filter => (
        <Checkbox
          key={filter.id}
          label={filter.label}
          labelClass="filter-label"
          name={filter.name}
          type={filter.input_type}
          wrapperClass="beige-wrapper inline-block filter"
          onChange={() => this.handleFilter(filter)}
          value={filter.value}
        />
      ));

    return (
      <div className="contentCard filters-wrapper">
        <div className="cardHeader">
          <span>Filters</span>
        </div>
        <div className="cardBody filters-body">
          {render_filters}
          <div className="clear-all">
            <Button style={{ padding: '0', marginBottom: '5px' }} onClick={this.clearFilters} variant="link">
              clear all
            </Button>
          </div>
        </div>
      </div>
    );
  }

  /*
   ** Adds or Remove from black_list, depending
   ** on value of the checkbox.
   ** Note: Removing an id from black_list means showing that field
   ** on the screen.
   **
   ** @param {Object} - filter
   */
  handleFilter(filter) {
    let controlled = filter.form_controller.controlled;
    if (filter.value) {
      this.addToBlackList(controlled, filter);
    } else {
      this.removeFromBlackList(controlled, filter);
    }
  }

  /*
   ** Add the Array of ids to the black_list so it can
   ** be hidden and change the value of the Filter that was
   ** clicked.
   **
   ** @param {Array} - ids
   ** @param {Object} - newFilter
   */
  addToBlackList(ids, newFilter) {
    this.setState(prevState => {
      newFilter['value'] = !newFilter.value;
      return {
        black_list: prevState.black_list.concat(ids),
        filters: prevState.filters.map(prevFilter => (prevFilter.id === newFilter.id ? newFilter : prevFilter)),
        field_groups: this.updateFieldGroups(prevState.field_groups, null, '', ids),
      };
    });
  }

  /*
   ** Remove the Array of ids from the black_list so it can
   ** be displayed on the screen and change the value of the
   ** Filter that was clicked.
   **
   ** @param {Array} - ids
   ** @param {Object} - newFilter
   */
  removeFromBlackList(ids, newFilter) {
    this.setState(prevState => {
      newFilter['value'] = !newFilter.value;
      return {
        black_list: prevState.black_list.filter(id => !ids.includes(id)),
        filters: prevState.filters.map(prevFilter => (prevFilter.id === newFilter.id ? newFilter : prevFilter)),
      };
    });
  }

  renderLoading() {
    if (!this.state.loading) {
      return null;
    }
    const classes = 'glyphicon glyphicon-refresh glyphicon-refresh-animate loading-icon';
    return <span className={classes} />;
  }

  renderFilteredFields(field_group, black_list) {
    if (!field_group.fields) {
      return null;
    }
    let fields = this.getFilteredFields(field_group.fields, black_list);
    return this.getFields(fields);
  }

  getFilteredFields(fields, black_list) {
    return fields.filter(field => !black_list.includes(field.id));
  }

  renderContainers(field_group, black_list) {
    if (!field_group.containers) {
      return null;
    }
    let render_containers = [];
    for (let container of field_group.containers) {
      let filtered_fields = this.getFilteredFields(container.fields, black_list);
      if (filtered_fields.length > 0) {
        render_containers.push(
          <div key={container.label} className="form-container">
            <div className="form-header">
              <span>{container.label}</span>
            </div>
            <div className="form-body">{this.getFields(filtered_fields)}</div>
          </div>,
        );
      }
    }
    return render_containers;
  }

  /*
   ** Returns an array of Buttons.
   **
   ** @param {Array} - buttons
   */
  getControlPanel(buttons) {
    let all_buttons = buttons.map(button => (
      <Button
        key={button.label}
        variant="primary"
        className="panel-button"
        onClick={() => this.handleControlPanel(button.action, button.endpoint, button.method)}
      >
        {button.label}
      </Button>
    ));
    return all_buttons;
  }

  /*
   ** Create a contentCard for each group of fields.
   **
   ** Note: The 3rd Field Group will take the whole screen (min-width: 100%)
   **
   ** @param {Array} - field_groups
   ** @param {Array} - black_list
   */
  getFormBoxes(field_groups, black_list) {
    let count = 0;
    let width_control;
    return field_groups.map(field_group => {
      count++;
      width_control = count % 3 === 0 ? { minWidth: '100%' } : {};
      let rendered_controlPanel = null;
      if (count === 1) {
        //at the button in the first FormBox (Schedule Configurations)
        rendered_controlPanel = (
          <div className="control-panel">{this.state.buttons && this.getControlPanel(this.state.buttons)}</div>
        );
      }
      return (
        <div key={field_group.label} className="schedule-box contentCard" style={width_control}>
          <div className="cardHeader">
            <span>{field_group.label}</span>
            {this.renderLoading()}
          </div>
          <div className="cardBody">
            {rendered_controlPanel}
            {this.renderFilteredFields(field_group, black_list)}
            {this.renderContainers(field_group, black_list)}
          </div>
        </div>
      );
    });
  }

  /*
   ** Loop through an Array of fields, creating an Array of React Components
   ** based on the input_type (dropdown, date_select...). This Array will then
   ** be returned wrapped on a div.configuration_body.
   **
   ** @param {Array} - fields
   */
  getFields(fields) {
    let group_of_fields = [];
    for (let field of fields) {
      switch (field.input_type) {
        case input_type.DROPDOWN:
          group_of_fields.push(
            <Dropdown
              id={field.name}
              key={field.id}
              name={field.name}
              default_option={field.default_option}
              label={field.label}
              onChange={this.setScheduleDetails}
              endpoint={field.endpoint || null}
              loading={this.state.loading}
              method={field.method || null}
              options={field.opts}
              wrapperClass="beige-wrapper"
              value={field.value}
            />,
          );
          break;
        case input_type.INPUT:
          group_of_fields.push(
            <Input
              id={field.name}
              key={field.id}
              label={field.label}
              labelClass="block"
              default_option={field.default_option}
              name={field.name}
              type={field.input_type}
              wrapperClass="beige-wrapper"
              onBlur={this.setScheduleDetails}
              value={field.value}
            />,
          );
          break;
        case input_type.DATE_SELECT:
          group_of_fields.push(
            <DateSelect
              id={field.name}
              key={field.id}
              label={field.label}
              name={field.name}
              inputClass="input-date"
              onChange={this.setScheduleDetails}
              wrapperClass="beige-wrapper"
              value={field.value}
            />,
          );
          break;
        case input_type.CHECKBOX:
          group_of_fields.push(
            <Checkbox
              id={field.name}
              key={field.id}
              label={field.label}
              labelClass="filter-label"
              name={field.name}
              type={field.input_type}
              wrapperClass="beige-wrapper max-width checkbox-no-filter"
              onChange={this.setScheduleDetails}
              value={field.value}
            />,
          );
          break;
        default:
          group_of_fields.push(<h3>Field no supported!!!</h3>);
          break;
      }
    }
    return <div className="configuration-body">{group_of_fields}</div>;
  }

  /*
   ** When a change occurs on any field, this method is called to update the state.
   ** If an endpoint is passed we call the API passing this name and value pair as
   ** params.
   **
   ** @param {Object} - e
   ** @param {String} - endpoint
   ** @param {String} - method
   */
  setScheduleDetails(e, endpoint, method) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (endpoint && method) {
      let params = this.getParams();
      Object.assign(params, { [name]: value });
      this.callApi(params, endpoint, method);
    } else {
      this.setState(prevState => ({
        field_groups: this.updateFieldGroups(prevState.field_groups, name, value),
      }));
    }
  }

  /*
   ** Take field_groups and go through them until it finds a match,
   ** either by name or id. After a match is found we update it with
   ** the proper value.
   ** Note: Usually used when updating the state.
   **
   ** @param {Array}    - field_groups
   ** @param {String}   - name
   ** @param {anything} - value
   ** @param {Array}    - ids
   */
  updateFieldGroups(field_groups, name = null, value, ids = null) {
    for (let x = 0; x < field_groups.length; x++) {
      let array_fields = [];
      if (field_groups[x].fields) {
        array_fields = field_groups[x].fields;
      } else if (field_groups[x].containers) {
        for (let container of field_groups[x].containers) {
          array_fields = array_fields.concat(container.fields);
        }
      }

      for (let y = 0; y < array_fields.length; y++) {
        if (name && array_fields[y].name === name) {
          array_fields[y].value = value;
          //When the update is by name there's no need to keep looking once it's found
          break;
        }
        if (ids && ids.includes(array_fields[y].id)) {
          array_fields[y].value = value;
        }
      }
    }
    return field_groups;
  }

  /*
   ** Note: this messages would be better displayed with Form.js popup message
   */
  handleResponse(response) {
    this.setState({ loading: false }); //we stop the loading animation
    if (response.status === 200) {
      (response.msg || response.data.msg) &&
        this.setState({
          alert: {
            msg: response.msg || response.data.msg,
            class: 'success',
          },
        });
      return this.parseData(response.data);
    } else {
      response.errmsg &&
        this.setState({
          alert: {
            msg: response.errmsg,
            class: 'warning',
          },
        });
    }
  }

  /*
   ** Chooses the right API method to use based on the method provided.
   **
   ** @param {Object} - params
   ** @param {String} - endpoint
   ** @param {String} - method
   */
  callApi(params, endpoint, method, action = null) {
    switch (method.toUpperCase()) {
      case 'GET':
        this.setState({ loading: true });
        return get(params, `/${endpoint}`, 'Fetching schedule builder').then(response => this.handleResponse(response));
      case 'POST':
        this.setState({ loading: true });
        return post(params, `/${endpoint}`, action).then(response => this.handleResponse(response));
      default:
        console.error('no case for this method');
        break;
    }
  }

  /*
   ** Gather all the fields and build a params Object with the format of
   ** {"name":"value"}. Using field_groups (all the fields in the form) and
   ** filters to send to the back-end.
   */
  getParams() {
    const { field_groups, filters } = this.state;
    let params = {};
    //gather all the field's name and value
    for (let field_group of field_groups) {
      let array_fields = [];
      if (field_group.fields) {
        array_fields = field_group.fields;
      } else if (field_group.containers) {
        field_group.containers.map(container => {
          array_fields = array_fields.concat(container.fields);
          return container;
        });
      }
      //create 1 giant object of {name:value} pairs and save it in params
      array_fields.map(field => Object.assign(params, { [field.name]: field.value }));
    }
    //add all the filter's {name:value} to params
    filters.map(filter => Object.assign(params, { [filter.name]: filter.value }));

    return params;
  }

  /*
   ** @param {String} - action
   ** @param {String} - endpoint
   ** @param {String} - method
   */
  handleControlPanel(action, endpoint, method) {
    const params = this.getParams();
    this.callApi(params, endpoint, method, action);
  }

  /*
   ** Returns an Alert that lasts 3s (3000ms). Feel free to customize
   ** this with the alert_style object.
   **
   ** @param {Object} - alert
   */
  getAlert(alert) {
    //Alert only lasts 3 seconds
    setTimeout(() => this.setState({ alert: null }), 3000);
    return (
      <Alert className="alert-popup" variant={alert.class}>
        {/*<Glyphicon className="alert-icon" glyph="info-sign" />*/}
        {alert.msg}
      </Alert>
    );
  }

  render() {
    const { filters, black_list, field_groups, alert } = this.state;

    if (this.state.field_groups) {
      return (
        <div className="scheduleBuilder">
          <div className="App">
            {alert && this.getAlert(alert)}
            <form>
              {filters.length && this.getFilters(filters)}
              <div className="side-by-side-wrapper">{field_groups && this.getFormBoxes(field_groups, black_list)}</div>
            </form>
          </div>
        </div>
      );
    } else {
      return <Loading type="bars" color="#e3e3e3" />;
    }
  }
}
