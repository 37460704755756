//Generic style injector for css from the api. Only handles simple, single-nested json

export function injectStyles(json) {
  let rules = [];
  for (let i in json) {
    let rule = `.${i}{`;
    for (let j in json[i]) {
      rule += `${j}:${json[i][j]};`;
    }
    rule += `}`;
    rules.push(rule);
  }
  let injectedStyles = document.createElement('style');
  injectedStyles.setAttribute('type', 'text/css');
  injectedStyles.innerHTML = rules.join('');

  document.head.appendChild(injectedStyles);
}
