const initialState = {
  reports: null,
  status: {
    loading: false,
    loaded: false,
    err: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_REPORTS_FULFILLED':
      return {
        reports: action.payload,
        status: {
          loading: false,
          loaded: true,
          err: null,
        },
      };

    case 'LOAD_REPORTS_FAILED':
      return {
        reports: null,
        status: {
          loading: false,
          loaded: true,
          err: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
