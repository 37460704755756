import React from 'react';
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { fetchManagerConfig } from '../actions/API';

export default class Manager extends React.Component {
  componentDidMount() {
    fetchManagerConfig()
      .then(response => {
        this.setState({
          config: response.config,
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loaded: true,
          err: true,
          err_msg: err,
          config: null,
        });
      });
  }

  render() {
    return (
      <div className="topPaddingMd">
        <Row>
          <Col xs={12}>
            <ButtonToolbar>{this.state && this.state.config && this.renderMenu(this.state.config.menu)}</ButtonToolbar>
          </Col>
        </Row>
      </div>
    );
  }

  renderMenu = menu => {
    let options = [];
    let that = this;
    for (let key in menu) {
      if (menu.hasOwnProperty(key)) {
        options.push(
          <Button key={key} onClick={() => this.handleClick(menu[key].label)}>
            {menu[key].label}
          </Button>,
        );
      }
    }
    return options;
  };

  handleClick = (btn, e) => {
    e.preventDefault();
  };
}
