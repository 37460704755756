const initialState = {
  shifts: null,
  duties: null,
  signups: null,
  exchanges: null,
  events: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //TODO: make less ugly
    case 'STORE_PRIVS':
      return {
        ...state,
        events: {
          ...state.events,
          ...action.payload.events,
        },
        shifts: {
          ...state.shifts,
          ...action.payload.shifts,
        },
        signups: {
          ...state.signups,
          ...action.payload.signups,
        },
        exchanges: {
          ...state.exchanges,
          ...action.payload.exchanges,
        },
        duties: {
          ...state.duties,
          ...action.payload.duties,
        },
      };
    default:
      return state;
  }
};

export default reducer;
