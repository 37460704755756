export const SIMPLE_EDITOR = 'simple_editor';
export const FULL_EDITOR = 'full_editor';
export const FORM = 'form';
export const USER_STATS = 'user_stats';
export const USER_SCHEDULE = 'user_schedule';
export const COMMAND_PANEL = 'operations';
export const HTML = 'html';
export const RAW_HTML = 'rawhtml';
export const DASHBOARD = 'dashboard';
export const CALENDAR = 'calendar';
export const REPORTS = 'report_builder';
export const REPORTS2 = 'reports2';
export const SETTINGS = 'settings';
export const MANAGER = 'manager';
export const DAY = 'day';
export const DAYVIEW = 'dayview';
export const API_MAP = 'apimap';
export const RECORDS = 'records';
export const OPERATIONS_BOARD = 'operations_board';
