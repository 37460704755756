import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import AppContainer from './containers/AppContainer';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faEnvelope,
  faEye,
  faInfoCircle,
  faPlusCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faQuestionCircle,
  faTools,
  faWrench,
  faExchangeAlt,
  faThumbsUp,
  faThumbsDown,
  faTrashAlt,
  faClock,
  faStar,
  faBalanceScale,
  faRandom,
  faSave,
  faSearchPlus,
  faSearchMinus,
  faUser,
  faTasks,
  faTags,
  faAsterisk,
  faBullhorn,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faCalendarWeek,
  faCalendarPlus,
  faSyncAlt,
  faThumbtack,
  faCheck,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faMinus,
  faLock,
  faListAlt,
  faInbox,
  faHourglassHalf,
  faHandPointUp,
  faHandPointDown,
  faHandPointLeft,
  faHandPointRight,
  faComment,
  faCertificate,
  faCloud,
  faCogs,
  faTachometerAlt,
  faDownload,
  faFileDownload,
  faCloudDownloadAlt,
  faFileAlt,
  faFire,
  faFilter,
  faBolt,
  faFlag,
  faFolder,
  faFolderOpen,
  faChartBar,
  faUserCog,
  faIdCard,
  faTimes,
  faExpand,
  faTimesCircle,
  faCog,
  faMoneyCheckDollar,
  faToolbox,
  faScrewdriver,
  faTableColumns,
  faGaugeSimple,
  faGaugeSimpleHigh,
  faGaugeHigh,
  faGear,
  faGears,
  faSliders,
  faListCheck,
  faEllipsisVertical,
  faEllipsis,
  faBarsProgress,
  faCalendarDays,
  faClipboardList,
  faCalendarXmark,
  faCalendarMinus,
  faCalendarDay,
  faCalendarCheck,
  faFileInvoice,
  faCommentDollar,
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import configureStore from './store/configureStore';
import './styles/styles.css';
import './styles/netduty.scss';
import './styles/bootstrap.css';
import theme from './theme';

// Add your FontAwesome Icons here
library.add(
  faAngleDoubleUp,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faExpand,
  faTimes,
  faTimesCircle,
  faIdCard,
  faUserCog,
  faChartBar,
  faEdit,
  faEnvelope,
  faEye,
  faInfoCircle,
  faPlusCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faQuestionCircle,
  faTools,
  faWrench,
  faExchangeAlt,
  faThumbsUp,
  faThumbsDown,
  faTrashAlt,
  faClock,
  faStar,
  faStarEmpty,
  faBalanceScale,
  faRandom,
  faSave,
  faSearchPlus,
  faSearchMinus,
  faUser,
  faTasks,
  faTags,
  faAsterisk,
  faBullseye,
  faBullhorn,
  faCalendar,
  faCalendarAlt,
  faCalendarWeek,
  faCalendarPlus,
  faSyncAlt,
  faThumbtack,
  faCheck,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faMinus,
  faLock,
  faListAlt,
  faInbox,
  faHourglassHalf,
  faHandPointUp,
  faHandPointDown,
  faHandPointLeft,
  faHandPointRight,
  faComment,
  faCertificate,
  faCloud,
  faCogs,
  faCog,
  faTachometerAlt,
  faDownload,
  faFileDownload,
  faCloudDownloadAlt,
  faFileAlt,
  faFire,
  faFilter,
  faBolt,
  faFlag,
  faFolder,
  faFolderOpen,
  faMoneyCheckDollar,
  faToolbox,
  faScrewdriver,
  faTableColumns,
  faGaugeSimple,
  faGaugeSimpleHigh,
  faGaugeHigh,
  faGear,
  faGears,
  faSliders,
  faListCheck,
  faEllipsisVertical,
  faEllipsis,
  faBarsProgress,
  faCalendarDays,
  faClipboardList,
  faCalendarXmark,
  faCalendarMinus,
  faCalendarDay,
  faCalendarCheck,
  faFileInvoice,
  faCommentDollar,
);

const store = configureStore();
const APP_CONTAINER = document.getElementById('app');
const ROOT = ReactDOM.createRoot(APP_CONTAINER);
ROOT.render(
  <Provider store={store}>
    <Router basename="/">
      <ThemeProvider theme={theme}>
        <AppContainer />
      </ThemeProvider>
    </Router>
  </Provider>,
);
