import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CustomToolTip from '../Common/CustomToolTip';


const labelStyle = {
    fontWeight: 'bold',
    color: 'black',
    marginTop: '10px',
    marginRight: '5px',
    marginBottom: 0,
    position: 'static',
};

export default function MuiRadioButton({ 
	label, 
    icon,
    iconClass,
	name, 
	options = [], 
	value, onChange, 
	row = true, 
	hovertext }) {
  const [localValue, setLocalValue] = React.useState(value);

  const handleChange = event => {
    const newValue = event.target.value;
    setLocalValue(newValue);
    onChange(newValue);
  };

  return (
    <FormControl component="fieldset">
      <abbr title={hovertext}>
        <FormLabel sx={labelStyle}>{label}
        {icon && <CustomToolTip sx={{ marginLeft: '5px' }} icon={icon} iconClass={iconClass} /> }
      </FormLabel>
        <RadioGroup name={name} value={localValue} onChange={handleChange} row={row}>
          {(options ?? []).map(opt => (
            <FormControlLabel
              sx={{ ml: 0 }}
              key={opt.id}
              value={opt.id}
              disabled={opt.disabled}
              control={<Radio color="primary" />}
              label={opt.label}
            />
          ))}
        </RadioGroup>
      </abbr>
    </FormControl>
  );
}
