import React from 'react';
import Router from './Router';
import { Button } from 'react-bootstrap';
import CustomToolTip from '../Common/CustomToolTip';
import Buttonbar from '../Common/Buttonbar';
import Ribbon from '../Common/Ribbon';
import classNames from 'classnames';

// a helper component so that each tab can manage it's own visible state without remounting
class TabContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.state.visible) {
      this.setState({
        visible: !this.state.visible,
      });
    }
  }

  render() {
    const hiddenClass = this.state.visible ? '' : ' hidden';
    return <div className={classNames('tabContent', hiddenClass)}>{this.props.children}</div>;
  }
}

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);

    // if an integer selectedID is specified in the JSON then display that tab, otherwise default to 0 for top level tabs, or null for all others
    let defaultSelected = this.props.nested ? null : 0;
    this.state = {
      selectedID: this.props.selectedID || this.props.selectedID === 0 ? this.props.selectedID : defaultSelected,
      tabs: null,
      loadedTabs: [],
    };
  }

  componentDidMount() {
    // if these aren't nested tabs, push them to the header to be displayed there
    if (!this.props.nested && this.props.jsonTabs) {
      this.updateTabs(this.props.jsonTabs);
    }
    if (this.props.nested && this.props.tabs) {
      this.setLoadedTabs(this.props.tabs);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.jsonTabs &&
      (!prevProps.jsonTabs || prevProps.jsonTabs.length !== this.props.jsonTabs.length) &&
      !this.props.nested
    ) {
      //TODO: this is hacky, actually compare the tabs
      this.updateTabs(this.props.jsonTabs);
    }
  }

  setLoadedTabs(tabs) {
    this.setState({
      tabs: tabs,
    });
  }

  // parse JSON tabs, format them for the header and send them through, set them
  // for this component
  updateTabs(jsonTabs) {
    const pTabs = this.parseTabs(jsonTabs);
    this.props.globalActions.storeHeaderTabs(this.formatTabsForHeader(pTabs));
    this.setLoadedTabs(pTabs);
  }

  parseTabs(jsonTabs) {
    let tabs = [];
    for (let i = 0; i < jsonTabs.length; i++) {
      let jsonTab = jsonTabs[i];

      // for now we are not mutating, just passing along the data we are given.
      // keeping this function so that only this one function is reliant on JSON format
      let tab = {
        label: jsonTab.label,
        endpoint: jsonTab.endpoint,
        method: jsonTab.method || 'GET',
        variables: jsonTab.variables || {},
        buttonbar: jsonTab.buttonbar || jsonTab.lbuttonbar,
        class: jsonTab.class,
        icon: jsonTab.icon,
        config: jsonTab.config,
        ribbon: jsonTab.ribbon,
        iconClass: jsonTab.iconClass,
        selectedID: jsonTab.selectedIndex,
        nestedTabs: jsonTab.topmenu ? this.parseTabs(jsonTab.topmenu) : null,
        endpoint_type: jsonTab.endpoint_type,
        maintainState: true,
        mountCount: 0,
      };
      tabs.push(tab);
    }
    return tabs;
  }

  //create an array of objects representing tabs in a format consumable by the
  // header TabNavigation
  formatTabsForHeader(tabs) {
    let formattedTabs = [];
    if (tabs) {
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        formattedTabs.push({
          active: i === 0,
          name: tab.label,
          id: i,
          onClick: this.changeTabFunc(i).bind(this),
        });
      }
    }
    return formattedTabs;
  }

  //create a callback function to set the selected tab to a given index
  changeTabFunc = index => {
    let changeTab = function () {
      if (index === this.state.selectedID) {
        // refresh tab -- we are forcing this by bumping the tab content's key,
        // forcing it to update.
        // TODO: think of cleaner way to do this, consider passing a callback function
        // to router
        let newTabs = this.state.tabs;
        let newTab = this.state.tabs[index];
        newTab.mountCount += 1;
        newTabs[index] = newTab;
        this.setState({
          tabs: newTabs,
        });
      } else {
        let newLoadedTabs = this.state.loadedTabs;
        newLoadedTabs[index] = true;
        this.setState({
          selectedID: index,
          loadedTabs: newLoadedTabs,
        });
      }
    };
    return changeTab;
  };

  //get the content to display for each tab
  //for now we just show the url we will eventually pull from, with the exception of the object editors
  pullContent(tab) {
    //TODO: Implement the handleButtonbar function to bring more functionality here
    if (tab.buttonbar || tab.lbuttonbar) {
      return (
        <Buttonbar
          buttonbar={tab.buttonbar || tab.lbuttonbar}
          handleButtonbar={() => console.log('handle Buttonbar - not yet implemented')}
        />
      );
    }
    if (tab.ribbon) {
      return <Ribbon data={tab.ribbon} globalActions={this.props.globalActions} history={this.props.history}/>;
    } else if (tab.nestedTabs) {
      //if the tab already has content, use that
      return (
        <Tabs
          key={tab.label}
          tabs={tab.nestedTabs}
          headerTabs={this.props.headerTabs}
          selectedID={tab.selectedID}
          globalActions={this.props.globalActions}
          nested={true}
        />
      );
    } else {
      return (
        <Router
          key={tab.label + tab.selectedID + tab.mountCount}
          content={tab}
          tabs={this.props.headerTabs}
          globalActions={this.props.globalActions}
          history={this.props.history}

        />
      );
    }
  }

  render() {
    //get the labels for this set of tabs
    let labels = [];
    let renderedTabs = [];
    const { tabs } = this.state;
    if (tabs && tabs.length) {
      //TODO: why did I have to add this when merging?
      for (let i = 0; i < tabs.length; i++) {
        let tab = tabs[i];
        const selectedClass = this.state.selectedID === i ? ' selected' : '';
        let icon = null;
        if (tab.icon) {
          icon = (
            <div style={{ marginRight: '2px', display: 'inline' }}>
              <CustomToolTip icon={tab.icon} iconClass={tab.iconClass} />
            </div>
          );
        }
        labels.push(
          <Button
            key={'tab' + i + tab.label}
            bsSize="small"
            className={classNames('nd-button tab ', tab.class, selectedClass)}
            onClick={this.changeTabFunc(i)}
          >
            {icon}
            {tab.label}
          </Button>,
        );

        //create list of rendered tabs
        // this list will contain all tabs which have already been visited, hidden,
        // in addition to one visible tab (the currently selected one)
        if ((tab.maintainState && this.state.loadedTabs[i]) || this.state.selectedID === i) {
          renderedTabs.push(
            <TabContent
              headerTabs={this.props.headerTabs}
              label={tab.label}
              visible={this.state.selectedID === i}
              key={'tabContent' + tab.label + i}
            >
              {this.pullContent(tab)}
            </TabContent>,
          );
        }
      }
    }

    //only show the tab labels here if they are nested tabs. Otherwise,
    //they will be displayed in the header
    let labelArea = this.props.nested ? <div className="tabLabels">{labels}</div> : null;
    return (
      <div className="tabArea">
        {labelArea}
        {renderedTabs}
      </div>
    );
  }
}
