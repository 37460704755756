import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Buttonbar from '../Common/Buttonbar';
import CustomToolTip from '../Common/CustomToolTip';

export default function MuiCheckbox({
  label,
  icon,
  iconClass,
  name,
  value,
  selected = false,
  autoComplete,
  onChange,
  hovertext,
  buttonbar,
}) {
  // Todo:
  // - Create more Checkbox colors
  const [localValue, setLocalValue] = React.useState(!!value);
  const handleChange = () => {
    const newValue = localValue ? 0 : 1; // Toggle value
    onChange(newValue);
    setLocalValue(newValue);
  };
  const labelStyle = icon ? { marginLeft: '0',marginRight: '5px'} : {marginLeft: '0'}; 

  return (
    <abbr title={hovertext}>
      <FormControlLabel
        sx={labelStyle}
        label={
          <div style={{display: 'flex'}}>
            <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
            {buttonbar && <Buttonbar buttonbar={buttonbar} />}
          </div>
        }
        control={
          <Checkbox
            checked={localValue}
            onChange={handleChange}
            name={name}
            autoComplete={autoComplete}
            icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
            checkedIcon={<CheckBoxIcon fontSize="large" />}
            sx={{ mr: '2px' }}
          />
        }
      />
      {icon && <CustomToolTip icon={icon} iconClass={iconClass} /> }
    </abbr>
  );
}
