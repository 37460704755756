import React from 'react';
import { Link } from 'react-router-dom';

//This is the link to (or from) the admin site to be displayed in the header bar
export default class AdminLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let text = this.props.path.pathname.includes('admin') ? 'User' : 'Admin';
    let href = this.props.path.pathname.includes('admin') ? '/user/dashboard' : '/admin/dashboard';
    return (
      <Link className="adminLink" to={href}>
        {text}
      </Link>
    );
  }
}
