import React from 'react';
import Duty from './Duty';

export default class Duties extends React.Component {
  render() {
    // console.log("Duties rendering with : " );
    // console.log(this.props);
    const that = this;
    return (
      <div className="duties">
        {this.props.data &&
          this.props.data &&
          this.props.data.length > 0 &&
          this.props.data.map(function (duty, key) {
            return (
              <Duty
                data={duty}
                key={key}
                day={that.props.day}
                actions={that.props.actions}
                showHideable={that.props.showHideable}
              />
            );
          })}
        {!this.props.data && <p>No duties</p>}
      </div>
    );
  }
}
