export const TEXT_AREA = 'textarea';
export const TEXT_FIELD = 'input';
export const INPUT_MASK = 'input_mask';
export const DROPDOWN = 'dropdown';
export const DROPDOWN_MULTI = 'dropdown_multi';
export const CHECKBOX = 'checkbox';
export const TIME_SELECT = 'time_select';
export const HIDDEN = 'hidden';
export const DATE_SELECT = 'date_select';
export const DATE_RANGE = 'date_range';
export const DATE_TIME = 'datetime';
export const HTML = 'html';
export const RADIO = 'radio';
export const PASSWORD = 'password';
export const HEADER = 'header';
export const MODAL = "modal";
