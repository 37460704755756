import React from 'react';
import { FormControl } from 'react-bootstrap';
import moment from 'moment';

export default class ShiftTimes extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.props.handleInputChange.bind(this);
    this.handleLocalChange = this.handleLocalChange.bind(this);
  }

  componentWillMount() {
    let h = moment(this.props.default);
    let m = h.format('mm');
    this.setState({
      hour: h,
      minute: m,
    });
  }

  render() {
    let options = [];
    // console.log(this.props);
    let timeStart = moment(this.props.times.range.start);
    let timeEnd = moment(this.props.times.range.end);

    while (timeEnd > timeStart) {
      options.push(
        <option value={timeStart.format('YYYY-MM-DD HH:mm:ss')} key={timeStart}>
          {timeStart.format('ddd HH')}
        </option>,
      );
      // console.log(timeStart.format('YYYY-MM-DD HH:mm:ss'));
      timeStart.add(1, 'hour');
    }

    if (this.state) {
      // console.log('shift time:');
      // console.log(this.props);
      // console.log(this.state);
      // console.log('default value is ' + moment(this.props.default).format('YYYY-MM-DD HH:mm:ss'));
      return (
        <div className="form-inline shiftTimes">
          <p className="timeSelectLabel">{this.props.label}</p>
          <FormControl
            componentClass="select"
            className="formControlSelect"
            defaultValue={moment(this.props.default).format('YYYY-MM-DD HH:00:00')}
            placeholder="select"
            onChange={this.setHour.bind(this)}
            ref="hour"
          >
            {options}
          </FormControl>
          <FormControl
            componentClass="select"
            className="formControlSelect"
            defaultValue={this.state.minute}
            placeholder="select"
            onChange={this.setMinute.bind(this)}
            ref="minute"
          >
            <option value="00" key="00">
              00
            </option>
            <option value="15" key="15">
              15
            </option>
            <option value="30" key="30">
              30
            </option>
            <option value="45" key="45">
              45
            </option>
          </FormControl>
        </div>
      );
    } else {
      return null;
    }
  }

  setHour(val) {
    let h = moment(val.target.value);
    // console.log('hour: ', h.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('minute', this.state.minute);
    // console.log('new time : ', h.format('YYYY-MM-DD HH:' + this.state.minute));
    this.setState({ hour: h });
    this.handleLocalChange(h.format('YYYY-MM-DD HH:' + this.state.minute));
  }

  setMinute(val) {
    // console.log('hour: ', this.state.hour.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('minute', val.target.value);
    // console.log('new time : ', this.state.hour.format('YYYY-MM-DD HH:' + val.target.value));
    this.setState({ minute: val.target.value });
    this.handleLocalChange(this.state.hour.format('YYYY-MM-DD HH:' + val.target.value));
  }

  handleLocalChange(timeStr) {
    //conforms outputs to the name/event pair handleInputChange expects
    this.handleInputChange(this.props.name, {
      target: {
        type: 'shiftTime',
        value: timeStr,
      },
    });
  }
}
