import React from 'react';
import { Row, FormGroup, Form, Col, FormLabel, FormControl } from 'react-bootstrap';
import { fetchCreateCalloutConfig } from '../../../actions/API';
import ShiftTimes from '../../Common/ShiftTimes';

export default class CalloutRequest extends React.Component {
  constructor() {
    super();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      loaded: false,
      loading: false,
    };
  }

  componentDidMount() {
    fetchCreateCalloutConfig({ duty_id: this.props.dutyID, dutydate: this.props.day })
      .then(response => {
        this.setState({
          loaded: true,
          loading: false,
          err: null,
          data: this.parseCalloutJson(response.data),
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loaded: true,
          err: true,
          err_msg: err,
        });
      });
  }

  parseCalloutJson(calloutJSON) {
    let data = {
      times: {
        range: {
          start: calloutJSON.data.start_date,
          end: calloutJSON.data.end_date,
        },
      },
      roster: calloutJSON.config.roster_selection,
    };
    return data;
  }

  render() {
    if (this.state && this.state.loaded && !this.state.err) {
      return (
        <Row>
          <Col xs={6}>
            <br />
            <Form horizontal onSubmit={this.handleFormSubmit}>
              <FormGroup controlId="formHorizontalEmail">
                <Col componentClass={FormLabel} xs={3}>
                  Importance
                </Col>
                <Col xs={9}>
                  <FormGroup>
                    <Form.Check
                      type="radio"
                      name="importance"
                      key="low"
                      onClick={this.handleInputChange.bind(this, 'low')}
                      inline
                    >
                      Low
                    </Form.Check>{' '}
                    <Form.Check
                      type="radio"
                      name="importance"
                      key="medium"
                      onClick={this.handleInputChange.bind(this, 'medium')}
                      inline
                    >
                      Medium
                    </Form.Check>{' '}
                    <Form.Check
                      type="radio"
                      name="importance"
                      key="high"
                      onClick={this.handleInputChange.bind(this, 'high')}
                      inline
                    >
                      High
                    </Form.Check>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup controlId="formHorizontalEmail">
                <Col componentClass={FormLabel} xs={3}>
                  Shift Times
                </Col>
              </FormGroup>

              <FormGroup controlId="formHorizontalEmail">
                <Col componentClass={FormLabel} xs={3} />
                <Col xs={9}>
                  Start:{' '}
                  <ShiftTimes
                    times={this.state.data.times}
                    default={this.state.data.times.range.start}
                    handleInputChange={this.handleShiftStartChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup controlId="formHorizontalEmail">
                <Col componentClass={FormLabel} xs={3} />
                <Col xs={9}>
                  End:&nbsp;{' '}
                  <ShiftTimes
                    times={this.state.data.times}
                    default={this.state.data.times.range.end}
                    handleInputChange={this.handleShiftEndChange}
                  />
                </Col>
              </FormGroup>

              <FormGroup controlId="formHorizontalEmail">
                <Col componentClass={FormLabel} xs={3}>
                  Shift Notes
                </Col>
                <Col xs={9}>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Enter notes here..."
                    onChange={this.handleInputChange}
                    name="shiftNotes"
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={5}>
            <Row>
              <h5>Groups:</h5>
              <p>No groups.</p>
              <hr />
            </Row>
            <Row>
              <FormGroup>{this.renderRosterSelection()}</FormGroup>
            </Row>
          </Col>
        </Row>
      );
    } else {
      return <h1>...</h1>;
    }
  }

  renderRosterSelection() {
    let options = [];
    //TODO:do
    //TODO: there is no reason for this to be an object instead of an array.
    // Make a parser for this in case Allen fixes in the future
    const roster = this.state.data.roster;
    for (let user in roster) {
      if (roster.hasOwnProperty(user)) {
        options.push(
          <Form.Check type="checkbox" key={user} value={user} defaultChecked={roster[user].selected}>
            {roster[user].label}
          </Form.Check>,
        );
      }
    }
    return options;
  }

  handleClick(btn, e) {
    e.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleShiftStartChange(t) {
    // console.log(t);
    let f = this.state.form;
    f.shiftStart = t._d;
    this.setState({ form: f }, this.checkShiftCreateValid);
  }

  handleShiftEndChange(t) {
    let f = this.state.form;
    f.shiftEnd = t._d;
    this.setState({ form: f }, this.checkShiftCreateValid);
  }
}
