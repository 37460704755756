import React from 'react';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import { dynamicOEButtonCall } from '../../actions/API';
import CustomToolTip from './CustomToolTip';
import Buttonbar from './Buttonbar';
import Records from './Records';
import OperationsBoard from '../OperationsBoard/OperationsBoard';
import { get } from '../../actions/REST';
import Form from './Form';
import getSizeInPixel from '../../constants/boxSizes';
import classNames from 'classnames';

const dialogTypes = {
  FORM: 'form',
  TABS: 'tabs',
  HTML: 'html',
  RECORDS: 'records',
  COMBO: 'combo',
  BUTTONBAR: 'buttonbar',
  OPERATIONS_BOARD: 'operations_board',
};

export default class DialogBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimized: false,
      boxHeader: {
        title: '',
        headerClass: '',
        icon: null,
        iconClass: '',
      },
      bodyStyle: {},
      boxData: null,
      tabsHeader: [],
      tabsContent: [],
      showTabContent: null,
      refreshOnClose: false,
      width: null,
      boxStyle: null,
    };
    this.switchTab = this.switchTab.bind(this);
    this.toggleMinized = this.toggleMinized.bind(this);
    this.setRefreshOnClose = this.setRefreshOnClose.bind(this);
    this.onEscapePressed = this.onEscapePressed.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.data && this.validateProps(this.props);
    window.addEventListener('keyup', this.onEscapePressed);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('keyup', this.onEscapePressed);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.data) {
      //To make DialogBox dissapear before new values are set.
      //this.setState({boxData: null});
      this.validateProps(this.props);
    }
  }

  onEscapePressed(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.props.onClose();
    }
  }

  validateProps(props) {
    //if there's an endpoint pull the data from API
    if (props.data && props.data.endpoint) {
      this.callApi(props.data.endpoint, props.data.variables, props.id, props.selected_ids);
    } else {
      props.data && this.parseData(props.data);
    }
  }

  parseData(data) {
    let tabsHeader = [];
    let tabsContent = [];
    let showTabContent = null;
    let title = data.config ? data.config.label : '';
    let icon = data.config ? data.config.icon : null;
    let headerClass = data.config ? data.config.class : '';
    let iconClass = data.config ? data.config.iconClass : '';
    let verticalSize = data.config ? data.config.maxHeight : null;
    let height = data.config ? data.config.height : null;
    let horizontalSize = data.config ? data.config.width : null;

    //Special case for DialogType TABS
    if (this.props.dialog_type === dialogTypes.TABS) {
      for (let tab of data.config.tabs) {
        tabsHeader.push({
          id: tab.id,
          label: tab.label,
        });
        tabsContent.push({
          id: tab.id,
          data: tab.form || tab.data || tab.rows || null,
          type: tab.type,
        });
      }
      //Choosing the default Tab
      if (data.config.default_tab) {
        showTabContent = data.config.default_tab;
      } else {
        //If there's no default_tab specified we open the first one
        showTabContent = tabsHeader.length > 0 ? tabsHeader[0].id : null;
      }
    }
    //Special case for DialogType Combo
    if (this.props.dialog_type === dialogTypes.COMBO) {
      title = data.data.config ? data.data.config.label : '';
      icon = data.data.config ? data.data.config.icon : null;
      headerClass = data.data.config ? data.data.config.class : '';
      iconClass = data.data.config ? data.data.config.iconClass : '';
      verticalSize = data.data.config ? data.data.config.maxHeight : null;
      height = data.data.config ? data.data.config.height : null;
      horizontalSize = data.data.config ? data.data.config.width : null;
    }

    /* If the back-end sends a maximum height to the DialogBox we create the
     ** bodyStyle object with overflow-y "auto" and overflow-x "hidden" so we only
     ** have a vertical scrollbar. */
    let bodyStyle = {};
    let maxHeight = getSizeInPixel(verticalSize);
    let width = getSizeInPixel(horizontalSize);
    let boxStyle = {};
    height = height ? getSizeInPixel(height) : null;
    if (isMobile) {
      maxHeight = window.innerHeight - 70 + 'px';
    }
    if (maxHeight) {
      bodyStyle = {
        maxHeight: parseInt(maxHeight.split('px')[0]) > window.innerHeight ? `${window.innerHeight - 40}px` : maxHeight,
        overflowY: 'auto',
        overflowX: 'hidden',
      };
    }
    if (maxHeight && parseInt(maxHeight.split('px')[0]) > window.innerHeight) {
      boxStyle = {
        top: 0,
      };
    }
    this._isMounted &&
      this.setState({
        boxData: data,
        boxStyle,
        boxHeader: {
          title,
          headerClass,
          icon,
          iconClass,
        },
        bodyStyle,
        width,
        height,
        tabsHeader,
        tabsContent,
        showTabContent: this.state.showTabContent || showTabContent,
      });
  }

  handleButton(button) {
    const variables = button.variables || {};
    if (button.endpoint && button.method) {
      dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables).then(response => {
        this.props.openDialog(response, this.handleButton.bind(this, button));
        if (response.status >= 200 && response.status < 300) {
          this.props.onClose();
          this.props.onSuccess();
        }
      });
    }
  }

  setRefreshOnClose() {
    this.setState({
      refreshOnClose: true,
    });
  }

  switchTab(id) {
    this.setState({ showTabContent: id });
  }

  renderTabsHeader(tabsHeader, showTabContent) {
    const tabs = tabsHeader.map(header => (
      <li
        key={header.id}
        className={`single-tab-header ${header.id === showTabContent ? 'active' : ''}`}
        onClick={() => this.switchTab(header.id)}
      >
        {header.label}
      </li>
    ));
    return <ul className="nav nav-tabs">{tabs}</ul>;
  }

  renderHTML(html) {
    if (!html) {
      console.warn('wrong format - html');
      return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  /*
   ** param {Array} - tabsContent
   */
  renderTabContent(tabsContent, showTabContent) {
    return tabsContent.map(content => {
      let visibility = content.id === showTabContent ? {} : { display: 'none' };
      return (
        <div key={content.id} className="single-tab-body" style={visibility}>
          {content && this.getBody(content, content.type)}
        </div>
      );
    });
  }

  renderTabs() {
    const { tabsHeader, tabsContent, showTabContent, boxHeader } = this.state;
    return (
      <div>
        <div className={classNames('tabs-header', boxHeader.headerClass)}>
          {this.renderTabsHeader(tabsHeader, showTabContent)}
        </div>
        <div className="tabs-content">{this.renderTabContent(tabsContent, showTabContent)}</div>
      </div>
    );
  }

  renderForm(data) {
    if (!data) {
      console.warn('wrong format - form');
      return null;
    }
    return (
      <Form
        key={'form' + data.id}
        formData={data}
        standAlone={false}
        variables={this.props.variables}
        openDialog={this.props.openDialog}
        setRefreshOnClose={this.setRefreshOnClose}
        fromDialogBox={true}
        success={(response, shouldRefresh, refresh_page) => {
          if (response.status >= 200 && response.status < 300) {
            this.props.onClose && this.props.onClose();
            this.props.onSuccess && this.props.onSuccess();
            refresh_page && this.props.refreshPage && this.props.refreshPage();
          }
        }}
      />
    );
  }

  renderRecords(data) {
    if (!data) {
      console.warn('wrong format - records');
      return null;
    }
    let rendered_records = [];
    if (Array.isArray(data) && data.length > 0) {
      rendered_records = <Records records={data} handleButton={this.handleButton.bind(this)} />;
    }

    return <div className="records">{rendered_records}</div>;
  }

  renderCombo(data) {
    if (!data) {
      console.warn('wrong format - combo data');
      return null;
    }
    if (!data.rows) {
      console.warn('wrong format - combo rows');
      return null;
    }
    let rendered_rows = [];
    for (let row of data.rows) {
      rendered_rows.push(<div key={row.id}>{this.getBody(row, row.config ? row.config.type : row.type)}</div>);
    }
    return rendered_rows;
  }

  renderButtonbar(buttonbar) {
    if (!buttonbar) {
      console.warn('wrong format - buttonbar');
      return null;
    }
    return <Buttonbar key={buttonbar.id} buttonbar={buttonbar} handleButton={this.handleButton.bind(this)} />;
  }
  // PJW 14Mar23: Include simplified operations board as a dialog
  // box to allow drag and drop operation.
  refreshOperationsBoard() {
    if (this.props.controller.endpoint) {
      let params = this.props.controller.variables || {};
      get(params, `/${this.props.controller.endpoint}`, 'Fetching data for DialogBox')
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            if (response.data && (response.data.config || (response.data.data && response.data.data.config))) {
              this.setState({
                boxData: response.data,
              });
            } else {
              console.warn('Operations Board Reload: No API Dialog Response or DialogBox sent from back-end');
            }
          } else {
            console.warn('Operations Board Reload failed');
          }
        })
        .catch(err => {
          console.warn('Operations Board Reload get exception');
        });
    }
  }

  renderOperationsBoard(data) {
    if (!data.data) {
      console.warn('wrong format - OperationsBoard');
      return null;
    }
    return <OperationsBoard updateRibbon={null} data={data} refreshObjects={this.refreshOperationsBoard.bind(this)} />;
  }

  getBody(boxData, dialog_type) {
    switch (dialog_type) {
      case dialogTypes.TABS:
        return this.renderTabs();
      case dialogTypes.FORM:
        return this.renderForm(boxData.form || boxData.data);
      case dialogTypes.HTML:
        return this.renderHTML(boxData.data);
      case dialogTypes.RECORDS:
        return this.renderRecords(boxData.data);
      case dialogTypes.COMBO:
        return this.renderCombo(boxData.data);
      case dialogTypes.BUTTONBAR:
        return this.renderButtonbar(boxData.data);
      case dialogTypes.OPERATIONS_BOARD:
        return this.renderOperationsBoard(boxData);

      default:
        return <div key="not_supported">Not supported yet!</div>;
    }
  }

  renderControllers() {
    const { minimized, boxHeader } = this.state;

    const button_class = minimized ? 'maximize-button' : 'minimize-button';
    const icon = minimized ? 'expand' : 'minus';

    return (
      <div className="box-controllers">
        <div className="header-icon">
          <CustomToolTip icon={boxHeader.icon} iconClass={boxHeader.iconClass} />
        </div>
        <span className="dialogbox-title">{boxHeader.title}</span>
        <div className="controllers-right">
          <IconButton
            className={classNames('controller', button_class)}
            aria-label={minimized ? 'minimize' : 'maximize'}
            onClick={this.toggleMinized}
            size="small"
          >
            <FontAwesomeIcon icon={icon} color="#fff" />
          </IconButton>
          <IconButton
            className="controller close-button"
            aria-label="close"
            size="small"
            onClick={() => {
              this.state.refreshOnClose && this.props.onSuccess();
              this.props.onClose();
            }}
          >
            <FontAwesomeIcon icon="times" color="#fff" />
          </IconButton>
        </div>
      </div>
    );
  }

  toggleMinized() {
    this.setState(prevState => ({
      minimized: !prevState.minimized,
    }));
  }

  /** DRAGGABLE HANDLERS **/
  onStart(e) {
    e.stopPropagation();
  }
  /** END OF DRAGGABLE HANDLERS **/

  render() {
    const { minimized, boxData } = this.state;
    const box_body_style = !minimized ? {} : { display: 'none' };
    let style = {};
    if (this.props.defaultPosition) {
      style = Object.assign({}, this.props.defaultPosition);
    }
    if (this.state.width && !isMobile) {
      style = Object.assign({}, style, { minWidth: this.state.width });
    }
    if (this.state.height && !minimized) {
      style = Object.assign({}, style, { minHeight: this.state.height });
    }
    if (this.state.boxStyle) {
      style = Object.assign({}, style, this.state.boxStyle);
    }

    if (boxData) {
      if (isMobile) {
        return (
          <div onStart={this.onStart.bind(this)} handle=".box-header" style={style}>
            <div
              className="dialog-box is-mobile"
              style={
                minimized
                  ? Object.assign({}, style, {
                      height: 'auto',
                    })
                  : style
              }
            >
              <div className={'box-header ' + this.state.boxHeader.headerClass}>{this.renderControllers()}</div>
              <div className="box-body" style={this.state.bodyStyle} onScroll={e => e.stopPropagation()}>
                <div style={minimized ? Object.assign({}, box_body_style, { minHeight: 0 }) : box_body_style}>
                  {this.getBody(boxData, this.props.dialog_type)}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
          if ( this.props.dialog_type === dialogTypes.OPERATIONS_BOARD ) {
            return (
              <div onStart={this.onStart.bind(this)} handle=".box-header" style={style}>
                <div
                  className="dialog-box"
                  style={
                    minimized
                      ? Object.assign({}, style, {
                          height: 'auto',
                        })
                      : style
                  }
                >
                  <div className={'box-header ' + this.state.boxHeader.headerClass}>{this.renderControllers()}</div>
                  <div className="box-body" style={this.state.bodyStyle} onScroll={e => e.stopPropagation()}>
                    <div style={minimized ? Object.assign({}, box_body_style, { minHeight: 0 }) : box_body_style}>
                      {this.getBody(boxData, this.props.dialog_type)}
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <Draggable
                onStart={this.onStart.bind(this)}
                handle=".box-header"
                bounds={this.props.fromDialogBox ? '' : '#app'}
              >
                <div
                  className="dialog-box"
                  style={Object.assign({}, style, {
                    width: 'auto !important',
                  })}
                >
                  <div className={'box-header ' + this.state.boxHeader.headerClass}>{this.renderControllers()}</div>
                  <div className="box-body" style={this.state.bodyStyle}>
                    <div
                      style={
                        minimized
                          ? Object.assign({}, box_body_style, {
                              minHeight: this.state.height,
                            })
                          : box_body_style
                      }
                    >
                      {this.getBody(boxData, this.props.dialog_type)}
                    </div>
                  </div>
                </div>
              </Draggable>
            );
          } // else if !OPERATIONS_BOARD
      }
    } else {
      return null;
    }
  }
}
