import React from 'react';
import ObjectTree from '../Common/ObjectTree.js';
import Results from './Results.js';
import Form from '../Common/Form.js';
import classNames from 'classnames';

export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [], //the currently selected report, stored as an array of size 1 for compatibility with ObjectTree
      showTree: true, //whether or not the report tree is currently expanded
      showDesigner: true, //whether or not the report designer is currently expanded
      showHeader: false,
    };
  }

  //set the selected report. Callback to be passed to Object Tree
  setSelected(selected) {
    if (selected.length > 0) {
      this.setState({
        selected: selected,
      });
    }
  }

  //load the reports when the component mounts. Component will automatically
  //re-render on completion because the props from redux will change
  componentDidMount() {
    this.props.reportsActions.getReports();
  }
  expandTree() {
    this.setState({
      showTree: !this.state.showTree,
    });
  }
  expandDesigner() {
    this.setState({
      showDesigner: !this.state.showDesigner,
    });
  }

  //if the tree is expanded create an ObjectTree of stored reports,
  //otherwise just display the label of the section
  getTree() {
    return this.state.showTree ? (
      <ObjectTree
        tree={this.props.reports.reports.org_reports}
        setSelectedCallback={this.setSelected.bind(this)}
        simple={true}
        selectedNodes={this.state.selected}
      />
    ) : (
      <div className="collapsedLabel">Saved Reports</div>
    );
  }

  // get the card header for the content card if the tree area is expanded
  getTreeHeader() {
    return this.state.showTree ? <div className="cardHeader">Saved Reports</div> : null;
  }

  //display the custom report form if the section is expanded
  getCustomReportForm() {
    return this.state.showDesigner ? <Form formData={this.props.reports.reports.custom_report} /> : null;
  }

  render() {
    //wait until reports has loaded
    if (this.props.reports.reports) {
      var results =
        this.state.showDesigner || this.state.selected.length <= 0 ? null : (
          <Results key={'reportResults' + this.state.selected[0].id} report={this.state.selected[0]} />
        );
      var leftClosed = this.state.showTree ? '' : ' leftClosed';
      var designerClosed = this.state.showDesigner ? '' : ' designerClosed';

      var leftExpandClass = this.state.showTree ? 'glyphicon-resize-small' : 'glyphicon-resize-full';
      var designerExpandClass = this.state.showDesigner ? 'glyphicon-resize-small' : 'glyphicon-resize-full';

      var header = this.state.showHeader ? <div className={'header reportsSection contentCard'}>Header</div> : null;
      var showHeaderClass = this.state.showHeader ? '' : ' hideHeader';
      return (
        <div className={classNames('reports', leftClosed, designerClosed, showHeaderClass)}>
          <div className="left">
            <div className="treeView reportsSection contentCard">
              {this.getTreeHeader()}
              <span className={'glyphicon cardExpandButton ' + leftExpandClass} onClick={this.expandTree.bind(this)} />
              <div className="cardBody">{this.getTree()}</div>
            </div>
          </div>
          <div className="right">
            <div className={'designer reportsSection contentCard'}>
              <div className="cardHeader"> Report Designer </div>
              <span
                className={'glyphicon cardExpandButton ' + designerExpandClass}
                onClick={this.expandDesigner.bind(this)}
              />
              <div className="cardBody">{this.getCustomReportForm()}</div>
            </div>
            {header}
            <div className={'resultsContainer reportsSection contentCard'}>
              <div className="cardHeader"> Results </div>
              {results}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
