import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import WrappedSelect from '../Common/WrappedSelect';
import Form from '../Common/Form';
import { getReport, dynamicOEButtonCall } from '../../actions/API';
import CustomToolTip from '../Common/CustomToolTip';
import Drag from '../Common/Drag';
import { get } from '../../actions/REST';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import MuiDateRange from '../MuiForm/DateRange';
import Buttonbar from '../Common/Buttonbar';
import './Records.scss';
const inputTypes = require('../../constants/inputTypes');
const formControlTypes = require('../../constants/formControlTypes');
const buttonTypes = require('../../constants/buttonTypes.js');


class Records extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: null,
      filters: [],
      records: null,
      results: null,
      showForm: false,
      html: null,
      showHtml: false,
      noHeader: false,
      form: null,
      buttonbar: null,
      record_control_panel: null,
      updatedValues: null,
      hiddenFields: [],
      menuData: null,
      alert: null,
      noRecordsText: 'No records found!',
    };
    this.getToggleButtons = this.getToggleButtons.bind(this);
    this.parseData = this.parseData.bind(this);
  }

  parseData(data) {
    let initialSubmitValues = {};
    this.controlled_fields = [];
    let noHeader = false;
    let menuData;
    if (!data.config) {
      return null;
    }

    let { record_control_panel, filters } = data.config;

    noHeader = data.config.no_header ? data.config.no_header : false; 
    if (data.config.menu) {
      menuData = data.config.menu;
    }

    if (record_control_panel) {
      for (let i = 0; i < record_control_panel.length; i++) {
        let field = record_control_panel[i];
        if (field.value) {
          initialSubmitValues[field.name] = field.value;
        }
      }
    }

    // Find which fields the filters, if any, will be using for
    // display selection
    if (typeof filters !== 'undefined') {
      Object.keys(filters).map(index => {
        return filters[index].controlled_field && this.controlled_fields.push(filters[index].controlled_field);
      });
    }

    this.all_ids = {};
    this.controlled_fields.map(field => (this.all_ids[field] = []));
    const record_group_list = Object.keys(data.config.records);
    record_group_list.map(group_name => {
      const records_type_list = Object.keys(data.config.records[group_name]);
      return records_type_list.map(record_name => {
        const records = Object.keys(data.config.records[group_name][record_name]);
        return records.map(record => {
          this.controlled_fields.map(field => {
            data.config.records[group_name][record_name][record][field] &&
              !this.all_ids[field].includes(data.config.records[group_name][record_name][record][field]) &&
              this.all_ids[field].push(data.config.records[group_name][record_name][record][field]);
          });
        });
      });
    });

    // Initialize each filter with a copy of the relevant all_ids
    if (typeof filters !== 'undefined') {
      Object.keys(filters).map(index => {
        filters[index].all_ids = this.all_ids[filters[index].controlled_field];
      });
    }

    this.setState({
      filters,
      updatedValues: initialSubmitValues,
      noHeader,
      menuData,
    });
    this.updateState(data.config);

  }

  componentDidMount() {
    this.parseData(this.props.data.data ? this.props.data.data : this.props.data);
  }


  componentDidUpdate(prevProps, prevState) {
    this.props.data.data.config !== prevProps.data.data.config && this.parseData(this.props.data.data);
  }

  updateState({ record_control_panel, buttonbar, records }) {
    // Don't update selectedButton unless it is null
    const selectedButton = this.state.selectedButton
      ? this.state.selectedButton
      : buttonbar
      ? buttonbar[0].label
      : null;

    this.setState({
      selectedButton: selectedButton,
      buttonbar,
      record_control_panel,
      records,
    });
  }

  /* Handles the click on the buttons that will update the state with
	the selected button, this will cause a re-render and the right form will
	be rendered.
	- selected (string)
	*/
  handleSelectedButton(button) {
    this.setState({ records: null, filters: null, noRecordsText: 'Loading, please wait...' });

    setTimeout(() => {
      let variables = ['GET', 'POST', 'PUT', 'DELETE'].indexOf(button.method) >= 0 && button.variables;

      dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
        if (response.data.code === 200) {

          //let filters = response.data.config.filters;
          let filters = response.data.config.filters;
          // Find which fields the filters, if any, will be using for
          // display selection
          if (typeof filters !== 'undefined') {
            Object.keys(filters).map(index => {
              return filters[index].controlled_field && this.controlled_fields.push(filters[index].controlled_field);
            });
          }

          this.all_ids = {};
          this.controlled_fields.map(field => (this.all_ids[field] = []));
          const data = response.data;
          const record_group_list = Object.keys(data.config.records);
          record_group_list.map(group_name => {
            const records_type_list = Object.keys(data.config.records[group_name]);
            return records_type_list.map(record_name => {
              const records = Object.keys(data.config.records[group_name][record_name]);
              return records.map(record => {
                this.controlled_fields.map(field => {
                  data.config.records[group_name][record_name][record][field] &&
                    !this.all_ids[field].includes(data.config.records[group_name][record_name][record][field]) &&
                    this.all_ids[field].push(data.config.records[group_name][record_name][record][field]);
                });
              });
            });
          });

          if (typeof filters !== 'undefined') {
            // Initialize each filter with a copy of the relevant all_ids
            Object.keys(filters).map(index => {
              filters[index].selected = true;
              filters[index].all_ids = this.all_ids[filters[index].controlled_field];
              filters[index].selected_ids = this.all_ids[filters[index].controlled_field];
            });
          }
          console.log(filters);
          console.log(response.data);

          this.setState({ records: response.data.config.records, filters: filters, selectedButton: button.label });

        }
      });
    }, 100);
  }

  // handleControllerShift, getOnChange and getFieldInput are subsets of Form methods
  // --------------------------------------------------------------------------------
  callAPI(endpoint, endpoint_type, method, variables = {}, extra = {}, dynamicId) {
    let params = Object.assign({}, this.state.updatedValues, variables);
    //Adding extra parameter, usually the Changing Dropdown
    Object.assign(params, extra);
    if (method === 'GET') {
      params = {
        ...params,
        ...this.state.toggleBtns,
      };
      get(params, `/${endpoint}`, 'Requesting new records').then(response => {
        if (response.status === 200 && response.data) {
          this.parseData(response.data, dynamicId); //Initialize page again
        } else {
          console.warn('Unexpected response to record_control request');
        }
      });
    } else {
      console.warn('wrong method');
    }
  }

  // if the given field is a form controller, update the forms state accordingly given its new value
  // TODO: return value instead of setting state, set state externally so that we can use this on initialization
  handleControllerShift(field, newValue) {
    //TODO: think of a better way to do this
    if (field.form_controllers) {
      for (let i = 0; i < field.form_controllers.length; i++) {
        let controller = field.form_controllers[i];
        // only support REPLACE OPTS for now
        if (controller.control_type === formControlTypes.REPLACE_OPTS) {
          const { endpoint, endpoint_type, method, variables } = controller;
          if (endpoint && endpoint_type && method) {
            /* The dynamicId is the key that will be used to create a new Form when we try
             ** to replace the existing form. By using this we tell React that we want this
             ** Form to be re-rendered.
             **
             ** Check getFormElements to see how this is used. */
            const dynamicId = field.name + (/<[a-z][\s\S]*>/i.test(newValue) ? i : newValue + i);
            const extra = { [field.name]: newValue }; //including the new Name:Value pair
            this.callAPI(endpoint, endpoint_type, method, variables, extra, dynamicId);
          } else {
            console.warn('Need endnpoint, endpoint_type and method');
          }
        }
      }
    }
  }

  getOnChange(field) {
    return function (event) {
      let newValue = event && event.target ? event.target.value : event;
      this.handleControllerShift(field, newValue);

      let updatedValues = this.state.updatedValues;
      updatedValues[field.name] = newValue;
      this.setState({ updatedValues: updatedValues });
    }.bind(this);
  }
  getFieldInput(field) {
    let fieldValue = this.state.updatedValues ? this.state.updatedValues[field.name] : field.value;

    switch (field.input_type) {
      case inputTypes.DATE_SELECT:
        return (
          <DatePickerInput
            key={'cal' + field.name}
            name={field.name}
            value={fieldValue ? moment(fieldValue) : null}
            minDate={field.range ? moment(field.range.start) : null}
            maxDate={field.range ? moment(field.range.end) : null}
            onChange={this.getOnChange(field)}
          />
        );
      default:
        return;
    }
  }

  getFilterInput(filter) {
    switch (filter.input_type) {
      case inputTypes.CHECKBOX:
        return (
          <div>
            <input
              type="checkbox"
              key={filter.name}
              className="filter-input"
              name={filter.name}
              onChange={this.handleFilter(filter)}
              checked={filter.selected}
            />
            <label className="filter-label" htmlFor={filter.name}>
              {filter.label}
            </label>
          </div>
        );
      //if it's a dropdown or a multi dropdown, return a Select component with "multi" set appropriately
      case inputTypes.DROPDOWN:
      case inputTypes.DROPDOWN_MULTI: {
        if (!(filter && filter.opts && Array.isArray(filter.opts))) {
          console.warn('no opts, or opts is not an array - Dropdown');
          return null;
        }
        let options = [];
        //using a map here because a for loop was causing an infinite loop
        filter.opts.map(function (opt) {
          options.push({
            value: opt.id,
            label: opt.label,
            details: opt.details,
          });
          return opt;
        });
        let isMulti = filter.input_type === inputTypes.DROPDOWN_MULTI;
        //for now default multi value to an empty array because the value property from the api is formatted incorrectly

        let activeElement = 'dropdown';
        let foundOther = false;
        if (!isMulti && filter.value !== '' && parseInt(filter.value) !== 0) {
          activeElement = 'input';
          options.forEach(function (opt) {
            if (opt.value === filter.value) activeElement = 'dropdown';
            if (opt.value === 'other') {
              foundOther = true;
            }
          });
          if (!foundOther) activeElement = 'dropdown';
        }
        return (
          <div>
            <label className="filter-label" htmlFor={filter.name}>
              {filter.label}
            </label>
            <WrappedSelect
              multi={isMulti}
              name={filter.name}
              key={'dropDown' + filter.name}
              value={filter.value}
              activeElementType={activeElement}
              options={options}
              size={filter.size}
              autocomplete={filter.autocomplete}
              className={'customSelect' + (isMulti ? ' multiple' : '')}
              onChange={this.handleFilter(filter)}
            />
          </div>
        );
      }
      case inputTypes.DATE_RANGE:

        return(
          <div>
            <label className="filter-label" htmlFor={filter.name}>{filter.label}</label>
            <MuiDateRange
              key={"calRange" + filter.name}
              startLabel={filter.start_label}
              endLabel={filter.end_label}
              startDefault={moment(filter.startDefault)}
              endDefault={moment(filter.endDefault)}
              start={filter.range ? moment(filter.range.start) : null}
              end={filter.range ? moment(filter.range.end) : null}
              onChange={this.handleFilter(filter)}
              />
          </div>
        );

      default:
        return;
    }
  }

  /* Extracting the buttons from the json, creating an Array of buttons
	that have an onClick event to change the state of the selectedButton.
	- buttonbar (array)
	*/
  getToggleButtons(buttonbar) {
    return buttonbar.map(button => (
      <li
        key={button.label}
        onClick={() => this.handleSelectedButton(button)}
        className={this.state.selectedButton === button.label ? 'selected toggle-buttons-item' : 'toggle-buttons-item'}
      >
        <span>{button.label}</span>
      </li>
    ));
  }

  /* Extracting the selectors from the json, creating an array of selectors
   */
  getSelectors(record_control_panel) {
    // Potentially there can be multiple selector fields
    let fields = [];
    for (let i = 0; i < record_control_panel.length; i++) {
      let field = record_control_panel[i];

      if (field.input_type === inputTypes.DATE_SELECT) {
        fields.push(
          <div>
            <div className="selector-label">{field.label}</div>
            {this.getFieldInput(field)}
          </div>,
        );
      }
    }
    return fields;
  }

  /* Handling the result of the API after submiting the form */
  handleResult(data) {
    if (data) {
      data.statusText === 'OK' && this.props.refreshState();
    } else {
      console.error('No response received!');
    }
  }

  /* - records (object) */
  getAllRecords(records) {
    //verify if there's any records
    if (records && records.length === 0) {
      return <div className="no-records">No records found!</div>;
    }

    //extracting the groups name of record (apparatus, position)
    const record_group_list = Object.keys(records);

    return record_group_list.map(group_name => {
      //For each group_name extract the record type (roster, duty...)
      const records_type_list = Object.keys(records[group_name]);
      return (
        <div className="record-group-box" key={group_name}>
          <div className="cardHeader">{group_name}</div>
          {records_type_list.map(record_name => (
            <div className="cardBody" key={record_name}>
              <span className="group-record-title">{record_name}</span>
              <ul className="record-group-list">{this.getRecordByType(records[group_name][record_name])}</ul>
            </div>
          ))}
        </div>
      );
    });
  }

  //used as a success callback to form.
  //Not sure this is needed. PJW 18Aug22
  //06Feb24: This should call refreshState if it is set. This allows a form to 
  //trigger a reload of the records on success.
  // Data is the response from the form action. This is not used yet, but it could be. 
  setResults(data) {
    this.setState({
      alert: data.data.fullmsg || data.data.msg || data.data.errmsg,
      showForm: data.status >= 200 && data.status < 300 ? false : true, //collapse form on successful responses
    });

    this.props.refreshState && this.props.refreshState(this.state.form.on_success_params);
  }

  closeDialog(e) {
    e && e.stopPropagation(); //Prevent the propagation of the Click event
    this.setState(
      {
        showHtml: false,
        showForm: false,
        alert: null,
      },
      this.props.onClose && this.props.onClose(),
    );
  }

  /*
   ** Returns an Alert that lasts 3s (3000ms).
   **
   ** @param {Object} - alert
   */
  getAlert(alert) {
    //Alert only lasts 3 seconds
    setTimeout(() => this.setState({ alert: null }), 3000);

    return <Drag headerText={alert} close={() => this.closeDialog()} />;
  }

  getForm() {
    if (this.state.showForm && this.state.form.form) {
      // PJW 06Feb24 Removed onSubmit prop
      // refreshState is the Router loadJasonContent and the form submit will pass the wrong values to that. 
      return (
        <Drag headerText={this.state.form.form.label} close={() => this.closeDialog()}>
          <Form
            formData={this.state.form.form}
            key={'form' + this.state.form.form.label}
            standAlone={false}
            success={this.setResults.bind(this)}
            //onSubmit={this.props.refreshState}
          />
        </Drag>
      );
    } else {
      return null;
    }
  }

  getHtml() {
    if (this.state.showHtml) {
      return (
        <Drag close={() => this.closeDialog()}>
          <div className="htmlEndpointContainer" dangerouslySetInnerHTML={{ __html: this.state.html.data }} />
        </Drag>
      );
    } else {
      return null;
    }
  }

  filterRecord(record, filter_ids) {
    let can_display = true;
    Object.keys(filter_ids).map(key => {
      if (typeof record[key] !== 'undefined' && !filter_ids[key].includes(record[key])) {
        can_display = false;
      }
    });
    return can_display;
  }

  /* Goes through a list of records and returns a list element that includes
	the record label and the button next to it.
	- records (array) 
	*/
  getRecordByType(records) {
    let { filters } = this.state;
    let filter_ids = {};
    // Javascript objects are shallow copied - this provides a deep copy of
    // the all_ids class variable.
    Object.keys(this.all_ids).map(field => (filter_ids[field] = this.all_ids[field]));

    // Process filters and find intersection of filter_ids with the selected ids
    if (typeof filters !== 'undefined') {
      filters.map(filter => {
        let ids = typeof filter.selected_ids !== 'undefined' ? filter.selected_ids : filter.all_ids;
        filter_ids[filter.controlled_field] = filter_ids[filter.controlled_field].filter(value => ids.includes(value));
      });
    }
    // Record display can be controlled by several different id fields The names of each are
    // found in the filter_ids object, along with the values used for filtering
    if (typeof filters === 'undefined') {
      return records.map(record =>
          <li className="record-group-item" key={record.record_id}>
            {typeof record.cells !== 'undefined' &&
              record.cells.map((cell, index) => {
                switch (cell.type) {
                  case 'button':
                    return this.renderButton(cell, record.record_id, index);
                  default:
                    return this.renderCellText(cell);
                }
              })}
          </li>
        );
    }
    return records.map(record =>
      this.filterRecord(record, filter_ids) ? (
        <li className="record-group-item" key={record.record_id}>
          {typeof record.cells !== 'undefined' &&
            record.cells.map((cell, index) => {
              switch (cell.type) {
                case 'button':
                  return this.renderButton(cell, record.record_id, index);
                default:
                  return this.renderCellText(cell);
              }
            })}
        </li>
      ) : (
        ''
      ),
    );
  }

  renderCellText(cell) {
    let cell_style = { color: 'black' };
    if (cell.type === 'header') {
      cell_style.fontWeight = 'bold';
    }
    if (cell.width) {
      cell_style.width = cell.width;
    }
    if (cell.color) {
      cell_style.color = cell.color;
    }
    if (cell.weight) {
      cell_style.fontWeight = cell.weight;
    }
    if (cell.order) {
      const arrow = cell.order.up ? 'angle-up' : 'angle-down';
      return (
        <span className="cell" onClick={() => this.handleReorderRecords(cell)} style={cell_style}>
          {cell.data}
          <FontAwesomeIcon
            key={`arrow_${cell.cell_id}`}
            icon={arrow}
            style={{ fontSize: '11px', paddingLeft: '2px' }}
          />
        </span>
      );
    }

    return (
      <span className="cell" style={cell_style}>
        {cell.data}
      </span>
    );
  }

  renderButton(cell, record_id, index) {
    let button = cell['data'];
    if (button.method) {
      let icon = null;
      switch (button.method) {
        /* If in the future there's another type of button you can search
				for an icon with React-Bootstrap Glyphicon and add it to the
				switch statement. */
        case 'DELETE':
          if (button.icon) {
            icon = (
              <CustomToolTip
              icon={button.icon}
              iconClass={button.iconClass ? button.iconClass : 'svg-img-red'}
              text={button.hovertext || ''}
              />
            );
          }

          return (
            <span
            style={{ width: cell.width }}
            key={`close_${record_id}_${index}`}
            className="record-button"
            onClick={() => this.handleRecordButton(button, record_id)}
            >
            {button.label}
            {icon}
            </span>
          ); 
        default:
          if (button.icon) {
            icon = (
              <CustomToolTip
                icon={button.icon}
                iconClass={button.iconClass ? button.iconClass : 'svg-img-black'}
                text={button.hovertext || ''}
              />
            );
          }

          return (
            <span
              style={{ width: cell.width }}
              key={`close_${record_id}_${index}`}
              className="record-button"
              onClick={() => this.handleRecordButton(button, record_id)}
            >
              {button.label}
              {icon}
            </span>
          );
      }
    } else if (cell.width) {
      return <span style={{ width: cell.width }} key={`close_${record_id}_${index}`} className="record-button" />;
    }
  }

  /* When a button is clicked we use dynamicOEButtonCall to send the
	necessary data to the API. Making sure we receive an OK status before
	refreshing the state and cause a re-render. 
	PJW 30Aug22 Use record_id from the response, and an 'action' field to 
	decide to hide (actually remove) the record. */
  handleRecordButton(button, record_id) {
    let variables = ['DELETE', 'POST', 'PUT'].indexOf(button.method) >= 0 && button.variables;
    dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
      if (response.status === 200) {
        if (button.method && button.method === 'POST') {
          const record_group_list = Object.keys(this.state.records);
          let records = this.state.records;

          // Action === 'hide' - hide the newly approved record without needing to reload.
          //
          if (button.action && button.action.match('hide')) {
            // These nested forEach loops modify the records array
            record_group_list.forEach(group_name => {
              const records_type_list = Object.keys(records[group_name]);
              records_type_list.forEach(record_name => {
                records[group_name][record_name] = records[group_name][record_name].filter(
                  item => item.record_id !== record_id,
                );
              });
            });
          }

          this.setState({ records: records, alert: response.data.msg || response.data.errmsg });
        }
        if (button.reload) {
          this.props.refreshState && this.props.refreshState();
        }

        let form = response.data;
        form.on_success_params = button.on_success_params ? button.on_success_params : [];

        switch (button.endpoint_type) {
          case 'form':
            this.setState({ form: form, showForm: true });
            break;
          case 'html':
            this.setState({ html: response.data, showHtml: true });
            break;
          default:
            break;
        }
      }
    });
  }

  // Records are an array of cells. Comparing requires
  // a) Identifying, in both records a and b, the cell to use, by matching the cell id with the
  //    cell_id identifier in the order object.
  //    a.1) If no matching cell is found, return -1 to sink that record to the bottom
  // b) Using the order.sort value, perform the comparison
  handleReorderRecords(cell) {
    let records = this.state.records;

    // invert sort order for next time
    cell.order.up = cell.order.up ? false : !cell.order.up;

    const order = cell.order;
    const record_group_list = Object.keys(records);
    record_group_list.forEach(group_name => {
      const record_type_list = Object.keys(records[group_name]);
      record_type_list.forEach(type_name => {
        const new_records = Object.entries(records[group_name][type_name]).sort(([, a], [, b]) => {
          // Update the ordering cell while processing each record
          // !! Sort may do multiple passes so this is not the most efficient, but that is negligible
          // compared to the alpha and date compare operations
          Object.entries(a.cells).forEach(([, a]) => {
            if (a === cell) records[group_name][type_name][a] = cell;
          });

          // Ensure unsortable records (like the header row) stay at the top.

          if (typeof a !== 'object' || typeof b !== 'object') {
            return 0;
          }
          if (!a.cells || !b.cells || !a.record_id || !b.record_id) {
            return 0;
          }

          let val_a = 0;
          let val_b = 0;
          Object.entries(a.cells).forEach(([, a]) => {
            if (a['cell_id'] === order.cell_id) val_a = a[order.field];
          });
          Object.entries(b.cells).forEach(([, a]) => {
            if (a['cell_id'] === order.cell_id) val_b = a[order.field];
          });
          if (order.up) {
            // Reverse sort order
            const tmp = val_a;
            val_a = val_b;
            val_b = tmp;
          }
          //const val_a = Object.entries(a.cells).reduce((x,[,cell]) => {console.log(cell['cell_id'],order.cell_id); if (cell['cell_id'] === order.cell_id) return cell[order.cell_id]}) ;
          //const val_a = Object.entries(a.cells).reduce((x,[,cell]) => {(cell['cell_id'] === order.cell_id) && cell[order.cell_id]}) ;
          if (typeof val_a === 'undefined' || typeof val_b === 'undefined') {
            return 1;
          }
          switch (order.sort) {
            case 'alpha':
              return val_a.toString().localeCompare(val_b.toString(), 'en', { numeric: true });
            case 'numeric':
              return val_a - val_b;
            case 'date':
              return new Date(val_a) - new Date(val_b);
            default:
              console.log(`Unknown ${order.sort} sort ${val_a},${val_b}`);
              return 0;
          }
        });
        // Remap the records using the sorted indices
        records[group_name][type_name] = new_records.map(([, a]) => a);
      });
    });
    this.setState({ records });
  }

  /*
   ** This sets all the filters "unchecked" (value of false) and since these fields
   ** are hidden, also clear the value of the fields that they
   ** control
   **
   ** Note: this does not clear the Fields that are not being control by a Filter.
   */
  resetFilters() {
    // Reset each filter with a copy of the all_ids list
    let filters = this.state.filters;
    filters.map((filter, index) => {
      filters[index].selected = true;
      filters[index].selected_ids = filters[index].all_ids;
    });
    this.setState(prevState => {
      return {
        filters: prevState.filters !== filters ? filters : prevState.filters,
      };
    });
  }

  getFilters(filters) {
    let rendered_filters = [];
    let include_reset = false;
    if (filters && filters.length > 0) {
      rendered_filters = filters.map(filter => {
        if (filter.input_type === inputTypes.CHECKBOX) {
          include_reset = true;
        }
      });
      rendered_filters = filters.map(filter => (
        <div
          key={'filter_' + filter.name + filter.value + filter.label}
          style={{ width: filter.width, overflow: 'visible' }}
          className="filter-wrapper"
        >
          {this.getFilterInput(filter)}
        </div>
      ));
    }
    return (
      <div>
        <div className="cardHeader">
          <span>Filters</span>
        </div>
        <div className="cardBody filters-body">
          {rendered_filters}
          {include_reset && (
            <div className="clear-all">
              <Button style={{ padding: '0', marginBottom: '5px' }} onClick={() => this.resetFilters()} bsStyle="link">
                Reset Filters
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  /*
   ** Adds or Remove from selected_ids list, depending
   ** on value of the checkbox.
   **
   ** @param {Object} - filter
   */
  handleFilter(filter) {
    let filters = this.state.filters;
    if (filter.input_type === inputTypes.CHECKBOX) {
      return function () {
        let controlled = filter.controlled;
        // Because of how the dropdown multi filters are handled, each filter has its
        // own all_ids list. For checkboxes this consists of the state all_ids list i.e. all
        // the controlled ids, with this particular filter id either included or removed
        // depending on the checked state.
        let newValue = filter.all_ids;
        if (filter.selected) {
          newValue = filter.all_ids.filter(id => id !== controlled);
        }
        // find which of the state filters this affects and update it's selected_ids list and toggle it's state
        filters.map((state_filter, index) => {
          if (state_filter.label === filter.label) {
            filters[index].selected_ids = newValue;
            filters[index].selected = !filters[index].selected;
          }
        });
        this.setState(prevState => {
          return {
            filters: prevState.filters !== filters ? filters : prevState.filters,
          };
        });
      }.bind(this);
    } else if (filter.input_type === inputTypes.DROPDOWN_MULTI) {
      return function (event) {
        let newValue = event && event.target ? Object.keys(event.target.value) : Object.keys(event);
        if (newValue.length === 0) {
          newValue = filter.all_ids;
        }
        // find which of the state filters this affects and update it's selected_ids
        filters.map((state_filter, index) => {
          if (state_filter.label === filter.label) {
            filters[index].selected_ids = newValue;
          }
        });
        this.setState(prevState => {
          return {
            filters: prevState.filters !== filters ? filters : prevState.filters,
          };
        });
      }.bind(this);
    } else if (filter.input_type === inputTypes.DROPDOWN) {
      return function (event) {
        let newValue = event;
        if (newValue.length === 0) {
          newValue = filter.all_ids;
        }
        // find which of the state filters this affects and update it's selected_ids
        filters.map((state_filter, index) => {
          if (state_filter.label === filter.label) {

            if (state_filter.inequality) {
              switch(filter.inequality) {
                case '>=':
                  filters[index].selected_ids = [];
                  state_filter.all_ids.map(id => 
                    {return id >= newValue && filters[index].selected_ids.push(id);});
                  break;
                case '<=':
                  filters[index].selected_ids = [];
                  state_filter.all_ids.map(id => 
                    {return id <= newValue && filters[index].selected_ids.push(id);});
                  break;
                case '<':
                  filters[index].selected_ids = [];
                  state_filter.all_ids.map(id => 
                    {return id < newValue && filters[index].selected_ids.push(id);});
                  break;
                case '>':
                  filters[index].selected_ids = [];
                  state_filter.all_ids.map(id => 
                    {return id > newValue && filters[index].selected_ids.push(id);});
                  break;
                default:
                  filters[index].selected_ids = newValue;
                  break;
              }
            } else {
              filters[index].selected_ids = newValue;
            }
          }
        });
        this.setState(prevState => {
          return {
            filters: prevState.filters !== filters ? filters : prevState.filters,
          };
        });
      }.bind(this);
    } else if (filter.input_type === inputTypes.DATE_RANGE) {
      return function (event) {
        // event contains start and end dates
        // filters contains the opts

        // find which of the state filters this affects and update it's selected_ids
        filters.map((state_filter, index) => {
          if (state_filter.label === filter.label) {
            const sdate = new Date(event.start);
            const edate = new Date(event.end);
            filters[index].selected_ids = [];
            state_filter.all_ids.map(id => 
              {
                const opt_date = new Date(id); 
                return opt_date <= edate && opt_date >= sdate && filters[index].selected_ids.push(id);
              });
          }
        });
        this.setState(prevState => {
          return {
            filters: prevState.filters !== filters ? filters : prevState.filters,
          };
        });
      }.bind(this);
    }
  }

  getMenu(menuData) {
    let renderedButtonbar = null;
    if (menuData.buttons) {
      renderedButtonbar = (
        <Buttonbar
          buttonbar={menuData.buttons}
          handleButton={this.handleButton.bind(this)}
          style={{ position: 'absolute', right: '5px', top: '25px' }}
        />
      );
    }
    return renderedButtonbar;
  }

  openNewWindow(button) {
    const variables = button.variables || {};
    dynamicOEButtonCall(`/${button.endpoint}`, button.method, variables).then(response => {
      if (response.status >= 200 && response.status < 300) {
        window.open().document.write(response.data.data ? response.data.data : response.data);
      }
      //If not open an APIDialog
    });
  }


  handleButton(button) {
    if (button.action === buttonTypes.OPEN_NEW_WINDOW) {
      return this.openNewWindow(button);
    }
  }


  render() {
    const { selectedButton, records, buttonbar, record_control_panel, alert, filters, noHeader, menuData, noRecordsText } = this.state;

    return (
      <div className="Records">
        {alert && this.getAlert(alert)}
        <div className="content-wrapper">
          {selectedButton && (
            <div className="left-content contentCard">
              <div className="cardHeader">Select Record</div>
              <div className="cardBody">
                <ul className="toggle-buttons-group">{this.getToggleButtons(buttonbar)}</ul>
              </div>
            </div>
          )}
          {filters && filters.length > 0 && <div className="left-content contentCard">{this.getFilters(filters)}</div>}
          <div className="right-content">
            <div className="records contentCard">
            { !noHeader && ( <div className="cardHeader">Records</div> ) }
              <div className="record-control-panel">
                {record_control_panel && this.getSelectors(record_control_panel)}
              </div>
              <div className="record-wrapper cardBody">
                {records ? this.getAllRecords(records) : <div className="no-records">{ noRecordsText }</div>}
              </div>
              <div className="cardBody">
                {menuData && this.getMenu(menuData)}
                {this.getForm()}
                {this.getHtml()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Records;
