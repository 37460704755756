import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import onClickOutside from 'react-onclickoutside';

class DropdownBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedOpt: null,
    };

    this.onClick = this.onClick.bind(this);
    this.openOrCloseCaret = this.openOrCloseCaret.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (Array.isArray(this.props.preSelected) && this.props.preSelected.length) {
      this.setState({
        selectedOpt: this.props.preSelected[0].value,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props && this.state.selectedOpt === prevState.selectedOpt && this.state.open) {
      this.setState({
        open: false,
      });
    } else if (prevProps.selectedClass === 'selected' && !this.props.selectedClass) {
      this.setState({
        selectedOpt: null,
      });
    }
  }

  handleClickOutside() {
    this.state.open &&
      this.setState({
        open: false,
      });
  }

  onClick(selectedOpt) {
    const { options } = this.props;

    if (this.props.onOptSelected) {
      const selectedBtn = options.filter(opt => opt.value === selectedOpt)[0];
      this.props.onOptSelected(selectedBtn, selectedOpt);
    }

    this.setState({
      selectedOpt,
      open: false,
    });
  }

  openOrCloseCaret() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  render() {
    let caret = <FontAwesomeIcon icon="angle-down" style={{ display: 'inline' }} className="font-size-12" />;
    if (this.state.open) {
      caret = <FontAwesomeIcon icon="angle-up" style={{ display: 'inline' }} className="font-size-12" />;
    }
    const extraClass = this.props.transparentStyle ? ' transparentBtn' : '';
    const openClass = this.state.open ? ' open' : '';

    return (
      <div className={classNames('nd-dropdown', extraClass)}>
        <div className="nd-container" ref="container">
          {this.state.selectedOpt ? (
            this.props.options
              .filter(opt => opt.value === this.state.selectedOpt)
              .map(({ label, value, id }) => (
                <button
                  key={id}
                  type="button"
                  className={'nd-button ' + this.props.selectedClass}
                  onClick={() => this.openOrCloseCaret()}
                >
                  <span className="title">{label}</span>
                  <span
                    style={{
                      marginLeft: '5px',
                      verticalAlign: 'bottom',
                    }}
                  >
                    {caret}
                  </span>
                </button>
              ))
          ) : (
            <button
              key={'label'}
              type="button"
              className={classNames('nd-button', this.props.selectedClass)}
              onClick={() => this.openOrCloseCaret()}
            >
              {this.props.label}
              <span>{caret}</span>
            </button>
          )}
          {
            <div className={classNames('dropdown', openClass)}>
              <ul>
                {this.props.options.map(({ class: className, label, value, id }) => {
                  let cs = className;

                  if (value === this.state.selectedOpt) {
                    cs += ' selected';
                  }

                  return (
                    <li key={id} className={cs} onClick={this.onClick.bind(this, value)}>
                      {label}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default onClickOutside(DropdownBtn);
