export const APPARATUS = require('../img/apparatus.png');
export const APPROVE = require('../img/approve.svg').default;
export const BID = require('../img/bid.svg').default;
export const CALLOUT = require('../img/callout.svg').default;
export const CANCEL = require('../img/cancel.svg').default;
export const CLOCKIN = require('../img/clockin.svg').default;
export const CLOCKOUT = require('../img/clockout.svg').default;
export const CREATE = require('../img/create.svg').default;
export const CROSSSTATION = require('../img/cross_station.png');
export const DAY = require('../img/day.png');
export const DELETE = require('../img/delete.svg').default;
export const DENY = require('../img/deny.svg').default;
export const EVENT = require('../img/event.svg').default;
export const EXCHANGE = require('../img/exchange.svg').default;
export const ENVELOPE_OPEN = require('../img/envelope_open.svg').default;
export const FULL = require('../img/full.svg').default;
export const MONTH = require('../img/month.png');
export const OFFER = require('../img/offer.svg').default;
export const OFFER2 = require('../img/offer2.svg').default;
export const ONDUTY = require('../img/onduty.svg').default;
export const ONDUTYPOLICE = require('../img/onDutyPolice.png');
export const ONSHIFT_BADGE = require('../img/duty_sm.png');
export const OVERTIME = require('../img/overtime.png');
export const PARTIAL = require('../img/partial.svg').default;
export const PAYPERIOD = require('../img/payperiod.png');
export const PTO = require('../img/pto.svg').default;
export const REPORTS = require('../img/reports.svg').default;
export const STATION = require('../img/station.png');
export const SCHEDULED_DUTY = require('../img/scheduled_duty.svg').default;
export const SHIFT = require('../img/shift.svg').default;
export const SHIFTBOARD = require('../img/shiftboard.png');
export const SIGNUP = require('../img/signup.svg').default;
export const SIGNUP_EVENT = require('../img/signup_event.svg').default;
export const SUB = require('../img/sub.svg').default;
export const TIME_CHANGE = require('../img/time_change.svg').default;
export const TIMECLOCK_RECORD = require('../img/timeclock_record.svg').default;
export const TIMEOFF = require('../img/timeoff.svg').default;
export const WEEK = require('../img/week.png');
