import React from 'react';
import { Form, FormGroup, FormLabel, Col, Row } from 'react-bootstrap';

export default class Radio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  render() {
    return (
      <Row className="formRow">
        <FormGroup>
          <Col componentClass={FormLabel} xs={3}>
            {this.props.label}
          </Col>
          <Col xs={9}>{this.renderOptions(this.props.options)}</Col>
        </FormGroup>
      </Row>
    );
  }

  renderOptions(opts) {
    let options = [];
    for (let key in opts) {
      if (opts.hasOwnProperty(key)) {
        options.push(
          <Form.Check
            type="radio"
            value={key}
            key={key}
            defaultChecked={key === this.props.default}
            name={this.props.name}
            inline
            onClick={this.props.handleInputChange.bind(this, this.props.name)}
          >
            {opts[key]}
          </Form.Check>,
        );
      }
    }
    return options;
  }
}
