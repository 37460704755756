import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loading from 'react-loading';
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once
import { Row, Col, Container, Card } from 'react-bootstrap';
import Stations from '../DutyRoster/Stations';
import EventModal from './EventModal';
import classNames from 'classnames';

//a helper component. The Events as they appear in the event list on "day" calendar
// view. control and open/close their own edit modal
class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  //open the edit modal
  open() {
    this.setState({ open: true });
  }

  //close the edit modal
  close() {
    this.setState({ open: false });
  }
  render() {
    // editModal is an EventModal with an ID passed to it so that it modifies
    // an existing event rather than creating a new one. Display this if the event
    // is "open"
    var editModal = this.state.open ? (
      <EventModal
        day={this.props.day}
        event={this.props.event}
        actions={this.props.GlobalActions}
        close={this.close.bind(this)}
        {...this.props}
      />
    ) : null;

    // display an edit button if the user has event edit privileges
    var editButton = this.props.privs.events.edit ? (
      <span /> /*<Glyphicon className="eventEditButton" glyph="pencil" onClick={this.open.bind(this)} />*/
    ) : null;

    // display signup indicator if this is a signup event
    var signupIndicator =
      this.props.event.type === 'signup' ? (
        <span /> // <Glyphicon className="signupIndicator" glyph="plus"/>
      ) : null;

    return (
      <div className="event">
        {editModal}
        <h4>
          {this.props.event.item_label}
          {editButton}
          {signupIndicator}
        </h4>
        <h5>{this.props.event.item_desc}</h5>
        <hr />
      </div>
    );
  }
}

export default class Day extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHideable: false,
      showEventCreate: false,
    };
  }

  componentWillMount() {
    // console.log("Day will:");
    // console.log(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateView(nextProps);
  }

  componentDidMount() {
    // console.log("Day did:");
    // console.log(this.props);
    if (!this.props.data) {
      this.props.GlobalActions.fetchDay(this.props.params);
    }
    this.updateView(this.props);
  }

  render() {
    console.log('Rendering a day using:');
    console.log(this);

    if (this.props.data) {
      const day = [this.props.params.year, this.props.params.month, this.props.params.day].join('-'); //YYYY-MM-DD
      let selectedDay = moment(
        this.props.params.year + '-' + this.props.params.month + '-' + this.props.params.day,
        'YYYY-MM-DD',
      );
      // let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

      // if the user has event create privileges, display the even create button
      const eventsTitle = this.props.privs.events.create ? (
        <h3>
          Events
          {/*<Glyphicon glyph="plus" onClick={this.open.bind(this)} style={{float:'right'}}/>*/}
        </h3>
      ) : (
        <h3>Events</h3>
      );

      const tomorrowLink = moment(
        `${this.props.params.year}-${this.props.params.month}-${this.props.params.day}`,
        'YYYY-MM-DD',
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
      const yesterdayLink = moment(
        `${this.props.params.year}-${this.props.params.month}-${this.props.params.day}`,
        'YYYY-MM-DD',
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');

      const dayTitle = moment(
        `${this.props.params.year}-${this.props.params.month}-${this.props.params.day}`,
        'YYYY-MM-DD',
      ).format('dddd, MMMM D YYYY');

      // const toggleHideableGlyph = 'chevron-' + (this.state.showHideable ? 'down' : 'up');

      return (
        <div className="day container-fluid topPaddingMd">
          <Container>
            <Row>
              <Col xsHidden={true} smHidden={true} mdHidden={true} lgHidden={false} lg={12}>
                <h1 className={classNames('dayTitle', this.props.data.class)}>
                  <Link to={yesterdayLink}>&#9668;</Link>
                  {dayTitle}
                  <Link to={tomorrowLink}>&#9658;</Link>
                </h1>
                <h4 className="dayTitle">{this.props.data.details}</h4>
              </Col>

              <Col xs={12} sm={6} md={6} lg={3}>
                <InfiniteCalendar
                  width={300}
                  height={300}
                  selected={selectedDay.format()}
                  className="miniCalendar"
                  onSelect={this.goToDate.bind(this)}
                  displayOptions={{
                    showHeader: false,
                  }}
                />
              </Col>

              <Col xsHidden={true} sm={6} md={6} lgHidden={true}>
                <Row>
                  {this.props.data.alert && (
                    <div className="dayAlertMsg">
                      {/*<Glyphicon glyph="alert" />*/}
                      {this.props.data.alert_msg}
                    </div>
                  )}
                </Row>
                <Row>
                  <Card header={eventsTitle} className="nopadding">
                    {this.props.data && this.renderEvents(this.props.data, selectedDay)}
                  </Card>
                  {this.state && this.state.showEventCreate && (
                    <EventModal
                      {...this.props}
                      day={selectedDay}
                      actions={this.props.GlobalActions}
                      close={this.close.bind(this)}
                    />
                  )}
                </Row>
              </Col>

              <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true} className="topPaddingMd">
                {this.props.data.alert && (
                  <div className="dayAlertMsg">
                    {/*<Glyphicon glyph="alert" />*/}
                    {this.props.data.alert_msg}
                  </div>
                )}
              </Col>

              <Col xs={12} sm={12} md={12} lgHidden={true}>
                <h1 className={'dayTitle ' + (this.props.data.class || '')}>
                  <Link to={yesterdayLink}>&#9668;</Link>
                  {dayTitle}
                  <Link to={tomorrowLink}>&#9658;</Link>
                </h1>
                <h4 className="dayTitle">{this.props.data.details}</h4>
              </Col>

              <Col xs={12} sm={12} md={12} lg={6}>
                <Row>
                  {/*<Glyphicon glyph={toggleHideableGlyph} style={{float : 'right', paddingRight : '15px', paddingBottom : '10px'}} onClick={this.toggleHideable.bind(this)} />*/}
                </Row>
                <Stations
                  data={this.props.data.dutyroster.stations}
                  day={day}
                  actions={this.props.GlobalActions}
                  showHideable={this.state.showHideable}
                />
              </Col>

              <Col xs={12} smHidden={true} mdHidden={true} lg={3}>
                <Row>
                  <Col className="nopadding" xsHidden={true} smHidden={true} mdHidden={true} lg={12}>
                    {this.props.data.alert && (
                      <div className="dayAlertMsg">
                        {/*<Glyphicon glyph="alert" />*/}
                        {this.props.data.alert_msg}
                      </div>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Card header={eventsTitle} className="nopadding">
                    {this.props.data && this.renderEvents(this.props.data, selectedDay)}
                  </Card>
                  {this.state && this.state.showEventCreate && (
                    <EventModal
                      {...this.props}
                      day={selectedDay}
                      GlobalActions={this.props.GlobalActions}
                      close={this.close.bind(this)}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      this.props.GlobalActions.fetchDay(this.props.params);
      return (
        <div className="hCenter">
          <Loading type="bars" color="#e3e3e3" />
        </div>
      );
    }
  }

  updateView(props) {
    if (!props || !props.params) return;
    let params = {
      seldate: moment(props.params.year + '-' + props.params.month + '-' + props.params.day, 'YYYY-MM-DD').valueOf(),
      scope: 'day',
      day: props.params.year + '-' + props.params.month + '-' + props.params.day,
    };
    props.GlobalActions.updateView(params);
  }

  close() {
    this.setState({ showEventCreate: false });
  }

  open() {
    this.setState({ showEventCreate: true });
  }

  toggleHideable() {
    //default to turning it on, otherwise toggle
    this.setState({
      showHideable: this.state.showHideable ? !this.state.showHideable : true,
    });
  }

  renderEvents(data, day) {
    if (data.day_items.event || data.day_items.signup) {
      let events = [];
      for (let e in data.day_items.event) {
        if (data.day_items.event.hasOwnProperty(e)) {
          events.push(
            <Event
              key={Math.random()}
              event={data.day_items.event[e]}
              day={day}
              GlobalActions={this.props.GlobalActions}
              {...this.props}
            />,
          );
        }
      }
      for (let e in data.day_items.signup) {
        if (data.day_items.signup.hasOwnProperty(e)) {
          events.push(
            <Event
              key={Math.random()}
              event={data.day_items.signup[e]}
              day={day}
              GlobalActions={this.props.GlobalActions}
              {...this.props}
            />,
          );
        }
      }

      return events;
    } else {
      return <p>No events</p>;
    }
  }

  goToDate(date) {
    return true;
  }
}
