import React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'inline-flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  popper: {
    zIndex: 1230,
  },
  growComp: {
    maxHeight: '300px',
    overflow: 'auto',
  },
}));

const MuiDropdown = props => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event, opt = null) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    opt && props.handleSelection(opt.value);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        className={props.className || ''}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        variant="contained"
        color="primary"
        size="large"
        onClick={handleToggle}
      >
        {props.label}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.popper}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            className={classNames(classes.growComp, props.className)}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {props.options.map(opt => (
                    <MenuItem
                      className="dropdownItem"
                      key={opt.label}
                      onClick={e => handleClose(e, opt)}
                      sx={{
                        padding: '9px !important',
                      }}
                    >
                      {opt.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default MuiDropdown;
