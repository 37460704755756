export function getColorFromClass(colorClass) {
  switch (colorClass) {
    case 'pto':
      return '#00ccff';
    case 'leave':
      return '#82ddf0';
    case 'holiday':
      return '#009900';
    case 'shift':
      return '#214670';
    case 'exchange':
      return '#5e4352';
    case 'bid':
      return '#82b4cc';
    case 'timeclock':
      return '#726afb';
    case 'event':
      return '#7d7abc';
    case 'signup':
      return '#6457a6';
    case 'callout':
      return '#cf7885';
    default:
      return '#000';
  }
}
